import {Component, OnInit} from '@angular/core';
import {Customer} from 'src/app/customers/shared/model/customer.model';
import {ActivatedRoute} from '@angular/router';
import {Worker} from 'src/app/workers/shared/model/worker.model';
import {Request} from '../shared/model/request.model';

@Component({
  selector: 'app-request-new',
  templateUrl: './request-new.component.html',
  styleUrls: ['./request-new.component.css']
})
export class RequestNewComponent implements OnInit {
  customers: Customer[] = [];
  workers: Worker[] = [];
  request: Request = null;

  constructor(private route: ActivatedRoute) {
    this.getAllCustomers();
    this.getAllWorkers();
    this.getRequest();
  }

  ngOnInit() {
  }

  private getRequest() {
    const data = this.route.snapshot.data['request'];
    if (data) {
      this.request = new Request();
      Object.assign(this.request, data);
    }
  }

  private getAllCustomers() {
    const data = this.route.snapshot.data['customers'];
    Object.assign(this.customers, data);
  }

  private getAllWorkers() {
    const data = this.route.snapshot.data['workers'];
    Object.assign(this.workers, data);
  }
}
