import {Serializer} from 'src/app/shared/model/serializer';
import {User} from './user.model';

export class UserSerializer implements Serializer {
  fromJson(json: any): any {

    const user: User = new User();

    user.id = json.id;
    user.username = json.username;
    user.languageCode = json.languageCode;
    user.firstName = json.firstName;
    user.lastName = json.lastName;
    user.operatingUnitId = json.operatingUnitId;
    user.role = json.roleName;
    user.accountType = json.accountType;
    user.customerId = json.customerId;
    user.workerId = json.workerId;

    return user;
  }

  toJson(user: User): any {
    return {
      id: user.id,
      username: user.username,
      languageCode: user.languageCode,
      firstName: user.firstName,
      lastName: user.lastName,
      operatingUnitId: user.operatingUnitId,
      role: {
        roleName: user.role
      },
      accountType: user.accountType,
      workerId: user.workerId,
      customerId: user.customerId
    };
  }
}
