import {Serializer} from '../../../shared/model/serializer';
import {PrestationPeriodCode} from './prestation-period-code.model';
import {DateSerializer} from '../../../shared/model/date.serializer';

export class PrestationPeriodCodeSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): PrestationPeriodCode {
    const prestationPeriodCode: PrestationPeriodCode = new PrestationPeriodCode();

    prestationPeriodCode.id = json.id;
    prestationPeriodCode.code = json.code;
    prestationPeriodCode.ssCode = json.ssCode;
    prestationPeriodCode.ssCodeType = json.ssCodeType;
    prestationPeriodCode.labelFR = json.labelFR;
    prestationPeriodCode.labelEN = json.labelEN;
    prestationPeriodCode.labelNL = json.labelNL;
    prestationPeriodCode.description = json.description;
    prestationPeriodCode.workerType = json.workerType;
    prestationPeriodCode.startDate = this.dateSerializer.fromJson(json.startDate);
    prestationPeriodCode.endDate = this.dateSerializer.fromJson(json.endDate);
    prestationPeriodCode.calculationDate = this.dateSerializer.fromJson(json.calculationDate);
    prestationPeriodCode.days = json.days;
    prestationPeriodCode.hoursDecimal = json.hoursDecimal;
    prestationPeriodCode.totalBasedSalary = json.totalBasedSalary;
    prestationPeriodCode.hours = this.dateSerializer.hoursFromJson(json.hours);
    prestationPeriodCode.prestationCoefficient = json.prestationCoefficient;
    prestationPeriodCode.prestationPercentage = json.prestationPercentage;
    prestationPeriodCode.salary = json.salary;
    prestationPeriodCode.prestationSalary = json.prestationSalary;
    prestationPeriodCode.billingDate = this.dateSerializer.fromJson(json.billingDate);
    prestationPeriodCode.billingRate = json.billingRate;
    prestationPeriodCode.billingPercentage = json.billingPercentage;
    prestationPeriodCode.billingTotal = json.billingTotal;
    prestationPeriodCode.billingCoefficient = json.billingCoefficient;
    prestationPeriodCode.active = json.active;

    return prestationPeriodCode;
  }

  toJson(prestationPeriodCode: PrestationPeriodCode): any {
    return {
      prestationPeriod: {
        id: prestationPeriodCode.prestationPeriod.id
      },
      id: prestationPeriodCode.id,
      code: prestationPeriodCode.code,
      ssCode: prestationPeriodCode.ssCode,
      ssCodeType: prestationPeriodCode.ssCodeType,
      labelFR: prestationPeriodCode.labelFR,
      labelEN: prestationPeriodCode.labelEN,
      labelNL: prestationPeriodCode.labelNL,
      description: prestationPeriodCode.description,
      workerType: prestationPeriodCode.workerType,
      startDate: this.dateSerializer.dateWithTimeToJson(prestationPeriodCode.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(prestationPeriodCode.endDate),
      calculationDate: this.dateSerializer.dateWithTimeToJson(prestationPeriodCode.calculationDate),
      days: prestationPeriodCode.days,
      hoursDecimal: prestationPeriodCode.hoursDecimal,
      hours: this.dateSerializer.hoursToJson(prestationPeriodCode.hours),
      totalBasedSalary: prestationPeriodCode.totalBasedSalary,
      prestationCoefficient: prestationPeriodCode.prestationCoefficient,
      prestationPercentage: prestationPeriodCode.prestationPercentage,
      salary: prestationPeriodCode.salary,
      prestationSalary: prestationPeriodCode.prestationSalary,
      billingDate: this.dateSerializer.toJson(prestationPeriodCode.billingDate),
      billingRate: prestationPeriodCode.billingRate,
      billingPercentage: prestationPeriodCode.billingPercentage,
      billingTotal: prestationPeriodCode.billingTotal,
      billingCoefficient: prestationPeriodCode.billingCoefficient,
      active: prestationPeriodCode.active
    };
  }
}
