import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';
import { MatDialog } from '@angular/material/dialog';
import { PrestationCode } from 'src/app/shared/model/prestation-code/prestation-code.model';
import { PrestationCodePage } from 'src/app/shared/model/pagination/prestation-code/prestation-code-page.model';
import { PrestationCodeDialogComponent } from 'src/app/shared/component/admin-dialog/prestation-code-dialog/prestation-code-dialog.component';


@Component({
  selector: 'app-prestation-code',
  templateUrl: './prestation-code.component.html',
  styleUrls: ['./prestation-code.component.css', '../../../admin.component.css']
})
export class PrestationCodeComponent implements OnInit {

  @ViewChild('PrestationCodePaginator', { static: true }) paginator: MatPaginator;

  prestationCode: PrestationCode;

  dataSource = new MatTableDataSource<PrestationCode>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "billing",
    "billingPercent",
    "code",
    "daily",
    "hourly",
    "jointCommissions",
    "labelEN",
    "labelFR",
    "labelNL",
    "prestation",
    "prestationCodeType",
    "prestationPercent",
    "ssCode",
    "ssCodeType",
    "ssEncodingType",
    "priority"
  ];

  public getData(event?: PageEvent){
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPagePrestationCode(this.pageIndex, this.pageSize).subscribe(
      (data: PrestationCodePage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      }
    );
    return event;
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(PrestationCodeDialogComponent, {
      data: { element: row },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

}
