import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {WorkerLiteSerializer} from '../model/worker-lite.serializer';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WorkerLite} from '../model/worker-lite.model';
import {WorkerLitePage} from '../../../shared/model/pagination/worker/worker-lite-page.model';
import {WorkerLitePageSerializer} from '../../../shared/model/pagination/worker/worker-lite-page.serializer';

@Injectable({
  providedIn: 'root'
})
export class WorkersLiteService {
  private serviceURL = environment.worker_service_URL + 'workers-lite';
  private workerLiteSerializer: WorkerLiteSerializer = new WorkerLiteSerializer();
  private workerLitePageSerializer: WorkerLitePageSerializer = new WorkerLitePageSerializer();

  constructor(private http: HttpClient) {
  }

  get(workerLiteId: number): Observable<any> {
    return this.http.get<WorkerLite>(this.serviceURL + '/' + workerLiteId).pipe(
      map((data: WorkerLite) => this.workerLiteSerializer.fromJson(data))
    );
  }

  delete(workerLiteId: number): Observable<any> {
    return this.http.delete<WorkerLite>(this.serviceURL + '/' + workerLiteId);
  }

  getPageWorkerLite(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<WorkerLitePage>(this.serviceURL + '/page', {params}).pipe(
      map((data: WorkerLitePage) => this.workerLitePageSerializer.fromJson(data))
    );
  }
}
