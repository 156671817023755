import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegistrationFormService {
  private serviceURL = environment.worker_service_URL + 'registration-forms';

  constructor(private http: HttpClient) {
  }

  getRegistrationForm(workerId: number): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
    return this.http.get(this.serviceURL + '/registrationform?workerId=' + workerId, {headers: headers, responseType: 'blob'});
  }
}
