import {Serializer} from '../serializer';
import {DimonaPeriod} from './dimona.model';
import {DateSerializer} from '../date.serializer';

export class DimonaPeriodSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): DimonaPeriod {
    const dimonaPeriod: DimonaPeriod = new DimonaPeriod();

    dimonaPeriod.id = json.id;
    dimonaPeriod.contractPeriodId = json.contractPeriodId;
    dimonaPeriod.reference = json.reference;
    dimonaPeriod.dimonaPeriodId = json.dimonaPeriodId;
    dimonaPeriod.requestPeriodId = json.requestPeriodId;
    dimonaPeriod.status = json.status;
    dimonaPeriod.errorCodes = json.errorCodes;
    dimonaPeriod.startDate = this.dateSerializer.fromJson(json.startDate);
    dimonaPeriod.endDate = this.dateSerializer.fromJson(json.endDate);
    dimonaPeriod.plannedNbrHours = json.plannedNbrHours;
    dimonaPeriod.active = json.active;

    return dimonaPeriod;
  }

  toJson(dimonaPeriod: DimonaPeriod): any {
    return {
      id: dimonaPeriod.id,
      contractPeriodId: dimonaPeriod.contractPeriodId,
      reference: dimonaPeriod.reference,
      dimonaPeriodId: dimonaPeriod.dimonaPeriodId,
      requestPeriodId: dimonaPeriod.requestPeriodId,
      status: dimonaPeriod.status,
      errorCodes: dimonaPeriod.errorCodes,
      startDate: this.dateSerializer.dateWithTimeToJson(dimonaPeriod.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(dimonaPeriod.endDate),
      plannedNbrHours: dimonaPeriod.plannedNbrHours,
      active: dimonaPeriod.active,
    };
  }
}
