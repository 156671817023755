import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthorizationService} from '../login/shared/service/authorization.service';
import {Role} from '../users/shared/model/user.model';
import {ConfirmationDialogComponent} from '../shared/component/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FacadeService} from '../shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-prestations',
  templateUrl: './prestations.component.html',
  styleUrls: ['./prestations.component.css']
})
export class PrestationsComponent {

  constructor(public route: ActivatedRoute,
              private dialog: MatDialog,
              private authorizationService: AuthorizationService,
              private translate: TranslateService,
              private service: FacadeService,
              private snackBar: MatSnackBar) {
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  /* releasePayroll() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {message: this.translate.instant('prestations.releasePayroll.confirm')}});

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.releasePayroll().subscribe({
          next: next => {
            if (next) {
              this.openSnackBar(this.translate.instant('prestations.releasePayroll.done'));
            } else {
              this.openSnackBar(this.translate.instant('prestations.releasePayroll.empty'));
            }
          },
          error: ignoreProperty => {
            this.openSnackBar(this.translate.instant('prestations.releasePayroll.error'));
          }
        });
      }
    });
  } */

  public openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }

}
