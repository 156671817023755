import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Worker} from '../../../workers/shared/model/worker.model';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class WorkersActiveResolver implements Resolve<Observable<Worker[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<Worker[]> {
    return this.facadeService.getAllActiveWorkers().pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
