import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../../../../shared/service/facade/facade.service';
import {Location} from '@angular/common';
import {DimonaPeriod, DimonaPeriodStatus} from '../../../../shared/model/dimona/dimona.model';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-request-error-detail',
  templateUrl: './request-error-detail.component.html',
  styleUrls: ['./request-error-detail.component.css']
})
export class RequestErrorDetailComponent implements OnInit {
  dimonaPeriods: DimonaPeriod[] = [];
  requestId: number;

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private location: Location) {
  }

  get DimonaPeriodStatus() {
    return DimonaPeriodStatus;
  }

  ngOnInit() {
    this.requestId = +this.route.snapshot.queryParamMap.get('r');
  }

  onBack(): void {
    this.location.back();
  }
}
