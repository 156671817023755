import {Prestation} from './prestation.model';
import {PrestationPeriodCode} from './prestation-period-code.model';

export enum PrestationPeriodStatus {
  TO_VALIDATE = 'TO_VALIDATE',
  VALIDATED = 'VALIDATED',
  REJECTED = 'REJECTED'
}

export class PrestationPeriod {
  prestation: Prestation;

  id: number;
  contractPeriodId: number;
  plannedStartDate: Date;
  plannedEndDate: Date;
  plannedPauseTime: Date;
  actualStartDate: Date;
  actualEndDate: Date;
  actualCustomerStartDate: Date;
  actualCustomerEndDate: Date;
  actualWorkerEndDate: Date;
  actualWorkerStartDate: Date;
  actualPauseTime: Date;
  status: PrestationPeriodStatus;
  description: string;
  function: string;
  workerValidationId: number;
  workerValidationBy: string;
  workerValidationDate: Date;
  customerValidationId: number;
  customerValidationBy: string;
  customerValidationDate: Date;
  customerRejectionDate: Date;
  consultantValidationId: number;
  consultantValidationBy: string;
  consultantValidationDate: Date;
  correctionCode: number;
  correctionLabel: string;
  active: boolean;
  prestationPeriodCodes: PrestationPeriodCode[] = [];
  actualHours: Date;
  minutes: number;
  updated: boolean;
  processed: boolean;
  totalActualHours: Date;
  justificationFile: File;


  isValidate(): boolean {
    return [PrestationPeriodStatus.VALIDATED, PrestationPeriodStatus.REJECTED].includes(this.status);
  }

  isCustomerValidated(): boolean {
    return this.customerValidationDate !== null;
  }

  isCustomerRejected(): boolean {
    return this.customerRejectionDate !== null;
  }

  isCustomerProcessed(): boolean {
    return this.isCustomerValidated() || this.isCustomerRejected();
  }

  validationDatesMatching(start: Date, end: Date): boolean {
    return !((start.getTime() !== this.actualStartDate.getTime()) || (end.getTime() !== this.actualEndDate.getTime()));
  }

  getTotalHoursToNumber(): number {
    const hours = this.totalActualHours.getHours();
    const minutes = this.totalActualHours.getMinutes();
    return hours + (minutes / 60);
  }
}
