export enum State {
  UP = 'UP',
  DOWN = 'DOWN'
}

export class Status {
  id: number;
  name: string;
  url: string;
  state: State;
  notified: boolean;
}
