import {Component, Input, OnInit} from '@angular/core';
import {Prestation} from '../shared/model/prestation.model';
import {NgForm} from '@angular/forms';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {DateSerializer} from '../../shared/model/date.serializer';
import {AuthorizationService} from '../../login/shared/service/authorization.service';
import {Role} from '../../users/shared/model/user.model';
import {TranslateService} from '@ngx-translate/core';
import {Contract} from '../../contracts/shared/model/contract.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Customer} from '../../customers/shared/model/customer.model';
import {Worker} from '../../workers/shared/model/worker.model';

class PrestationFilter {
  id: number;
  startDate: Date;
  endDate: Date;
  customerId: number;
  billingDate: Date;
  dueDate: Date;
  processingDate: Date;
  workerId: number;
  billNumber: string;
}

@Component({
  selector: 'app-prestation-correction',
  templateUrl: './prestation-correction.component.html',
  styleUrls: ['./prestation-correction.component.css'],
})

export class PrestationCorrectionComponent implements OnInit {

  customers: Customer[];
  workers: Worker[];
  dateSerializer: DateSerializer = new DateSerializer();
  prestationFilter: PrestationFilter = new PrestationFilter();
  prestations: Prestation[] = [];

  constructor(private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.facadeService.getAllCustomers().subscribe(data => this.customers = data);
    this.facadeService.getAllWorkers().subscribe(data => this.workers = data);
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  searchPrestation(form: NgForm) {
    if (form.value.id === undefined || form.value.id === '') {
      if (!(form.value.startDate) ||
        !(form.value.endDate) ||
        form.value.customerId === undefined) {
        return alert('Please select either an id or a Start time, an End time and a Customer');
      }
    }
    if (form.valid) {
      const id = form.value.id;
      const customerId = form.value.customerId;
      const startDate = form.value.startDate ? new Date(form.value.startDate) : null;
      const endDate = form.value.endDate ? new Date(form.value.endDate) : null;
      const billingDate = (form.value.billingDate) ? new Date(form.value.billingDate) : null;
      const dueDate = (form.value.dueDate) ? new Date(form.value.dueDate) : null;
      const workerId = (form.value.workerId) ? form.value.workerId : null;
      const processingDate = (form.value.processingDate) ? new Date(form.value.processingDate) : null;
      const billNumber = (form.value.billNumber) ? form.value.billNumber : null;
      //  Check if date are valid
      if (startDate && endDate) {
        if (startDate.getTime() > endDate.getTime()) {
          return alert(this.translate.instant('prestations.correction.message.error.search'));
        }
      }
      this.getPrestationsWithFilter(+id, +customerId, startDate, endDate, billingDate, dueDate, +workerId, processingDate, billNumber);
    }
  }

  openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }

  clearStartDate() {
    this.prestationFilter.startDate = null;
  }

  clearEndDate() {
    this.prestationFilter.endDate = null;
  }

  clearBillingDate() {
    this.prestationFilter.billingDate = null;
  }

  clearDueDate() {
    this.prestationFilter.dueDate = null;
  }

  clearProcessingDate() {
    this.prestationFilter.processingDate = null;
  }

  clearWorker() {
    this.prestationFilter.workerId = null;
  }

  private getPrestationsWithFilter(id: number,
                                   customerId: number,
                                   startDate: Date,
                                   endDate: Date,
                                   billingDate: Date,
                                   dueDate: Date,
                                   workerId: number,
                                   processingDate: Date,
                                   billNumber: string) {
    this.facadeService.filterPrestationForCorrection(
      id,
      customerId,
      startDate,
      endDate,
      workerId,
      billingDate,
      dueDate,
      processingDate,
      billNumber).subscribe((prestations: Prestation[]) => {
      prestations.forEach(
        prestation => {
          this.facadeService.getContractById(prestation.contractId)
            .subscribe((contract: Contract) => {
              prestation.contract = contract;
            });
        }
      );
      this.prestations = prestations;
    });
  }
}
