import {Serializer} from '../../../shared/model/serializer';
import {BillingCoefficients} from './customer.model';

export class BillingCoefficientSerializer implements Serializer {

  fromJson(json: any): BillingCoefficients {
    const billingCoefficients: BillingCoefficients = new BillingCoefficients();

    billingCoefficients.id = json.id;
    billingCoefficients.active = json.active;
    billingCoefficients.customerSize = json.customerSize;
    billingCoefficients.employeePayroll = json.employeePayroll;
    billingCoefficients.employeeSelection = json.employeeSelection;
    billingCoefficients.extraPayroll = json.extraPayroll;
    billingCoefficients.extraSelection = json.extraSelection;
    billingCoefficients.flexiPayroll = json.flexiPayroll;
    billingCoefficients.flexiSelection = json.flexiSelection;
    billingCoefficients.studentPayrollNotSubmitted = json.studentPayrollNotSubmitted;
    billingCoefficients.studentPayrollSubmitted = json.studentPayrollSubmitted;
    billingCoefficients.studentSelectionNotSubmitted = json.studentSelectionNotSubmitted;
    billingCoefficients.studentSelectionSubmitted = json.studentSelectionSubmitted;
    billingCoefficients.workerPayroll = json.workerPayroll;
    billingCoefficients.workerSelection = json.workerSelection;

    return billingCoefficients;
  }

  toJson(billingCoefficients: BillingCoefficients): any {
    return {
      id: billingCoefficients.id,
      active: billingCoefficients.active,
      customerSize: billingCoefficients.customerSize,
      employeePayroll: billingCoefficients.employeePayroll,
      employeeSelection: billingCoefficients.employeeSelection,
      extraPayroll: billingCoefficients.extraPayroll,
      extraSelection: billingCoefficients.extraSelection,
      flexiPayroll: billingCoefficients.flexiPayroll,
      flexiSelection: billingCoefficients.flexiSelection,
      studentPayrollNotSubmitted: billingCoefficients.studentPayrollNotSubmitted,
      studentPayrollSubmitted: billingCoefficients.studentPayrollSubmitted,
      studentSelectionNotSubmitted: billingCoefficients.studentSelectionNotSubmitted,
      studentSelectionSubmitted: billingCoefficients.studentSelectionSubmitted,
      workerPayroll: billingCoefficients.workerPayroll,
      workerSelection: billingCoefficients.workerSelection
    };
  }

}
