import {Serializer} from '../serializer';
import {Address} from './address.model';

export class AddressSerializer implements Serializer {

  fromJson(json: any): Address {
    const address = new Address();
    address.street = json.street;
    address.number = json.number;
    address.box = json.box;
    address.zip = json.zip;
    address.city = json.city;
    address.country = json.country;

    return address;
  }

  toJson(address: Address): any {
    return {
      street: address.street,
      number: address.number,
      box: address.box,
      zip: address.zip,
      city: address.city,
      country: address.country
    };
  }
}
