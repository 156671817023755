import {Component, OnInit} from '@angular/core';
import {User} from '../shared/model/user.model';
import {AuthorizationService} from '../../login/shared/service/authorization.service';
import {ActivatedRoute} from '@angular/router';
import {OperatingUnit} from '../../operating-units/shared/model/operating-unit.model';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {
  user: User;
  operatingUnits: OperatingUnit[] = [];

  constructor(private authService: AuthorizationService,
              private route: ActivatedRoute,
              private facadeService: FacadeService,
              private translate: TranslateService) {
    this.getAllOperatingUnits();
  }

  ngOnInit() {
    this.getUser();
  }

  private getAllOperatingUnits() {
    const operatingUnits = this.route.snapshot.data['operatingUnits'];
    Object.assign(this.operatingUnits, operatingUnits);
  }

  private getUser() {
    const userId = this.route.snapshot.queryParamMap.get('u');
    if (userId) {
      this.facadeService.getUserById(userId).subscribe(
        (user: User) => {
          this.user = user;
        },
      );
    } else {
      this.user = this.authService.getCurrentUser();
    }
  }
}
