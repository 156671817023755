export class JointCommissionCoefficientAdmin {
    id: number;
    active: boolean;
    jointCommissionCode: string;
    nightShiftWorkType : string;
    clothesBillingCoefficient : number;
    coefficientClothes : number;
    coefficientEcoCheques : number;
    coefficientPension : number;
    coefficientPurchasing : number;
    dimonaBillingCoefficient : number;
    dimonaFee : number;
    ecoChequeBillingCoefficient : number;
    flexyRate: number;
    mealEmployer: number;
    mealFacial: number;
    mealVoucher: number;
    pensionBillingCoefficient: number;
    purchasingBillingCoefficient: number;
    travelExpenseBillingCoefficient: number;
    travelExpenses: number;
    riskedActivity: boolean;
    rtt: boolean;
    whCutSchedule: number;
    whLate: number;
    whNight: number;
    whPublicHoliday: number;
    whSaturday: number;
    whSunday: number;
  }
