import { JointCommission } from './../joint-commission/joint-commission.model';
import { FacadeService } from "./../../service/facade/facade.service";
import { Serializer } from "src/app/shared/model/serializer";
import { DatePipe } from "@angular/common";
import { WorkFunction } from "./work-function.model";
import { JointCommissionSerializer } from "../joint-commission/joint-commission.serializer";
import { inject } from "@angular/core";

export class WorkFunctionSerializer implements Serializer {
  private datePipe: DatePipe = new DatePipe("en");
  private joinCommissionSerializer: JointCommissionSerializer = new JointCommissionSerializer();
  private facadeService = inject(FacadeService);

  constructor() {}

  fromJson(json: any): WorkFunction {
    const workFunction: WorkFunction = new WorkFunction();
    let joinCommission: JointCommission = new JointCommission();

    workFunction.id = json.id;
    workFunction.active = json.active;
    workFunction.category = json.category;
    workFunction.code = json.code;
    workFunction.nameDE = json.nameDE;
    workFunction.nameEN = json.nameEN;
    workFunction.nameFR = json.nameFR;
    workFunction.nameNL = json.nameNL;
    workFunction.validityEndDate = json.validityEndDate
      ? new Date(json.validityEndDate)
      : null;
    workFunction.validityStartDate = json.validityEndDate
      ? new Date(json.validityEndDate)
      : null;

    const jsonJoinCommission = json.jointCommission;
    if (typeof(jsonJoinCommission) !== 'number') {
      workFunction.jointCommission = this.joinCommissionSerializer.fromJson(jsonJoinCommission);
    } else {
      workFunction.jointCommission = new JointCommission();
      workFunction.jointCommission.id = json.jointCommission;
    }

    return workFunction;
  }

  toJson(workFunction: WorkFunction): any {
    return {
      id: workFunction.id,
      active: workFunction.active,
      category: workFunction.category,
      code: workFunction.code,
      nameDE: workFunction.nameDE,
      nameEN: workFunction.nameEN,
      nameFR: workFunction.nameFR,
      nameNL: workFunction.nameNL,
      validityEndDate: workFunction.validityEndDate
        ? this.datePipe.transform(workFunction.validityEndDate, "yyyy-MM-dd")
        : null,
      validityStartDate: workFunction.validityStartDate
        ? this.datePipe.transform(workFunction.validityStartDate, "yyyy-MM-dd")
        : null,
      jointCommission: this.joinCommissionSerializer.toJson(
        workFunction.jointCommission
      ),
    };
  }
}
