import {Component, Inject} from '@angular/core';
import {Frequency, NonAvailability} from '../../shared/model/nonAvailability.model';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

export enum FrequencyForm {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ONCE = 'ONCE'
}

@Component({
  selector: 'app-non-availability-dialog',
  templateUrl: './non-availability-dialog.component.html',
  styleUrls: ['./non-availability-dialog.component.css']
})
export class NonAvailabilityDialogComponent {
  nonAvailability: NonAvailability = new NonAvailability();
  displayDay: number;
  frequency: FrequencyForm;

  constructor(private dialogRef: MatDialogRef<NonAvailabilityDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private facadeService: FacadeService,
              private translate: TranslateService) {
    if (data.nonAvailability) {
      this.nonAvailability = data.nonAvailability;
      if (Object.values(Frequency).includes(this.nonAvailability.frequency)) {
        switch (this.nonAvailability.frequency) {
          case Frequency.MONTHLY:
            this.frequency = FrequencyForm.MONTHLY;
            break;
          case Frequency.WEEKLY:
            this.frequency = FrequencyForm.WEEKLY;
            break;
          default:
            this.frequency = FrequencyForm.DAILY;
        }
      } else {
        this.frequency = FrequencyForm.ONCE;
      }
    } else {
      const now = new Date();
      this.nonAvailability.startDate = data.startDate ?? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0);
      this.nonAvailability.endDate = data.endDate ?? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0, 0);
      this.nonAvailability.allDay = false;
      this.frequency = FrequencyForm.ONCE;
    }
    this.displayDay = this.nonAvailability.startDate.getDay();
  }

  get FrequencyForm() {
    return FrequencyForm;
  }

  addNonAvailability(form: NgForm) {
    if (form.valid) {
      const startDate = new Date(form.value.startDate);
      if (form.value.start) {
        const start = form.value.start.split(':');
        startDate.setHours(+start[0]);
        startDate.setMinutes(+start[1]);
      }

      const endDate = new Date(form.value.endDate);
      if (form.value.end) {
        const end = form.value.end.split(':');
        endDate.setHours(+end[0]);
        endDate.setMinutes(+end[1]);
      }

      const nonAvailability: NonAvailability = new NonAvailability();
      nonAvailability.id = this.nonAvailability.id;
      nonAvailability.startDate = startDate;
      nonAvailability.endDate = endDate;
      nonAvailability.worker = this.data.worker;
      nonAvailability.allDay = form.value.allDay;
      nonAvailability.frequency = (Object.values(Frequency).includes(form.value.frequency) ? form.value.frequency : null);
      if (nonAvailability.frequency) {
        nonAvailability.count = 1;
      }
      if (form.value.until) {
        nonAvailability.until = new Date(form.value.until);
        nonAvailability.count = null;
        if (nonAvailability.until.getTime() < nonAvailability.startDate.getTime()) {
          return alert(this.translate.instant('workers.view.nonAvailabilities.dialog.message.error.until'));
        }


        if (nonAvailability.frequency === Frequency.DAILY && (endDate.getTime() - startDate.getTime()) / 1000 > 24 * 60 * 60) {
          return alert('Impossible to set a daily non availability which is more than 24h');
        }
      }

      if (nonAvailability.allDay) {
        nonAvailability.startDate
          = new Date(nonAvailability.startDate.getFullYear(), nonAvailability.startDate.getMonth(), nonAvailability.startDate.getDate(),
          0, 0, 1);
        nonAvailability.endDate
          = new Date(nonAvailability.endDate.getFullYear(), nonAvailability.endDate.getMonth(), nonAvailability.endDate.getDate(),
          23, 59, 59);
      }

      if (nonAvailability.isValid()) {
        this.facadeService.saveNonAvailability(nonAvailability)
          .subscribe({
            next:
              ignoreProperty => {
                this.closeDialog(true);
              },
            error: ignoreProperty => {
              return alert(this.translate.instant('workers.view.nonAvailabilities.dialog.message.error.save'));
            }
          });
      } else {
        return alert(this.translate.instant('workers.view.nonAvailabilities.dialog.message.error.form'));
      }
    }
  }

  openSnackBar(message: string, pC: string, action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  validDate(form: NgForm) {
    const startDate = form.value.startDate;
    this.displayDay = new Date(startDate).getDay();
    if (new Date(startDate).getTime() > new Date(form.value.endDate).getTime()) {
      form.controls['endDate'].patchValue(startDate);
    }
  }

  deleteNonAvailability(nonAvailability: NonAvailability) {
    this.facadeService.deleteNonAvailability(nonAvailability)
      .subscribe({
        next:
          ignoreProperty => {
            this.closeDialog(true);
            this.openSnackBar(this.translate.instant('workers.view.nonAvailabilities.dialog.message.success.delete'), 'customSnackValid');
          },
        error: ignoreProperty => {
          return alert(this.translate.instant('workers.view.nonAvailabilities.dialog.message.error.delete'));
        }
      });
  }

  closeDialog(update = false): void {
    this.dialogRef.close(update);
  }
}
