import {Serializer} from '../serializer';
import {JointCommissionCoefficient} from './joint-commission-coefficient.model';
import {UncomfortableWorkingHoursSerializer} from '../../../customers/shared/model/uncomfortable-working-hours.serializer';
import {PaymentTermsSerializer} from '../../../customers/shared/model/payment-terms.serializer';
import {PaymentTerms, UncomfortableWorkingHours} from '../../../customers/shared/model/customer.model';

export class JointCommissionCoefficientSerializer implements Serializer {
  private uncomfortableWorkingHoursSerializer: UncomfortableWorkingHoursSerializer = new UncomfortableWorkingHoursSerializer();
  private paymentTermsSerializer: PaymentTermsSerializer = new PaymentTermsSerializer();

  fromJson(json: any): JointCommissionCoefficient {
    const jointCommissionCoefficient: JointCommissionCoefficient = new JointCommissionCoefficient();
    let paymentTerms: PaymentTerms = new PaymentTerms();
    let uncomfortableWorkingHours: UncomfortableWorkingHours = new UncomfortableWorkingHours();

    jointCommissionCoefficient.id = json.id;
    jointCommissionCoefficient.jointCommissionCode = json.jointCommissionCode;
    const paymentTermsJson = json.paymentTerms;
    if (paymentTermsJson) {
      paymentTerms = this.paymentTermsSerializer.fromJson(paymentTermsJson);
    }
    jointCommissionCoefficient.paymentTerms = paymentTerms;
    const uncomfortableWorkingHoursJson = json.uncomfortableWorkingHours;
    if (uncomfortableWorkingHoursJson) {
      uncomfortableWorkingHours = this.uncomfortableWorkingHoursSerializer.fromJson(uncomfortableWorkingHoursJson);
    }
    jointCommissionCoefficient.uncomfortableWorkingHours = uncomfortableWorkingHours;
    jointCommissionCoefficient.rtt = json.rtt;
    jointCommissionCoefficient.nightShiftWorkType = json.nightShiftWorkType;
    jointCommissionCoefficient.riskedActivity = json.riskedActivity;
    jointCommissionCoefficient.active = json.active;

    return jointCommissionCoefficient;
  }

  toJson(jointCommissionCoefficient: JointCommissionCoefficient): any {
    return {
      id: jointCommissionCoefficient.id,
      jointCommissionCode: jointCommissionCoefficient.jointCommissionCode,
      paymentTerms: this.paymentTermsSerializer.toJson(jointCommissionCoefficient.paymentTerms),
      uncomfortableWorkingHours: this.uncomfortableWorkingHoursSerializer.toJson(jointCommissionCoefficient.uncomfortableWorkingHours),
      rtt: jointCommissionCoefficient.rtt,
      nightShiftWorkType: jointCommissionCoefficient.nightShiftWorkType,
      riskedActivity: jointCommissionCoefficient.riskedActivity,
      active: jointCommissionCoefficient.active
    };
  }
}
