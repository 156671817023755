import {Address} from '../../../shared/model/address/address.model';
import {LanguageCode} from '../../../shared/enum/language-code.enum';
import {WorkerPosition} from '../../../shared/worker-position/worker-position.model';

export enum FamilySituation {
  SINGLE = 'SINGLE',
  MARRIED = 'MARRIED',
  WIDOWED = 'WIDOWED',
  DIVORCED_OR_SEPARATED = 'DIVORCED_OR_SEPARATED',
  SEPARATION_OF_FACT = 'SEPARATION_OF_FACT',
  REGISTERED_COMMON_LAW_SPOUSE = 'REGISTERED_COMMON_LAW_SPOUSE'
}

export enum Gender {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
  X = 'X'
}

export enum ResidentPermitType {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F'
}

export enum StudyLevel {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUPERIOR = 'SUPERIOR',
  COLLEGE = 'COLLEGE'
}

export enum WorkerType {
  EMPLOYEE = 'EMPLOYEE',
  WORKER = 'WORKER',
  STUDENT_SUBMITTED = 'STUDENT_SUBMITTED',
  STUDENT_NOT_SUBMITTED = 'STUDENT_NOT_SUBMITTED',
  FLEXI = 'FLEXI',
  EXTRA = 'EXTRA'
}

export class StudentQuotaHour {
  id: number;
  year: number;
  hoursDone: number;
  worker: Worker;
}

export class Worker {
  id: number;
  workerNumber: number;
  firstName: string;
  lastName: string;
  operatingUnitId: number;
  address: Address = new Address();
  niss: string;
  gender: Gender;
  mobile: string;
  email: string;
  dateOfBirth: Date;
  placeOfBirth: string;
  languageCode: LanguageCode;
  workerStatus: WorkerStatus = new WorkerStatus();
  bankData: BankData = new BankData();
  medicalData: MedicalData = new MedicalData();
  contractData: ContractData = new ContractData();
  active = true;
  groupsId: number;
  docPending: boolean;
  workerPositions: WorkerPosition[];
  studentQuotaHours: StudentQuotaHour[] = [];
  age: number;

  get fullName(): string {
    return `${this.lastName} ${this.firstName}`;
  }
}

export class WorkerStatus {
  workerType: WorkerType;
  seniority: number;
  studentJobQuotaStartDate: Date;
  studentJobQuotaHours: number;
  studentAtWorkNumber: number;
  horecaAtWorkQuotaStartDate: Date;
  horecaAtWorkQuotadays: number;
  idCardNumber: string;
  idCardEndOfValidity: Date;
  nationality: string;
  residentPermitType: ResidentPermitType;
  residentPermitNumber: string;
  residentPermitEndOfValidity: Date;
}

export class BankData {
  bic: string;
  iban: string;
  deposit: number;
  diplomaNumber: string;
  visaNumber: string;
  inamiNumber: string;
  positions: string;
  licenseNumber: string;
  expertize: string;
  withHoldingRate: number;
  deductionsSeizures: boolean;
  invalid: boolean;
  familySituation: FamilySituation;
  dependentsNumber: number;
  federalTargetGroupReduction: number;
  walloniaTargetGroupReduction: number;
  socialExclusionReport: boolean;
}

export class MedicalData {
  hepBDosage: string;
  medicalVisit: string;
  intradermo: string;
  socialRiskDeclaration1: string;
  socialRiskDeclaration2: string;
  socialRiskDeclaration3: string;
  socialRiskDeclaration4: string;
  socialRiskDeclaration5: string;
  socialRiskDeclaration6: string;
}

export class ContractData {
  studyLevel: StudyLevel;
  firstContractDate: Date;
  lastContractDate: Date;
  interimOnlinePlanning: string;
  communicationTool: string;
  customerRemarks: string;
  possibleEvaluation: string;
  operatingUnitNotes: string;
}
