import {Serializer} from '../serializer';
import {Bic} from './bic.model';

export class BicSerializer implements Serializer {

  fromJson(json: any): Bic {
    const bic: Bic = new Bic();

    bic.id = json.id;
    bic.active = json.active;
    bic.identificationNumber = json.identificationNumber;
    bic.bicCode = json.bicCode;
    bic.institutionDutch = json.institutionDutch;
    bic.institutionFrench = json.institutionFrench;
    bic.institutionEnglish = json.institutionEnglish;
    bic.institutionGerman = json.institutionGerman;
    return bic;
  }

  toJson(bic: Bic): any {
    return {
      id: bic.id,
      active: bic.active,
      identificationNumber: bic.identificationNumber,
      bicCode: bic.bicCode,
      institutionDutch: bic.institutionDutch,
      institutionFrench: bic.institutionFrench,
      institutionEnglish: bic.institutionEnglish,
      institutionGerman: bic.institutionGerman
    };
  }
}
