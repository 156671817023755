import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AccountType, Role, User} from '../../../users/shared/model/user.model';
import {firstValueFrom, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {JwtHelperService} from '@auth0/angular-jwt';
import {FacadeService} from '../../../shared/service/facade/facade.service';

@Injectable()
export class AuthorizationService {
  private tokenKey = 'hs-web.token';
  private refreshTokenKey = 'hs-web.refresh-token';
  private userKey = 'hs-web.user';
  private sessionStorage: Storage = sessionStorage;
  private localStorage: Storage = localStorage;

  constructor(private router: Router,
              private http: HttpClient,
              private snackBar: MatSnackBar,
              private facadeService: FacadeService,
              public jwtHelper: JwtHelperService) {
  }

  loggedIn(): boolean {
    const token = this.getToken();
    const refreshToken = this.getRefreshToken();
    return !this.jwtHelper.isTokenExpired(token) || !this.jwtHelper.isTokenExpired(refreshToken);
  }

  getUserRole(accountType: AccountType) {

    if (accountType === AccountType.CONSULTANT) {

      if (this.hasRole(Role.COMEXIS_SUPER_ADMIN)) {
        return [Role.COMEXIS_SUPER_ADMIN, Role.SUPER_ADMIN, Role.SUPERVISOR, Role.CONSULTANT];
      }

      if (this.hasRole(Role.SUPER_ADMIN)) {
        return [Role.SUPER_ADMIN, Role.SUPERVISOR, Role.CONSULTANT];
      }

      if (this.hasRole(Role.SUPERVISOR)) {
        return [Role.SUPERVISOR, Role.CONSULTANT];
      }

      return [Role.CONSULTANT];
    }

    if (accountType === AccountType.WORKER) {

      if (this.hasRole(Role.COMEXIS_SUPER_ADMIN)) {
        return [Role.COMEXIS_SUPER_ADMIN, Role.WORKER];
      }

      return [Role.WORKER];
    }

    if (accountType === AccountType.CUSTOMER) {

      if (this.hasRole(Role.COMEXIS_SUPER_ADMIN)) {
        return [Role.COMEXIS_SUPER_ADMIN, Role.CUSTOMER_VALIDATOR, Role.CUSTOMER_PLANNER];
      }

      return [Role.CUSTOMER_VALIDATOR, Role.CUSTOMER_PLANNER];
    }
  }

  hasRole(role: Role): boolean {
    switch (role) {
      case Role.SUPERVISOR:
        return this.loggedIn() && this.isSupervisor();
      case Role.SUPER_ADMIN:
        return this.loggedIn() && this.isSuperAdmin();
      case Role.COMEXIS_SUPER_ADMIN:
        return this.loggedIn() && this.isComexisSuperAdmin();
      default:
        return this.loggedIn() && this.isConsultant();
    }
  }

  getToken(): string {
    return this.sessionStorage.getItem(this.tokenKey);
  }

  getRefreshToken(): string {
    return this.sessionStorage.getItem(this.refreshTokenKey);
  }

  setToken(newToken: string, refreshToken: string): void {

    if (this.getCurrentUser()) {
      this.sessionStorage.setItem(this.tokenKey, newToken);
      this.sessionStorage.setItem(this.refreshTokenKey, refreshToken);

      if (!!(this.localStorage.getItem(this.refreshTokenKey))) {
        this.localStorage.setItem(this.refreshTokenKey, refreshToken);
      }
    }
  }

  async setUser(token: string, refreshToken: string, rememberMe: boolean) {

    const user = await firstValueFrom(this.facadeService.getByToken(token))
      .catch(() => {
        this.logout();
      });

    if (![Role.CONSULTANT, Role.SUPERVISOR, Role.SUPER_ADMIN, Role.COMEXIS_SUPER_ADMIN].includes(user.role)) {
      this.logout();
    }

    this.setCurrentUser(user);
    this.setToken(token, refreshToken);

    user.operatingUnit = await firstValueFrom(this.facadeService.getOperatingUnitById(user.operatingUnitId))
      .catch(() => {
        this.logout();
      });

    if (rememberMe) {
      this.localStorage.setItem(this.refreshTokenKey, refreshToken);
    }

    this.setCurrentUser(user);
    window.location.href = '/dashboard';
  }

  getCurrentUser(): User {
    return JSON.parse(this.sessionStorage.getItem(this.userKey));
  }

  setCurrentUser(user: User): void {
    this.sessionStorage.setItem(this.userKey, JSON.stringify(user));
  }

  async autoLogin() {
    if (!this.getCurrentUser()) {
      const refreshToken = this.localStorage.getItem(this.refreshTokenKey);
      if (!!(refreshToken)) {
        const httpResponse = await firstValueFrom(this.facadeService.refreshToken(refreshToken))
          .catch(() => {
            this.logout();
          });
        await this.setUser(httpResponse.headers.get('authorization'), httpResponse.headers.get('refresh_token'), true);
      }
    }
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    return this.facadeService.refreshToken(refreshToken);
  }

  logout(): void {
    this.clearStorage();
    window.location.href = '/login';
  }

  clearStorage(): void {
    this.sessionStorage.removeItem(this.tokenKey);
    this.sessionStorage.removeItem(this.userKey);
    this.sessionStorage.removeItem(this.refreshTokenKey);
    this.localStorage.removeItem(this.refreshTokenKey);
  }

  private isConsultant(): boolean {
    return this.getCurrentUser().role === Role.CONSULTANT || this.isSupervisor();
  }

  private isSupervisor(): boolean {
    return this.getCurrentUser().role === Role.SUPERVISOR || this.isSuperAdmin();
  }

  private isSuperAdmin(): boolean {
    return this.getCurrentUser().role === Role.SUPER_ADMIN || this.isComexisSuperAdmin();
  }

  private isComexisSuperAdmin(): boolean {
    return this.getCurrentUser().role === Role.COMEXIS_SUPER_ADMIN;
  }
}
