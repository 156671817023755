import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {FacadeService} from 'src/app/shared/service/facade/facade.service';
import {OperatingUnit} from '../../model/operating-unit.model';
import {Language} from '../../../../shared/model/language/language.model';
import {Sector} from '../../../../shared/model/sector/sector.model';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {convertCountries, Country} from '../../../../shared/model/country/country.model';
import {ibanValidator} from 'ngx-iban';
import {emailValidator} from '../../../../shared/validator/email.validator';
import {Role} from '../../../../users/shared/model/user.model';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';

@Component({
  selector: 'app-operating-unit-form',
  templateUrl: './operating-unit-form.component.html',
  styleUrls: ['./operating-unit-form.component.css']
})
export class OperatingUnitFormComponent implements OnInit {
  @Input() operatingUnit: OperatingUnit;
  @Input() sectors: Sector[];
  operatingUnitForm: UntypedFormGroup;
  languages: Language[] = [];
  countries: Country[] = [];

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private translate: TranslateService) {
    this.getOperatingUnit();
  }

  ngOnInit() {
    this.formBuilderValidations();
    this.getLanguages();
    this.getCountries();
    this.translate.onLangChange.subscribe(() => {
      if (this.countries) {
        this.countries = convertCountries(this.countries, this.translate);
      }
    });
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPER_ADMIN);
  }

  getLanguages() {
    this.facadeService.getLanguages().subscribe(
      (languages: Language[]) => {
        this.languages = languages;
      }
    );
  }

  formBuilderValidations() {
    this.operatingUnitForm = this.formBuilder.group({
      id: [this.operatingUnit.id, []],
      operatingUnitNumber: [this.operatingUnit.operatingUnitNumber, []],
      active: [this.operatingUnit.active, []],
      name: [this.operatingUnit.name, [Validators.required]],
      vatNumber: [this.operatingUnit.vatNumber, [Validators.required]],
      onssNumber: [this.operatingUnit.onssNumber, []],
      sectorId: [this.operatingUnit.sectorId, [Validators.required]],
      address: this.formBuilder.group({
        street: [this.operatingUnit.address.street, [Validators.required]],
        number: [this.operatingUnit.address.number, [Validators.required]],
        box: [this.operatingUnit.address.box, []],
        zip: [this.operatingUnit.address.zip, [Validators.required]],
        city: [this.operatingUnit.address.city, [Validators.required]],
        country: [this.operatingUnit.address.country, [Validators.required]]
      }),
      contactData: this.formBuilder.group({
        phone: [this.operatingUnit.contactData.phone, [Validators.required]],
        fax: [this.operatingUnit.contactData.fax, []],
        email: [this.operatingUnit.contactData.email, [Validators.required, emailValidator]],
        languageCode: [this.operatingUnit.contactData.languageCode, [Validators.required]]
      }),
      otherInformation: this.formBuilder.group({
        walloonRegionalApproval: [this.operatingUnit.otherInformation.walloonRegionalApproval, []],
        brusselsRegionalApproval: [this.operatingUnit.otherInformation.brusselsRegionalApproval, []],
        flemishRegionalApproval: [this.operatingUnit.otherInformation.flemishRegionalApproval, []],
        legalInsurance: [this.operatingUnit.otherInformation.legalInsurance, []],
        legalInsuranceNumber: [this.operatingUnit.otherInformation.legalInsuranceNumber, []],
        externalPreventionService: [this.operatingUnit.otherInformation.externalPreventionService, []],
        externalPreventionNumber: [this.operatingUnit.otherInformation.externalPreventionNumber, []],
        holidayAllocationOffice: [this.operatingUnit.otherInformation.holidayAllocationOffice, []],
        holidayAllocationNumber: [this.operatingUnit.otherInformation.holidayAllocationNumber, []],
        familyAllocationOffice: [this.operatingUnit.otherInformation.familyAllocationOffice, []],
        familyAllocationNumber: [this.operatingUnit.otherInformation.familyAllocationNumber, []],
        socialSecretary: [this.operatingUnit.otherInformation.socialSecretary, []],
        bankAccountNumber: [this.operatingUnit.otherInformation.bankAccountNumber, [ibanValidator()]]
      })
    });
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  onSubmit() {
    if (this.operatingUnitForm.valid) {
      Object.assign(this.operatingUnit, this.operatingUnitForm.value);
      this.facadeService.saveOperatingUnit(this.operatingUnit)
        .subscribe({
          next: async operatingUnit => {
            await this.router.navigate(['operating-units']);
            this.openSnackBar(
              this.translate.instant('operatingUnits.form.message.success.save', {id: operatingUnit.id}),
              'X', 'customSnackValid');
          },
          error: _ => {
            this.openSnackBar(this.translate.instant('operatingUnits.form.message.error.save'), 'X', 'customSnackError');
          }
        });
    }
  }

  onBack(): void {
    this.location.back();
  }

  delete() {
    this.facadeService.deleteOperatingUnit(this.operatingUnit)
      .subscribe({
        next: async _ => {
          await this.router.navigate(['operating-units']);
          this.openSnackBar(
            this.translate.instant('operatingUnits.form.message.success.delete'),
            'X', 'customSnackValid');
        },
        error: _ => {
          this.openSnackBar(this.translate.instant('operatingUnits.form.message.error.delete'), 'X', 'customSnackError');
        }
      });
  }

  active() {
    this.facadeService.activeOperatingUnit(this.operatingUnit)
      .subscribe({
        next: async _ => {
          await this.router.navigate(['operating-units']);
          this.openSnackBar(
            this.translate.instant('operatingUnits.form.message.success.active'),
            'X', 'customSnackValid');
        },
        error: _ => {
          this.openSnackBar(this.translate.instant('operatingUnits.form.message.error.active'), 'X', 'customSnackError');
        }
      });
  }

  private getCountries() {
    this.facadeService.getCountries().subscribe(
      countries => {
        this.countries = countries;
      }
    );
  }

  private getOperatingUnit() {
    if (!this.operatingUnit) {
      this.operatingUnit = new OperatingUnit();
    }
  }
}
