import {Serializer} from '../../../shared/model/serializer';
import {UncomfortableWorkingHours} from './customer.model';

export class UncomfortableWorkingHoursSerializer implements Serializer {
  fromJson(jsonUncomfortableWorkingHours: any): UncomfortableWorkingHours {
    const uncomfortableWorkingHours: UncomfortableWorkingHours = new UncomfortableWorkingHours();
    uncomfortableWorkingHours.late = jsonUncomfortableWorkingHours.late;
    uncomfortableWorkingHours.night = jsonUncomfortableWorkingHours.night;
    uncomfortableWorkingHours.saturday = jsonUncomfortableWorkingHours.saturday;
    uncomfortableWorkingHours.sunday = jsonUncomfortableWorkingHours.sunday;
    uncomfortableWorkingHours.cutSchedule = jsonUncomfortableWorkingHours.cutSchedule;
    uncomfortableWorkingHours.publicHoliday = jsonUncomfortableWorkingHours.publicHoliday;

    return uncomfortableWorkingHours;
  }

  toJson(uncomfortableWorkingHours: UncomfortableWorkingHours): any {
    return {
      late: uncomfortableWorkingHours.late,
      night: uncomfortableWorkingHours.night,
      saturday: uncomfortableWorkingHours.saturday,
      sunday: uncomfortableWorkingHours.sunday,
      cutSchedule: uncomfortableWorkingHours.cutSchedule,
      publicHoliday: uncomfortableWorkingHours.publicHoliday
    };
  }
}
