import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NaceCode} from '../../shared/model/nace-code/nace-code.model';
import {CompanyPrefix} from '../../shared/model/company-prefix/company-prefix.model';
import {Country} from '../../shared/model/country/country.model';
import {OperatingUnit} from '../../operating-units/shared/model/operating-unit.model';

@Component({
  selector: 'app-customer-new',
  templateUrl: './customer-new.component.html',
  styleUrls: ['./customer-new.component.css']
})
export class CustomerNewComponent implements OnInit {
  naceCodes: NaceCode[] = [];
  companyPrefixes: CompanyPrefix[] = [];
  countries: Country[] = [];
  operatingUnits: OperatingUnit[] = [];


  constructor(private route: ActivatedRoute) {
    this.getAllNaceCodes();
    this.getAllCompanyPrefixes();
    this.getAllCountries();
    this.getAllOperatingUnits();
  }

  ngOnInit() {
  }

  private getAllCompanyPrefixes() {
    const data = this.route.snapshot.data['companyPrefixes'];
    Object.assign(this.companyPrefixes, data);
  }

  private getAllNaceCodes() {
    const data = this.route.snapshot.data['naceCodes'];
    Object.assign(this.naceCodes, data);
  }

  private getAllCountries() {
    const countries = this.route.snapshot.data['countries'];
    Object.assign(this.countries, countries);
  }

  private getAllOperatingUnits() {
    const operatingUnits = this.route.snapshot.data['operatingUnits'];
    Object.assign(this.operatingUnits, operatingUnits);
  }
}
