import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {WorkerLite} from '../../../workers-lite/shared/model/worker-lite.model';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class WorkerLiteResolver implements Resolve<Observable<WorkerLite>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<WorkerLite> {
    const workerLiteId = route.queryParams['wL'];
    if (workerLiteId) {
      return this.facadeService.getWorkerLiteById(+workerLiteId).pipe(
        map((data) => data),
        catchError(error => {
          return of({error: error});
        })
      );
    }
  }
}
