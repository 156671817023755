import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from '../service/authorization.service';
import {Role} from '../../../users/shared/model/user.model';

@Injectable()
export class SupervisorGuard implements CanActivate {

  constructor(private router: Router,
              private authorizationService: AuthorizationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }
}
