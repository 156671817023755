import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from '@angular/core';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {

  private static _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }

  parse(value: any): any {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return MyDateAdapter._to2digit(day) + '/' + MyDateAdapter._to2digit(month) + '/' + year;
    } else {
      return date.toDateString();
    }
  }
}

//  https://stackblitz.com/edit/angular-date-picker-sample-fr
