import {Component, OnInit, ViewChild} from '@angular/core';
import {OperatingUnit} from './shared/model/operating-unit.model';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FacadeService} from '../shared/service/facade/facade.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {OperatingUnitPage} from '../shared/model/pagination/operating-unit/operating-unit-page.model';
import {checkIfValidSearchTerm} from '../shared/model/pagination/pagination.model';

@Component({
  selector: 'app-operating-units',
  templateUrl: './operating-units.component.html',
  styleUrls: ['./operating-units.component.css']
})
export class OperatingUnitsComponent implements OnInit {
  displayedColumns: string[] = ['statusColor', 'operatingUnitNumber', 'operatingName', 'operatingPhone', 'operatingStatus'];
  operatingUnitTable = new MatTableDataSource<OperatingUnit>();
  @ViewChild('operatingUnitPaginator', {static: true}) paginator: MatPaginator;
  event: PageEvent;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  query: string;
  length: number;
  wasInFilter = false;
  searchForm: UntypedFormGroup;
  operatingUnits: OperatingUnit[] = [];

  constructor(private facadeService: FacadeService,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.pageIndex = this.route.snapshot.queryParams['pageIndex'] ?? 0;
    this.pageSize = this.route.snapshot.queryParams['pageSize'] ?? 20;

    const operatingUnitsFromRoute: OperatingUnitPage = this.route.snapshot.data['operatingUnits'];
    this.query = this.route.snapshot.queryParams['search'];


    this.length = operatingUnitsFromRoute.totalElements;
    this.operatingUnitTable = new MatTableDataSource<OperatingUnit>(operatingUnitsFromRoute.content);
    this.operatingUnitTable.paginator = this.paginator;
    this.wasInFilter = checkIfValidSearchTerm(this.query);


    this.searchForm = this.formBuilder.group({
      query: [this.query, []],
    });

    this.searchForm.get('query').valueChanges.subscribe(value => {
      this.searchOperatingUnit(value);
    });
  }

  handleChangePagination(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEventOperatingUnitData();
    return event;
  }

  getEventOperatingUnitData() {
    this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: {pageIndex: this.pageIndex, pageSize: this.pageSize, search: this.query}
      }
    );
    this.facadeService.getPageOperatingUnits(this.pageIndex, this.pageSize, {query: this.query}).subscribe(
      (res: OperatingUnitPage) => {
        this.operatingUnitTable.data = res.content;
        this.length = res.totalElements;
      }
    );
  }

  searchOperatingUnit(value: string) {
    if (checkIfValidSearchTerm(value)) {
      this.query = value;
      this.wasInFilter = true;
    } else {
      this.query = null;
      if (!this.wasInFilter) {
        return;
      }
      this.wasInFilter = false;
    }
    this.getEventOperatingUnitData();
  }
}
