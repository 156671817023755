import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExportInvoiceCreationDate } from 'src/app/shared/validator/exportInvoiceCreationDate.validator';

@Component({
  selector: 'app-export-invoices-exact-online',
  templateUrl: './export-invoices-exact-online.component.html',
  styleUrls: ['./export-invoices-exact-online.component.css']
})
export class ExportInvoicesExactOnlineComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.form.addControl('id', new UntypedFormControl());
    this.form.addControl('active', new UntypedFormControl());
    this.form.addControl('customerSize', new UntypedFormControl());
    this.form.addControl('employeePayroll', new UntypedFormControl());
    this.form.addControl('employeeSelection', new UntypedFormControl());
    this.form.addControl('extraPayroll', new UntypedFormControl());
    this.form.addControl('extraSelection', new UntypedFormControl());
    this.form.addControl('flexiPayroll', new UntypedFormControl());
    this.form.addControl('flexiSelection', new UntypedFormControl());
    this.form.addControl('studentPayrollNotSubmitted', new UntypedFormControl());
    this.form.addControl('studentPayrollSubmitted', new UntypedFormControl());
    this.form.addControl('studentSelectionNotSubmitted', new UntypedFormControl());
    this.form.addControl('studentSelectionSubmitted', new UntypedFormControl());
    this.form.addControl('workerPayroll', new UntypedFormControl());
    this.form.addControl('workerSelection', new UntypedFormControl());
  }

  clearInvoiceCreationFrom() {
    this.form.controls.invoiceCreationFrom.setValue('');
  }

  clearInvoiceCreationTo() {
    this.form.controls.invoiceCreationTo.setValue('');
  }
}


