import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {FacadeService} from '../../../../../shared/service/facade/facade.service';
import {ContractType, Request} from '../../../model/request.model';
import {WorkFunction} from '../../../../../shared/model/work-function/work-function.model';
import {StudentQuotaHour, Worker, WorkerType} from '../../../../../workers/shared/model/worker.model';
import {Customer} from '../../../../../customers/shared/model/customer.model';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarComponent} from '../../../../../shared/component/snackbar/snackbar.component';
import {ConfirmationDialogComponent} from '../../../../../shared/component/confirmation-dialog/confirmation-dialog.component';
import * as moment from 'moment';
import {fourDecimalsValidator} from '../../../../../shared/validator/four-decimals.validator';
import {twoDecimalsValidator} from '../../../../../shared/validator/two-decimals.validator';

@Component({
  selector: 'app-validate-request-dialog',
  templateUrl: './validate-request-dialog.component.html',
  styleUrls: ['./validate-request-dialog.component.css']
})
export class ValidateRequestDialogComponent implements OnInit {

  clicked = false;
  costCenters: [] = [];
  workFunctions: WorkFunction[] = [];
  contractTypes = Object.keys(ContractType).sort();
  workerTypes = Object.keys(WorkerType).sort();
  request: Request;
  minimum: number;
  requestForm: UntypedFormGroup;
  customer: Customer;
  worker: Worker;
  workerAge: number;
  isFlexi: boolean;
  studentRate: number;
  studentQuotaHoursMax: number;
  studentRateFetched = false;

  STUDENT = [WorkerType.STUDENT_SUBMITTED, WorkerType.STUDENT_NOT_SUBMITTED];

  constructor(private dialogRef: MatDialogRef<ValidateRequestDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar,
              private facadeService: FacadeService,
              private formBuilder: UntypedFormBuilder,
              private translate: TranslateService,
              private dialog: MatDialog) {
    this.request = data.request;
    this.customer = data.customer;
    this.worker = data.worker;
    this.workFunctions = data.workFunctions;
    this.costCenters = data.costCenters;
    this.isFlexi = this.request.workerType === WorkerType.FLEXI;
    this.minimum = this.isFlexi ? +data.minimum.toFixed(2) : +data.minimum.toFixed(4);
    if (!this.request.flexyRate) {
      this.request.flexyRate = this.minimum;
    }
    this.workerAge = moment().diff(this.worker.dateOfBirth, 'years');
    this.studentQuotaHoursMax = data.studentQuotaHoursMax;
  }

  ngOnInit() {
    this.formBuilderValidations();
  }

  openSnackBar(message: string, pC: string = 'customSnackValid', action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 5000,
      panelClass: [pC]
    });
  }

  openCustomSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: [pC],
      data: {
        message: message
      }
    });
  }

  onSubmit() {
    this.clicked = true;
    if (this.requestForm.valid) {
      if (!this.worker.workerStatus.idCardEndOfValidity || this.request.endDate >= this.worker.workerStatus.idCardEndOfValidity) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
          {data: {message: this.translate.instant('requests.form.validate.dialog.message')}});
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            this.validateRequest();
          } else {
            this.clicked = false;
          }
        });
      } else {
        this.validateRequest();
      }
    } else {
      this.clicked = false;
    }
  }

  setMinimumSalaryRate() {
    // https://drive.google.com/file/d/1y-0P9SxMFObTqHHytkYoydmF-ByTO-q0/view
    this.isFlexi = this.requestForm.controls.workerType.value === WorkerType.FLEXI;
    const workerType = this.requestForm.controls.workerType.value;
    this.facadeService.getMinimumSalaryScale(workerType, this.request.workFunctionId, this.request.workerId, this.request.customerId)
      .subscribe((min: number) => {
          this.formBuilderSetMinimum(min);
        }
      );
  }

  closeDialog(update = false): void {
    this.dialogRef.close(update);
  }

  isStudent(): boolean {
    return this.STUDENT.includes(this.requestForm.controls['workerType'].value);
  }

  getHintMessage() {
    if (this.isStudent()) {
      if (!this.studentRateFetched) {
        this.studentRateFetched = true;
        this.facadeService.getStudentRate(this.workerAge).subscribe(rate => {
          this.studentRate = rate;
        });
      }
      return this.translate.instant('requests.form.validate.message.hint.legalRate', {age: this.workerAge, rate: this.studentRate * 100});
    }
    this.studentRate = null;
    if (this.customer.billingData.seniorityTaken) {
      return 'This customer take into account worker seniority for salary computation';
    }
    return '';
  }

  getFlexyRateError(): string {
    if (this.requestForm.controls.flexyRate.value == null || this.requestForm.controls.flexyRate.value === '') {
      return this.translate.instant('requests.form.validate.message.error.flexyRate.required') + '<br>';
    }
    let res = '';
    if (this.requestForm.controls.flexyRate.value < this.minimum) {
      res += this.translate.instant('requests.form.validate.message.error.flexyRate.minimum', {min: this.minimum}) + '<br>';
    }
    if (fourDecimalsValidator(this.requestForm.controls.flexyRate) || twoDecimalsValidator(this.requestForm.controls.flexyRate)) {
      res += this.translate.instant('requests.form.validate.message.error.flexyRate.decimals', {max: this.isFlexi ? 2 : 4}) + '<br>';
    }
    return res;
  }

  private validateRequest() {
    Object.assign(this.request, this.requestForm.value);
    if (this.request.workerType === WorkerType.STUDENT_NOT_SUBMITTED) {
      const groupByDate = new Map();
      this.request.periods.forEach((period) => {
        const date = period.startDate.getFullYear();
        if (!groupByDate.get(date)) {
          groupByDate.set(date, 0);
        }
        groupByDate.set(date, groupByDate.get(date) + period.getDiffMs());
      });
      let errorMessage: any = null;
      groupByDate.forEach((hours, date) => {
        const studentQuotaHour: StudentQuotaHour = this.worker.studentQuotaHours.find(sqh => sqh.year === date);
        const hoursDone = studentQuotaHour != null ? studentQuotaHour.hoursDone : 0;
        if (hoursDone + hours > this.worker.workerStatus.studentJobQuotaHours ?? this.studentQuotaHoursMax) {
          return errorMessage = this.translate.instant('requests.form.validate.message.error.studentQuotaHours',
            {
              year: date,
              hoursWorker: hoursDone + hours,
              quotaHours: this.worker.workerStatus.studentJobQuotaHours ?? this.studentQuotaHoursMax
            });
        }
      });
      if (errorMessage) {
        this.clicked = false;
        return this.openSnackBar(errorMessage, 'customSnackError');
      }
    }
    this.openCustomSnackBar(this.translate.instant('requests.form.validate.message.validatingRequest'));
    this.closeDialog(true);
    this.facadeService.validateRequest(this.request).subscribe({
      next: ignoreProperty => {
        this.openSnackBar(this.translate.instant('requests.form.validate.message.success.save'));
      },
      error: () => {
        this.openSnackBar(this.translate.instant('requests.form.validate.message.error.save', 'customSnackError'));
      }
    });
  }

  private formBuilderValidations() {
    this.requestForm = this.formBuilder.group({
        contractType: [this.request.contractType, [Validators.required]],
        workerType: [this.request.workerType, [
          Validators.required,
        ]],
        flexyRate: [
          this.request.flexyRate, [
            Validators.required,
            Validators.min(this.minimum),
            this.isFlexi ? twoDecimalsValidator : fourDecimalsValidator]
        ],
        costCenter: [this.request.costCenter, (this.costCenters.length > 0) ? [Validators.required] : []],
        workFunctionId: [this.request.workFunctionId, [Validators.required]],
        description: [this.request.description, []],
      }
    );
  }

  private formBuilderSetMinimum(minimum: number) {
    if (this.isFlexi) {
      this.minimum = +minimum.toFixed(2);
      this.requestForm.controls['flexyRate'].setValidators([
        Validators.required,
        Validators.min(this.minimum),
        twoDecimalsValidator]);
    } else {
      this.minimum = +minimum.toFixed(4);
      this.requestForm.controls['flexyRate'].setValidators([
        Validators.required,
        Validators.min(this.minimum),
        fourDecimalsValidator]);
    }
    this.requestForm.controls.flexyRate.setValue(this.minimum);
  }
}
