import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Request} from 'src/app/requests/shared/model/request.model';
import {Customer} from 'src/app/customers/shared/model/customer.model';
import {Worker} from 'src/app/workers/shared/model/worker.model';

@Component({
  selector: 'app-request-edit',
  templateUrl: './request-edit.component.html',
  styleUrls: ['./request-edit.component.css']
})
export class RequestEditComponent implements OnInit {
  request: Request = new Request();
  customers: Customer[] = [];
  workers: Worker[] = [];

  constructor(public route: ActivatedRoute) {
    this.getRequest();    
    this.getWorker();
    this.getCustomer();
  }

  ngOnInit() {
  }

  private getRequest() {
    const data = this.route.snapshot.data['request'];
    Object.assign(this.request, data);
  }

  private getWorker() {
    const data = this.route.snapshot.data['workers'];
    const worker: Worker = new Worker();
    Object.assign(worker, data);
    this.workers.push(worker);
  }

  private getCustomer() {
    const data = this.route.snapshot.data['customers'];
    const customer: Customer = new Customer();
    Object.assign(customer, data);
    this.customers.push(customer);
  }

}
