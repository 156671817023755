export class Address {
  street: string;
  number: string;
  box: string;
  zip: string;
  city: string;
  country: string;

  get formattedAddress() {
    let address = `${this.street ? this.street : ''}, ${this.number ? this.number : ''}  `;
    address += `${this.box ? this.box : ''} `;
    address += `${this.zip ? this.zip : ''} ${this.city ? this.city : ''} ( ${this.country ? this.country : ''} )`;
    address = address.replace(/,\s*,/, ',');
    address = address.replace(/,\s*-/, ' - ');
    return address;
  }
}
