import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StudentQuotaHours } from 'src/app/admin/model/student-quota-hours.model';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';

@Component({
  selector: 'app-student-quota-hours-dialog',
  templateUrl: './student-quota-hours-dialog.component.html',
  styleUrls: ['./student-quota-hours-dialog.component.css']
})
export class StudentQuotaHoursDialogComponent implements OnInit {

  studentQuotaHours: StudentQuotaHours;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<StudentQuotaHoursDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {
    this.studentQuotaHours = this.data.element;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [this.studentQuotaHours.id ? this.studentQuotaHours.id : null, []],
      active: [this.studentQuotaHours.active ? this.studentQuotaHours.active : true, [Validators.required]],
      maxHours: [this.studentQuotaHours.maxHours ? this.studentQuotaHours.maxHours : 0, []],
      year: [this.studentQuotaHours.year ? this.studentQuotaHours.year : 2025, [Validators.required]],
    }
    );
  }

  onSubmit() {
    Object.assign(this.studentQuotaHours, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.facade.saveStudentQuotaHours(this.studentQuotaHours)
      .subscribe({
        next: studentQuotaHours => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: studentQuotaHours.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

  clearDate(): void {
    this.form.controls.date.setValue('');
  }

}
