import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Request} from '../model/request.model';
import {RequestSerializer} from '../model/request.serializer';
import {map} from 'rxjs/operators';
import {AuthorizationService} from '../../../login/shared/service/authorization.service';
import {Role} from '../../../users/shared/model/user.model';
import {RequestPage} from '../../../shared/model/pagination/request/request-page.model';
import {RequestPageSerializer} from '../../../shared/model/pagination/request/request-page.serializer';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private serviceURL = environment.request_service_URL + 'requests';
  private requestSerializer: RequestSerializer = new RequestSerializer();
  private requestPageSerializer = new RequestPageSerializer();


  constructor(private http: HttpClient,
              private authorizationService: AuthorizationService) {
  }

  copyWithNewDate(request: Request): Observable<any> {
    return this.http.post<Request>(this.serviceURL + '/copy', this.requestSerializer.toJson(request)).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  get(id: number): Observable<any> {
    return this.http.get<Request>(this.serviceURL + '/' + id).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  list(): Observable<any> {
    return this.http.get<Request[]>(this.serviceURL).pipe(
      map((data) => this.convertData(data))
    );
  }

  getPage(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<RequestPage>(this.serviceURL + '/page', {params}).pipe(
      map((data) => this.requestPageSerializer.fromJson(data)));
  }

  getPagePending(index: number, size: number, customerId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }
    return this.http.get<RequestPage>(this.serviceURL + '/page-pending', {params}).pipe(
      map((data) => this.requestPageSerializer.fromJson(data))
    );
  }

  save(request: Request): Observable<any> {
    return this.http.post<Request>(this.serviceURL, this.requestSerializer.toJson(request)).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  delete(request: Request): Observable<any> {
    return this.http.delete<Request>(this.serviceURL + '/' + request.id);
  }

  validate(request: Request): Observable<any> {
    return this.http.post<Request>(this.serviceURL + '/validate', this.requestSerializer.toJson(request)).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  modify(request: Request): Observable<any> {
    return this.http.post<Request>(this.serviceURL + '/' + request.id + '/modify', this.requestSerializer.toJson(request)).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  getPendingRequestByWorker(id: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('workerId', String(id));
    return this.http.get<Request[]>(this.serviceURL + '/pending-worker', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  getPendingRequestByCustomer(id: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('customerId', String(id));
    return this.http.get<Request[]>(this.serviceURL + '/pending-customer', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  getPageDeadline(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<RequestPage>(this.serviceURL + '/page-deadline', {params}).pipe(
      map((data) => this.requestPageSerializer.fromJson(data))
    );
  }

  getPageErrors(index: number, size: number) {
    if (!this.authorizationService.hasRole(Role.SUPERVISOR)) {
      return throwError(() => 'unauthorized');
    }
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<RequestPage>(this.serviceURL + '/page-in-error', {params}).pipe(
      map((data) => this.requestPageSerializer.fromJson(data))
    );
  }

  paid(request: Request): Observable<any> {
    return this.http.post<Request>(this.serviceURL + '/' + request.id + '/paid', null).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  unpaid(request: Request): Observable<any> {
    return this.http.post<Request>(this.serviceURL + '/' + request.id + '/unpaid', null).pipe(
      map((data) => this.requestSerializer.fromJson(data))
    );
  }

  private convertData(data: any): Request[] {
    return data.map(request => this.requestSerializer.fromJson(request));
  }
}
