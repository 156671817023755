import {Serializer} from 'src/app/shared/model/serializer';
import {Language} from './language.model';

export class LanguageSerializer implements Serializer {
  fromJson(json: any): Language {
    const language: Language = new Language();

    language.id = json.id;
    language.active = json.active;
    language.code = json.code;
    language.nameDE = json.nameDE;
    language.nameEN = json.nameEN;
    language.nameFR = json.nameFR;
    language.nameNL = json.nameNL;
    return language;
  }

  toJson(language: Language): any {
    return {
      id: language.id,
      active: language.active,
      code: language.code,
      nameDE: language.nameDE,
      nameEN: language.nameEN,
      nameFR: language.nameFR,
      nameNL: language.nameNL
    };
  }
}
