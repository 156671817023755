import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FacadeService } from '../../../shared/service/facade/facade.service';
import { MatDialog } from '@angular/material/dialog';
import { PublicHoliday } from '../../model/public-holiday.model';
import { PublicHolidayPage } from 'src/app/shared/model/pagination/public-holiday/public-holiday-page.model';
import { PublicHolidayDialogComponent } from 'src/app/shared/component/admin-dialog/public-holiday-dialog/public-holiday-dialog.component';

@Component({
  selector: 'app-public-holiday',
  templateUrl: './public-holiday.component.html',
  styleUrls: ['./public-holiday.component.css', '../../admin.component.css']
})
export class PublicHolidayComponent implements OnInit {
  @ViewChild('PublicHolidayPaginator', { static: true }) paginator: MatPaginator;

  publicHoliday: PublicHoliday;

  dataSource = new MatTableDataSource<PublicHoliday>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "description",
    "date"
  ];

  public getData(event?: PageEvent) {
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPagePublicHoliday(this.pageIndex, this.pageSize).subscribe(
      (data: PublicHolidayPage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      }
    );
    return event;
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(PublicHolidayDialogComponent, {
      data: { element: row },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  addElement() {
    this.publicHoliday = new PublicHoliday;
    this.openPopUp(this.publicHoliday);
  }

}
