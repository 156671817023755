import { StudentQuotaHours } from 'src/app/admin/model/student-quota-hours.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FacadeService } from '../../../shared/service/facade/facade.service';
import { MatDialog } from '@angular/material/dialog';
import { StudentQuotaHoursPage } from 'src/app/shared/model/pagination/student-quota-hour/student-quota-hours-page.model';
import { StudentQuotaHoursDialogComponent } from 'src/app/shared/component/admin-dialog/student-quota-hours-dialog/student-quota-hours-dialog.component';

@Component({
  selector: 'app-student-quota-hours',
  templateUrl: './student-quota-hours.component.html',
  styleUrls: ['./student-quota-hours.component.css', '../../admin.component.css']
})
export class StudentQuotaHoursComponent implements OnInit {
@ViewChild('StudentQuotaHoursPaginator', { static: true }) paginator: MatPaginator;

  studentQuotaHours: StudentQuotaHours;

  dataSource = new MatTableDataSource<StudentQuotaHours>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  displayedColumns: string[] = [
    "id",
    "active",
    "maxHours",
    "year"
  ];

  public getData(event?: PageEvent) {
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPageStudentQuotaHours(this.pageIndex, this.pageSize).subscribe(
      (data: StudentQuotaHoursPage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      }
    );
    return event;
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(StudentQuotaHoursDialogComponent, {
      data: { element: row },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  addElement() {
    this.studentQuotaHours = new StudentQuotaHours;
    this.openPopUp(this.studentQuotaHours);
  }

}
