import {Injectable} from '@angular/core';
import {HttpUrlEncodingCodec} from '@angular/common/http';
import {Address} from '../../model/address/address.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  private httpUrlEncodingCodec: HttpUrlEncodingCodec;

  constructor() {
    this.httpUrlEncodingCodec = new HttpUrlEncodingCodec();
  }

  getLinkWithAddress(address: Address) {
    return 'https://www.google.com/maps/search/?api=1&query=' + this.httpUrlEncodingCodec.encodeValue(address.formattedAddress);
  }
}
