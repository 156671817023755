import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-export-invoices',
  templateUrl: './export-invoices.component.html',
  styleUrls: ['./export-invoices.component.css']
})
export class ExportInvoicesComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.form.addControl('prestationStartDateFrom', new UntypedFormControl());
    this.form.addControl('prestationStartDateTo', new UntypedFormControl());
    this.form.addControl('prestationEndDateFrom', new UntypedFormControl());
    this.form.addControl('prestationEndDateTo', new UntypedFormControl());
    this.form.addControl('invoiceCreationDateFrom', new UntypedFormControl());
    this.form.addControl('invoiceCreationDateTo', new UntypedFormControl());
    this.form.addControl('invoiceDueDateFrom', new UntypedFormControl());
    this.form.addControl('invoiceDueDateTo', new UntypedFormControl());
    this.form.addControl('customerId', new UntypedFormControl());
  }

  clearFromStartDatePrestation() {
    this.form.controls.prestationStartDateFrom.setValue('');
  }

  clearToStartDatePrestation() {
    this.form.controls.prestationStartDateTo.setValue('');
  }

  clearFromEndDatePrestation() {
    this.form.controls.prestationEndDateFrom.setValue('');
  }

  clearToEndDatePrestation() {
    this.form.controls.prestationEndDateTo.setValue('');
  }

  clearFromCreationInvoice() {
    this.form.controls.invoiceCreationDateFrom.setValue('');
  }

  clearToCreationInvoice() {
    this.form.controls.invoiceCreationDateTo.setValue('');
  }

  clearFromDueDateInvoice() {
    this.form.controls.invoiceDueDateFrom.setValue('');
  }

  clearToDueDateInvoice() {
    this.form.controls.invoiceDueDateTo.setValue('');
  }
}
