import {Serializer} from '../../serializer';
import {RequestSerializer} from '../../../../requests/shared/model/request.serializer';
import {RequestPage} from './request-page.model';

export class RequestPageSerializer implements Serializer {

  private requestSerializer = new RequestSerializer();

  fromJson(json: any): RequestPage {
    const requestPage = new RequestPage();

    json.content.forEach(
      content => {
        requestPage.content.push(this.requestSerializer.fromJson(content));
      }
    );
    requestPage.totalElements = json.totalElements;
    requestPage.totalPages = json.totalPages;
    return requestPage;
  }

  toJson(resource: Object): any {
  }
}

