import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {OperatingUnitPage} from '../../model/pagination/operating-unit/operating-unit-page.model';

@Injectable()
export class OperatingUnitsPageResolver implements Resolve<Observable<OperatingUnitPage>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<OperatingUnitPage> {

    const pageIndex = route.queryParams['pageIndex'] ?? 0;
    const pageSize = route.queryParams['pageSize'] ?? 20;
    const query = route.queryParams['search'];

    return this.facadeService.getPageOperatingUnits(pageIndex, pageSize, {query: query}).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
