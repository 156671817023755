import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SubsidiaryAddress} from '../../../../model/customer.model';
import {Country} from '../../../../../../shared/model/country/country.model';


export class TempSubsidiaryAddress extends SubsidiaryAddress {


  tempId: string;

  constructor() {
    super();
  }

  convertFromSub(subsidiaryAddress: SubsidiaryAddress): TempSubsidiaryAddress {
    this.tempId = String(subsidiaryAddress.id);
    this.id = subsidiaryAddress.id;
    this.name = subsidiaryAddress.name;
    this.street = subsidiaryAddress.street;
    this.number = subsidiaryAddress.number;
    this.box = subsidiaryAddress.box;
    this.zip = subsidiaryAddress.zip;
    this.city = subsidiaryAddress.city;
    this.country = subsidiaryAddress.country;
    return this;
  }
}

@Component({
  selector: 'app-subsidiary-customer-dialog',
  templateUrl: './subsidiary-customer-dialog.component.html',
  styleUrls: ['./subsidiary-customer-dialog.component.css']
})
export class SubsidiaryCustomerDialogComponent implements OnInit {

  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  selectedSubsidiaryAddress: TempSubsidiaryAddress;
  subsidiaryAddresses: TempSubsidiaryAddress[];
  form: UntypedFormGroup;
  countries: Country[] = [];
  tempId = 0;

  constructor(private dialogRef: MatDialogRef<SubsidiaryCustomerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private formBuilder: UntypedFormBuilder) {
    this.countries = data.countries;
    this.subsidiaryAddresses = data.subsidiaryAddresses;
  }

  ngOnInit() {
    this.initForm(null);
  }

  edit(subsidiaryAddress: TempSubsidiaryAddress) {
    this.selectedSubsidiaryAddress = subsidiaryAddress;
    this.initForm(subsidiaryAddress);
  }

  delete(subsidiaryAddress: TempSubsidiaryAddress) {
    this.subsidiaryAddresses = this.subsidiaryAddresses.filter(value => value.tempId !== subsidiaryAddress.tempId);
  }

  reset() {
    this.selectedSubsidiaryAddress = null;
    this.formDirective.resetForm();
  }

  onSubmit() {
    if (this.form.valid) {
      const subsidiaryAddress = new TempSubsidiaryAddress();
      Object.assign(subsidiaryAddress, this.form.value);
      if (this.selectedSubsidiaryAddress) {
        subsidiaryAddress.tempId = this.selectedSubsidiaryAddress.tempId;
        subsidiaryAddress.id = this.selectedSubsidiaryAddress.id;
        this.subsidiaryAddresses = this.subsidiaryAddresses.map(value => {
          value = value.tempId === subsidiaryAddress.tempId ? subsidiaryAddress : value;
          return value;
        });
      } else {
        subsidiaryAddress.tempId = this.getTempId();
        this.subsidiaryAddresses.push(subsidiaryAddress);
      }
      this.reset();
    }
  }

  isEditing(): boolean {
    return !!(this.selectedSubsidiaryAddress);
  }

  getTempId(): string {
    this.tempId++;
    return 'temp_' + this.tempId;
  }

  close() {
    this.dialogRef.close();
  }

  validate() {
    this.dialogRef.close(this.subsidiaryAddresses);
  }

  private initForm(subsidiaryAddress: TempSubsidiaryAddress) {
    this.form = this.formBuilder.group({
      name: [!subsidiaryAddress ? null : subsidiaryAddress.name, [Validators.required]],
      street: [!subsidiaryAddress ? null : subsidiaryAddress.street, [Validators.required]],
      number: [!subsidiaryAddress ? null : subsidiaryAddress.number, [Validators.required]],
      box: [!subsidiaryAddress ? null : subsidiaryAddress.box, []],
      zip: [!subsidiaryAddress ? null : subsidiaryAddress.zip, [Validators.required]],
      city: [!subsidiaryAddress ? null : subsidiaryAddress.city, [Validators.required]],
      country: [!subsidiaryAddress ? null : subsidiaryAddress.country, [Validators.required]]
    });
  }

}
