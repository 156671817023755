import {AbstractControl} from '@angular/forms';

export function twoDecimalsValidator(control: AbstractControl) {
  const DECIMALS_PATTERN = /^\d+([,.]\d{1,2})?$/;

  if (control && (control.value !== null || true)) {
    const regex = new RegExp(DECIMALS_PATTERN);

    if (!regex.test(control.value)) {
      return {
        isError: true
      };
    }
  }

  return null;
}
