import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Prestation, PrestationStatus} from '../../shared/model/prestation.model';
import {MatTableDataSource} from '@angular/material/table';
import {PrestationPeriodCode} from '../../shared/model/prestation-period-code.model';
import {PrestationPeriod} from '../../shared/model/prestation-period.model';
import {Customer} from '../../../customers/shared/model/customer.model';
import {ValidationCorrectionDialogComponent} from '../validation-correction-dialog/validation-correction-dialog.component';
import {EditCorrectionDialogComponent} from '../edit-correction-dialog/edit-correction-dialog.component';
import {
  CancelCorrectionPrestationDialogComponent
} from '../cancel-correction-prestation-dialog/cancel-correction-prestation-dialog.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-listing-prestation',
  templateUrl: './listing-prestation.component.html',
  styleUrls: ['./listing-prestation.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})
export class ListingPrestationComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @Input() customers: Customer[];
  @Input() requestId: number;
  // @Input() prestations: Prestation[];
  displayedPrestationColumns: string[] =
    ['id', 'worker', 'payment', 'startDate', 'endDate', 'costCenter', 'workerType', 'status', 'totalBilling'];
  displayedPrestationPeriodCodeColumns: string[] = ['code', 'description', 'number', 'days', 'totalBasedSalary', 'rate', 'percentage',
    'total', 'coefficient', 'billingPercentage', 'billingTotal'];
  prestationDataSource = new MatTableDataSource<Prestation>();
  prestationPeriodCodeDataSource = new MatTableDataSource<PrestationPeriodCode>();
  expandedPrestation: Prestation | null;
  prestationPeriodSelected: PrestationPeriod | null;
  prestationPeriodValidated: PrestationPeriod | null;

  constructor(private dialog: MatDialog,
              private facadeService: FacadeService) {
  }

  @Input()
  set prestations(val: any) {
    if (this.requestId === undefined || this.requestId === null) {
      this.setPrestationDataSource(val);
    }
  }

  get PrestationStatus(){
    return PrestationStatus;
  }

  ngOnInit(): void {
    if (this.requestId !== undefined && this.requestId !== null) {
      this.getPrestationWithValidContract();
    }
  }

  getPrestationWithValidContract() {
    this.facadeService.filterPrestationWithValidContracts(this.requestId).subscribe({
      next: (prestations: Prestation[]) => {
        this.setPrestationDataSource(prestations);
      }
    });
  }

  setPrestationPeriodSelected(period: PrestationPeriod) {
    if (period === this.prestationPeriodSelected) {
      return this.prestationPeriodSelected = null;
    }
    if (this.prestationPeriodValidated && (this.prestationPeriodValidated.id !== period.id)) {
      this.prestationPeriodValidated = null;
    }
    this.prestationPeriodSelected = period;
    this.setPrestationPeriodCodeDataSource(period.prestationPeriodCodes);
  }

  getCustomer(customerId: number): Customer {
    return this.customers.find(c => c.id === customerId);
  }

  getTotalBillingForPrestation(prestation: Prestation) {
    let total = 0;
    prestation.periods.forEach(
      p => {
        p.prestationPeriodCodes.forEach(
          pPC => {
            total += pPC.billingTotal;
          }
        );
      }
    );
    return total;
  }

  validateCorrectionPrestation() {
    this.facadeService.getAllCorrectionTypes().subscribe(corrections => {
      const dialogRef = this.dialog.open(ValidationCorrectionDialogComponent, {
        data: {
          prestationPeriod: <PrestationPeriod>this.prestationPeriodSelected,
          prestation: <Prestation>this.expandedPrestation,
          correctionTypes: corrections
        }
      });

      dialogRef.afterClosed().subscribe((result: PrestationPeriod) => {
      });
    });
  }

  editCorrectionPrestation() {
    this.facadeService.getPrestationCodesCorrections().subscribe(codes => {
        const dialogRef = this.dialog.open(EditCorrectionDialogComponent, {
          data: {
            prestationPeriod: <PrestationPeriod>this.prestationPeriodSelected,
            prestation: <Prestation>this.expandedPrestation,
            prestationCodes: codes
          }
        });

        dialogRef.afterClosed().subscribe((result: PrestationPeriod) => {
          if (result) {
            this.reloadPrestion(result.id);
          }
        });
      }
    );
  }

  cancelCorrectionPrestation(period: PrestationPeriod) {
    period.prestation = this.expandedPrestation;
    forkJoin([
      this.facadeService.getAllCorrectionTypes(),
      this.facadeService.getPrestationCodeJustification()
    ]).subscribe(([corrections, justifications]) => {
      const dialogRef = this.dialog.open(CancelCorrectionPrestationDialogComponent, {
        data: {
          prestationPeriod: period,
          correctionTypes: corrections,
          justificationPrestationCodes: justifications
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.prestationPeriodValidated = result;
        }
      });
    });
  }

  private setPrestationDataSource(prestations: Prestation[]) {
    this.prestationDataSource = new MatTableDataSource<Prestation>(prestations);
    this.prestationDataSource.paginator = this.paginator;
  }

  private setPrestationPeriodCodeDataSource(prestationPeriodCode: PrestationPeriodCode[]) {
    this.prestationPeriodCodeDataSource = new MatTableDataSource<PrestationPeriodCode>(prestationPeriodCode);
  }

  private reloadPrestion(id: number) {
  }
}
