import {Component, Input} from '@angular/core';
import {BusinessContact, ContactMethod} from '../../shared/model/customer.model';

@Component({
  selector: 'app-business-contact-view',
  templateUrl: './business-contact-view.component.html',
  styleUrls: ['./business-contact-view.component.css']
})
export class BusinessContactViewComponent {
  @Input() businessContact: BusinessContact;

  constructor() {
  }

  get ContactMethod() {
    return ContactMethod;
  }

}
