import {PrestationPeriod} from './prestation-period.model';

export class PrestationPeriodCode {
  prestationPeriod: PrestationPeriod;

  id: number;
  code: string;
  ssCode: string;
  ssCodeType: string;
  labelFR: string;
  labelEN: string;
  labelNL: string;
  description: string;
  workerType: string;
  startDate: Date;
  endDate: Date;
  calculationDate: Date;
  days: number;
  totalBasedSalary: number;
  hours: Date;
  hoursDecimal: number;
  prestationCoefficient: number;
  prestationPercentage: number;
  salary: number;
  prestationSalary: number;
  billingDate: Date;
  billingRate: number;
  billingPercentage: number;
  billingTotal: number;
  billingCoefficient: number;
  active: boolean;
}
