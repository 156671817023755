import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthorizationService} from '../service/authorization.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    public auth: AuthorizationService,
    public router: Router) {
  }

  canActivate(): boolean {

    if (!this.auth.loggedIn()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
