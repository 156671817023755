export enum TypeFileWorker {
  CV = 'CV',
  MEDICAL_ATTESTATION = 'MEDICAL_ATTESTATION',
  ID_CARD = 'ID_CARD',
  BANK_DATA = 'BANK_DATA',
  PENDING = 'PENDING',
  OTHER = 'OTHER',
}

export enum TypeFileCustomer {
  RFA = 'RFA',
  ADMIN_FORM = 'ADMIN_FORM',
  OFFER = 'OFFER',
  EXECUTIVE_CONTRACT = 'EXECUTIVE_CONTRACT',
  OTHER = 'OTHER',
}

export class Document {
  fileName: string;
  originalFileName: string;
  typeFile: TypeFileWorker | TypeFileCustomer;
  contentType: string;
  consultant: string;
  comment: string;
  reportMessage: string;
  dateUploaded: Date;
  dateProcessed: Date;
  endDate: Date;  //  MEDICAL_ATTESTATION
  startDate: Date;  //  MEDICAL_ATTESTATION
}
