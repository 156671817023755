import {Serializer} from './../../shared/model/serializer';
import { TravelExpenseCoefficient } from './travel-expense-coefficient.model';

export class TravelExpenseCoefficientSerializer implements Serializer {

  fromJson(json: any): TravelExpenseCoefficient {
    const travelExpenseCoefficient: TravelExpenseCoefficient = new TravelExpenseCoefficient();

    travelExpenseCoefficient.id = json.id;
    travelExpenseCoefficient.active = json.active;
    travelExpenseCoefficient.distanceKM = json.distance;
    travelExpenseCoefficient.weeklyA5 = json.weeklyA5;
    travelExpenseCoefficient.weeklyA6 = json.weeklyA6;
    travelExpenseCoefficient.weeklyA7 = json.weeklyA7;
    travelExpenseCoefficient.weeklyIndemnities = json.weeklyIndemnities;

    return travelExpenseCoefficient;
  }

  toJson(travelExpenseCoefficient: TravelExpenseCoefficient): any {
    return {
      id: travelExpenseCoefficient.id,
      active: travelExpenseCoefficient.active,
      distance: travelExpenseCoefficient.distanceKM,
      weeklyA5: travelExpenseCoefficient.weeklyA5,
      weeklyA6: travelExpenseCoefficient.weeklyA6,
      weeklyA7: travelExpenseCoefficient.weeklyA7,
      weeklyIndemnities: travelExpenseCoefficient.weeklyIndemnities,
    };
  }

  }
