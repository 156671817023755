import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {RequestPage} from '../../model/pagination/request/request-page.model';

@Injectable()
export class DeadlineRequestsResolver implements Resolve<Observable<RequestPage>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<RequestPage> {
    return this.facadeService.getPageDeadlineRequests(0, 5).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
