import {CustomerPage} from './customer-page.model';
import {Serializer} from '../../serializer';
import {CustomerSerializer} from '../../../../customers/shared/model/customer.serializer';

export class CustomerPageSerializer implements Serializer {

  private customerSerializer = new CustomerSerializer();

  fromJson(json: any): CustomerPage {
    const customerPage = new CustomerPage();

    json.content.forEach(
      content => {
        customerPage.content.push(this.customerSerializer.fromJson(content));
      }
    );

    customerPage.totalElements = json.totalElements;
    customerPage.totalPages = json.totalPages;
    return customerPage;
  }

  toJson(resource: Object): any {
  }
}

