import {Component, Inject} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FacadeService} from '../../../../../shared/service/facade/facade.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Customer} from '../../../../../customers/shared/model/customer.model';
import {Worker} from '../../../../../workers/shared/model/worker.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

class DimonaPeriodDetail {
  workerNiss: string;
  customerEnterpriseNumber: string;
}

enum DimonaCodeError {
  NISS_NOT_AVAILABLE = '00024-001',
  NISS_INVALID = '00024-003',
  INVALID_COMPANY_NUMBER = '00131-003',
}

@Component({
  selector: 'app-error-detail-dialog',
  templateUrl: './error-detail-dialog.component.html',
  styleUrls: ['./error-detail-dialog.component.css']
})
export class ErrorDetailDialogComponent {
  dimonaPeriodDetail: DimonaPeriodDetail = new DimonaPeriodDetail();

  constructor(private dialogRef: MatDialogRef<ErrorDetailDialogComponent>,
              private facadeService: FacadeService,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService) {
  }

  get DimonaCodeError() {
    return DimonaCodeError;
  }

  updateDetail(form: NgForm) {
    if (form.valid) {
      switch (this.data.dimonaPeriod.errorCodes) {
        case DimonaCodeError.NISS_INVALID:
          this.updateWorkerNiss(form.value.workerNiss);
          break;
        case DimonaCodeError.NISS_NOT_AVAILABLE:
          this.updateWorkerNiss(form.value.workerNiss);
          break;
        case DimonaCodeError.INVALID_COMPANY_NUMBER:
          this.updateEnterpriseNumber(form.value.customerEnterpriseNumber);
          break;
      }
    }
  }

  redirectToRequest() {
    this.closeDialog();
    return this.router.navigate(['request-edit'], {queryParams: {'r': this.data.request.id}});
  }

  canBeEditOnDialog() {
    return [
      DimonaCodeError.NISS_INVALID,
      DimonaCodeError.NISS_NOT_AVAILABLE,
      DimonaCodeError.INVALID_COMPANY_NUMBER
    ].includes(this.data.dimonaPeriod.errorCodes);
  }

  closeDialog(update = false): void {
    this.dialogRef.close(update);
  }

  private updateWorkerNiss(niss: string) {
    this.facadeService.getWorkerById(this.data.request.workerId)
      .subscribe(
        (worker: Worker) => {
          worker.niss = niss;
          this.facadeService.saveWorker(worker).subscribe(
            ignoreProperty => {
              this.modifyRequest();
            }
          );
        },
      );
  }

  private updateEnterpriseNumber(vatNumber: string) {
    this.facadeService.getCustomerById(this.data.request.customerId).subscribe({
      next: (customer: Customer) => {
        customer.enterpriseNumber = vatNumber;
        this.saveCustomer(customer);
      },
      error: ignoreProperty => {
        alert(this.translate.instant('requests.requestsError.dialog.message.error.getCustomer'));
      }
    });
  }

  private saveCustomer(customer: Customer) {
    this.facadeService.saveCustomer(customer).subscribe({
      next: ignoreProperty => {
        this.modifyRequest();
      },
      error: ignoreProperty => {
        alert(this.translate.instant('requests.requestsError.dialog.message.error.saveCustomer'));
      }
    });
  }

  private modifyRequest() {
    this.facadeService.modifyRequest(this.data.request).subscribe({
      next:
        ignoreProperty => {
          this.closeDialog(true);
        },
      error: ignoreProperty => {
        alert(this.translate.instant('requests.requestsError.dialog.message.error.request'));
      }

    });
  }
}
