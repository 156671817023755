import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { JointCommissionCoefficientAdmin } from 'src/app/admin/model/joint-commission-coefficient.model';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';

@Component({
  selector: 'app-joint-commission-coefficient-dialog',
  templateUrl: './joint-commission-coefficient-dialog.component.html',
  styleUrls: ['./joint-commission-coefficient-dialog.component.css']
})
export class JointCommissionCoefficientDialogComponent implements OnInit {

  joinCommissionCoefficients: JointCommissionCoefficientAdmin;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<JointCommissionCoefficientDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    this.joinCommissionCoefficients = this.data.element;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [this.joinCommissionCoefficients.id? this.joinCommissionCoefficients.id : null, []],
      active: [this.joinCommissionCoefficients.active ? this.joinCommissionCoefficients.active : true, [Validators.required]],
      jointCommissionCode: [this.joinCommissionCoefficients.jointCommissionCode ? this.joinCommissionCoefficients.jointCommissionCode : "", []],
      nightShiftWorkType: [this.joinCommissionCoefficients.nightShiftWorkType ? this.joinCommissionCoefficients.nightShiftWorkType : "", [Validators.required]],
      clothesBillingCoefficient: [this.joinCommissionCoefficients.clothesBillingCoefficient ? this.joinCommissionCoefficients.clothesBillingCoefficient : 0, [Validators.required]],
      coefficientClothes: [this.joinCommissionCoefficients.coefficientClothes ? this.joinCommissionCoefficients.coefficientClothes : 0, [Validators.required]],
      coefficientEcoCheques: [this.joinCommissionCoefficients.coefficientEcoCheques ? this.joinCommissionCoefficients.coefficientEcoCheques : 0, [Validators.required]],
      coefficientPension: [this.joinCommissionCoefficients.coefficientPension ? this.joinCommissionCoefficients.coefficientPension : 0, [Validators.required]],
      coefficientPurchasing: [this.joinCommissionCoefficients.coefficientPurchasing ? this.joinCommissionCoefficients.coefficientPurchasing : 0, [Validators.required]],
      dimonaBillingCoefficient: [this.joinCommissionCoefficients.dimonaBillingCoefficient ? this.joinCommissionCoefficients.dimonaBillingCoefficient : 0, [Validators.required]],
      dimonaFee: [this.joinCommissionCoefficients.dimonaFee ? this.joinCommissionCoefficients.dimonaFee : 0, [Validators.required]],
      ecoChequeBillingCoefficient: [this.joinCommissionCoefficients.ecoChequeBillingCoefficient ? this.joinCommissionCoefficients.ecoChequeBillingCoefficient : 0, [Validators.required]],
      flexyRate: [this.joinCommissionCoefficients.flexyRate ? this.joinCommissionCoefficients.flexyRate : 0, [Validators.required]],
      mealEmployer: [this.joinCommissionCoefficients.mealEmployer ? this.joinCommissionCoefficients.mealEmployer : 0, [Validators.required]],
      mealFacial: [this.joinCommissionCoefficients.mealFacial ? this.joinCommissionCoefficients.mealFacial : 0, [Validators.required]],
      mealVoucher: [this.joinCommissionCoefficients.mealVoucher ? this.joinCommissionCoefficients.mealVoucher : 0, [Validators.required]],
      pensionBillingCoefficient: [this.joinCommissionCoefficients.pensionBillingCoefficient ? this.joinCommissionCoefficients.pensionBillingCoefficient : 0, [Validators.required]],
      purchasingBillingCoefficient: [this.joinCommissionCoefficients.purchasingBillingCoefficient ? this.joinCommissionCoefficients.purchasingBillingCoefficient : 0, [Validators.required]],
      travelExpenseBillingCoefficient: [this.joinCommissionCoefficients.travelExpenseBillingCoefficient ? this.joinCommissionCoefficients.travelExpenseBillingCoefficient : 0, [Validators.required]],
      travelExpenses: [this.joinCommissionCoefficients.travelExpenses ? this.joinCommissionCoefficients.travelExpenses : 0, [Validators.required]],
      riskedActivity: [this.joinCommissionCoefficients.riskedActivity ? this.joinCommissionCoefficients.riskedActivity : false, [Validators.required]],
      rtt: [this.joinCommissionCoefficients.rtt ? this.joinCommissionCoefficients.rtt : false, [Validators.required]],
      whCutSchedule: [this.joinCommissionCoefficients.whCutSchedule ? this.joinCommissionCoefficients.whCutSchedule : 0, [Validators.required]],
      whLate: [this.joinCommissionCoefficients.whLate ? this.joinCommissionCoefficients.whLate : 0, [Validators.required]],
      whNight: [this.joinCommissionCoefficients.whNight ? this.joinCommissionCoefficients.whNight : 0, [Validators.required]],
      whPublicHoliday: [this.joinCommissionCoefficients.whPublicHoliday ? this.joinCommissionCoefficients.whPublicHoliday : 0, [Validators.required]],
      whSaturday: [this.joinCommissionCoefficients.whSaturday ? this.joinCommissionCoefficients.whSaturday : 0, [Validators.required]],
      whSunday: [this.joinCommissionCoefficients.whSunday ? this.joinCommissionCoefficients.whSunday : 0, [Validators.required]],
    }
    );
  }

  onSubmit() {
    Object.assign(this.joinCommissionCoefficients, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.facade.saveJointCommissionCoefficients(this.joinCommissionCoefficients)
      .subscribe({
        next: joinCommissionCoefficients => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: joinCommissionCoefficients.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

}