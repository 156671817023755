import {Serializer} from '../../serializer';
import {ContractSerializer} from '../../../../contracts/shared/model/contract.serializer';
import {ContractPage} from './contract-page.model';

export class ContractPageSerializer implements Serializer {

  private contractSerializer = new ContractSerializer();

  fromJson(json: any): ContractPage {
    const contractPage = new ContractPage();

    json.content.forEach(
      content => {
        contractPage.content.push(this.contractSerializer.fromJson(content));
      }
    );

    contractPage.totalElements = json.totalElements;
    contractPage.totalPages = json.totalPages;
    return contractPage;
  }

  toJson(resource: Object): any {
  }
}

