import { DateSerializer } from 'src/app/shared/model/date.serializer';
import {Serializer} from './../../shared/model/serializer';
import { PublicHoliday } from './public-holiday.model';

export class PublicHolidaySerializer implements Serializer {

  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): PublicHoliday {
    const publicHoliday: PublicHoliday = new PublicHoliday();

    publicHoliday.id = json.id;
    publicHoliday.active = json.active;
    publicHoliday.description = json.description;
    publicHoliday.date = this.dateSerializer.fromJson(json.date);

    return publicHoliday;
  }

  toJson(publicHoliday: PublicHoliday): any {
    return {
      id: publicHoliday.id,
      active: publicHoliday.active,
      description: publicHoliday.description,
      date: this.dateSerializer.toJson(publicHoliday.date),
    };
  }

}
