import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from './shared/model/user.model';
import {ActivatedRoute} from '@angular/router';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FacadeService} from '../shared/service/facade/facade.service';
import {UserPage} from '../shared/model/pagination/user/user-page.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = ['id', 'username', 'lastName', 'firstName'];
  @ViewChild('usersPaginator', {static: true}) paginatorConsultant: MatPaginator;
  pageEventConsultant: PageEvent;
  pageIndexConsultant: number;
  pageSizeConsultant: number;
  lengthConsultant: number;
  usersConsultantMatTable = new MatTableDataSource<User>();


  @ViewChild('usersPaginator', {static: true}) paginatorWorker: MatPaginator;

  pageEventWorker: PageEvent;
  pageIndexWorker: number;
  pageSizeWorker: number;
  lengthWorker: number;
  usersWorkerMatTable = new MatTableDataSource<User>();


  @ViewChild('usersPaginator', {static: true}) paginatorCustomer: MatPaginator;

  pageEventCustomer: PageEvent;
  pageIndexCustomer: number;
  pageSizeCustomer: number;
  lengthCustomer: number;
  usersCustomerMatTable = new MatTableDataSource<User>();

  selectedColor1 = '#F46250';
  selectedColor2 = 'accent';
  selectedColor3 = 'accent';
  selectedSize1 = 'medium';
  selectedSize2 = 'small';
  selectedSize3 = 'small';

  constructor(private facadeService: FacadeService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.pageIndexConsultant = 0;
    this.pageSizeConsultant = 5;
    this.getAllUsersConsultant();

    this.pageIndexWorker = 0;
    this.pageSizeWorker = 5;
    this.getAllUsersWorker();

    this.pageIndexCustomer = 0;
    this.pageSizeCustomer = 5;
    this.getAllUsersCustomer();
  }

  public getServerDataConsultant(event?: PageEvent) {
    this.facadeService.getPageUsersConsultants(event.pageIndex, event.pageSize).subscribe(
      (data: UserPage) => {
        this.usersConsultantMatTable.data = data.content;
        this.pageIndexConsultant = event.pageIndex;
        this.pageSizeConsultant = event.pageSize;
      }
    );
    return event;
  }

  public getServerDataWorker(event?: PageEvent) {
    this.facadeService.getPageUsersWorkers(event.pageIndex, event.pageSize).subscribe(
      (data: UserPage) => {
        this.usersWorkerMatTable.data = data.content;
        this.pageIndexWorker = event.pageIndex;
        this.pageSizeWorker = event.pageSize;
      }
    );
    return event;
  }

  public getServerDataCustomer(event?: PageEvent) {
    this.facadeService.getPageUsersCustomers(event.pageIndex, event.pageSize).subscribe(
      (data: UserPage) => {
        this.usersCustomerMatTable.data = data.content;
        this.pageIndexCustomer = event.pageIndex;
        this.pageSizeCustomer = event.pageSize;
      }
    );
    return event;
  }

  private getAllUsersConsultant() {
    const data: UserPage = this.route.snapshot.data['consultants'];
    this.usersConsultantMatTable = new MatTableDataSource<User>(data.content);
    this.usersConsultantMatTable.paginator = this.paginatorConsultant;
    this.lengthConsultant = data.totalElements;
  }

  private getAllUsersWorker() {
    const data: UserPage = this.route.snapshot.data['workers'];
    this.usersWorkerMatTable = new MatTableDataSource<User>(data.content);
    this.usersWorkerMatTable.paginator = this.paginatorWorker;
    this.lengthWorker = data.totalElements;
  }

  private getAllUsersCustomer() {
    const data: UserPage = this.route.snapshot.data['customers'];
    this.usersCustomerMatTable = new MatTableDataSource<User>(data.content);
    this.usersCustomerMatTable.paginator = this.paginatorCustomer;
    this.lengthCustomer = data.totalElements;
  }
}
