import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PrestationCode, PrestationCodeType, SSCodeType, SSEncodingType, Priority } from 'src/app/shared/model/prestation-code/prestation-code.model';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';
import { JointCommission } from 'src/app/shared/model/joint-commission/joint-commission.model';

@Component({
  selector: 'app-prestation-code-dialog',
  templateUrl: './prestation-code-dialog.component.html',
  styleUrls: ['./prestation-code-dialog.component.css']
})
export class PrestationCodeDialogComponent implements OnInit {

  prestationCode: PrestationCode;
  form: FormGroup;
  ssCodeTypes = Object.keys(SSCodeType);
  ssEncodingTypes = Object.keys(SSEncodingType);
  priorities = Object.keys(Priority);
  allJointCommissionCodes = [];
  selectedJointCommissionCodes = [];


  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PrestationCodeDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {
    this.prestationCode = this.data.element;
    this.getAllJointCommissions();
    this.getSelectedJointCommissions();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [this.prestationCode.id ? this.prestationCode.id : null, []],
      active: [(this.prestationCode.active !== null && this.prestationCode.active !== undefined) ? this.prestationCode.active : true, [Validators.required]],
      billing: [(this.prestationCode.billing !== null && this.prestationCode.billing !== undefined) ? this.prestationCode.billing : true, []],
      billingPercent: [(this.prestationCode.billingPercent !== null && this.prestationCode.billingPercent !== undefined) ? this.prestationCode.billingPercent : null, []],
      code: [(this.prestationCode.code !== null && this.prestationCode.code !== undefined) ? this.prestationCode.code : "", []],
      daily: [(this.prestationCode.daily !== null && this.prestationCode.daily !== undefined) ? this.prestationCode.daily : true, []],
      hourly: [(this.prestationCode.hourly !== null && this.prestationCode.hourly !== undefined) ? this.prestationCode.hourly : true, []],
      jointCommissions: [this.selectedJointCommissionCodes, []],
      labelEN: [this.prestationCode.labelEN ? this.prestationCode.labelEN : "", []],
      labelFR: [this.prestationCode.labelFR ? this.prestationCode.labelFR : "", []],
      labelNL: [this.prestationCode.labelNL ? this.prestationCode.labelNL : "", []],
      prestation: [(this.prestationCode.prestation !== null && this.prestationCode.prestation !== undefined) ? this.prestationCode.prestation : true, []],
      prestationCodeType: [this.prestationCode.prestationCodeType ? this.prestationCode.prestationCodeType : PrestationCodeType.MANUAL, []],
      prestationPercent: [(this.prestationCode.prestationPercent !== null && this.prestationCode.prestationPercent !== undefined) ? this.prestationCode.prestationPercent : null, []],
      ssCode: [(this.prestationCode.ssCode !== null && this.prestationCode.ssCode !== undefined) ? this.prestationCode.ssCode : "", []],
      ssCodeType: [this.prestationCode.ssCodeType ? this.prestationCode.ssCodeType : null, []],
      ssEncodingType: [this.prestationCode.ssEncodingType ? this.prestationCode.ssEncodingType : null, []],
      priority: [this.prestationCode.priority ? this.prestationCode.priority : null, []],
      }
    );
  }

  onSubmit() {
    Object.assign(this.prestationCode, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.prestationCode.jointCommissions = '';
    this.form.value.jointCommissions?.forEach(
      element => {
        this.prestationCode.jointCommissions += element;
      }
    );

    this.facade.savePrestationCode(this.prestationCode)
      .subscribe({
        next: prestationCode => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: prestationCode.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  getAllJointCommissions() {
    this.facade.getAllJointCommissions().subscribe({
      next: response => {
        this.setJointCommissionCodes(response);
      }
    });
  }

  setJointCommissionCodes(jointCommissions: JointCommission[]) {
    jointCommissions.forEach(
      element => {
        this.allJointCommissionCodes.push('[' + element.code + ']');
      }
    );
    this.allJointCommissionCodes.sort((a,b) => a.localeCompare(b))
  }

  getSelectedJointCommissions() {
    this.selectedJointCommissionCodes = this.prestationCode.jointCommissions?.split(/(?<=])/);
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

}
