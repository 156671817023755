import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FacadeService} from '../../service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';
import {saveAs} from 'file-saver';

import {MatListOption, MatSelectionList} from '@angular/material/list';
import {Observable} from 'rxjs';

import {ExportInvoicesExactOnlineComponent } from 'src/app/shared/component/export-dialog/export-invoices-exact-online/export-invoices-exact-online.component';
import { SnackbarComponent } from '../snackbar/snackbar.component';

export class ExportDialogParam {
  contractsExport = false;
  dimonasExport = false;

  // Generic export boolean is used for multiple exports (customer, worker, exportation tab exports) so added another specific condition (workerExport, etc.) to adapt the export title//
  genericExport = false;
  customerExport = false;
  workerExport = false;
  successiveContractsExport = false;
  activityReportExport = false;
  dutyReport = false;
  invoicesExport = false;
  invoicesExportExactOnline = false;
  prestationsExport = false;
  prestationsExportNew = false;
  prestationsCodesExport = false;
  
  showOperatingUnit = true;

  constructor(private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  saveFile(result: Observable<any>) {
    result.subscribe({
      next: res => {
        const file = new Blob([res.body], {type: 'text/csv'});
        saveAs(file, res.headers.get('Content-Disposition').split('filename=')[1].replaceAll('\"', ''));
        this.openSnackBar(this.translate.instant('exports.message.downloadingSuccessfull'));
      },
      error: error => {
        if (error.error instanceof Blob && error.error.type === 'application/json') {
          this.openSnackBar(this.translate.instant('exports.message.error.requestSize'), 'customSnackError');
        } else {
          this.openSnackBar(this.translate.instant('exports.message.error.export') + ' : ' + error.message, 'customSnackError');
        }
      }
    });
  }

  public openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }
}

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.css']
})
export class ExportDialogComponent implements OnInit {

  exportForm: UntypedFormGroup;
  operatingUnits: OperatingUnit[] = [];
  inactiveOperatingUnits: OperatingUnit[] = [];
  tab: number[];
  allOperating = false;
  parameters: ExportDialogParam;
  @ViewChild('operatingUnitIds') private operatingUnitIds: MatSelectionList;
  @ViewChild('inactiveOperatingUnitIds') private inactiveOperatingUnitIds: MatSelectionList;
  @ViewChild(ExportInvoicesExactOnlineComponent) invoicesExportExactOnlineComponent: ExportInvoicesExactOnlineComponent;

  constructor(private dialogRef: MatDialogRef<ExportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ExportDialogParam,
              private facadeService: FacadeService,
              private translate: TranslateService,
              private snackBar: MatSnackBar) {
    this.parameters = data;
    facadeService.getAllOperatingUnits().subscribe(
      (next: OperatingUnit[]) => {
        next.forEach(
          operatingUnit => {
            if (!operatingUnit.active) {
              operatingUnit.name = '[' + this.translate.instant('exports.exportDialog.inactive') + '] ' + operatingUnit.name;
              this.inactiveOperatingUnits.push(operatingUnit);
            } else {
              this.operatingUnits.push(operatingUnit);
            }
          }
        );
      });
    this.operatingUnits.sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnInit(): void {
    this.formBuilderValidations();
    this.tab = this.exportForm.controls['operatingUnitIds'].value;
  }

  openCustomSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: [pC],
      data: {
        message: message
      }
    });
  }

  export() {
    if (this.data.showOperatingUnit) {

      // Check if the dates interval given in the invoicesExportExactOnline form is correct
      if (this.data.invoicesExportExactOnline && this.invoicesExportExactOnlineComponent.form.invalid) {
        return ; 
      }

      let currentTab: number[] = this.exportForm.controls['operatingUnitIds'].value;
      currentTab = currentTab.filter(ou => ou !== 0);
      this.exportForm.controls['inactiveOperatingUnitIds'].value.forEach(ou => currentTab.push(ou));
      if (!(currentTab.length > 0) || !currentTab) {
        return this.parameters.openSnackBar(this.translate.instant('exports.message.error.agency'), 'customSnackError');
      }
      this.exportForm.controls['operatingUnitIds'].setValue(currentTab);
      this.exportForm.controls['inactiveOperatingUnitIds'].setValue(null);
    }
      this.openCustomSnackBar(this.translate.instant('exports.message.downloading'));
      this.dialogRef.close(this.exportForm.value);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectionChange(optionList: MatListOption[]) {
    const option = optionList[0];
    if (option.value === 0 && option.selected === true) {
      this.operatingUnitIds.selectAll();
      this.allOperating = true;
    }
    if (option.value === 0 && option.selected === false) {
      this.operatingUnitIds.deselectAll();
      this.allOperating = false;
    }
    if (option.value !== 0 && option.selected === false && this.allOperating) {
      this.operatingUnitIds.selectedOptions.deselect(this.operatingUnitIds.selectedOptions.selected.find(mto => mto.value === 0));
      this.allOperating = false;
    }
  }

  private formBuilderValidations() {
    this.exportForm = new UntypedFormGroup({
      operatingUnitIds: new UntypedFormControl([]),
      inactiveOperatingUnitIds: new UntypedFormControl([])
    });
  }
}
