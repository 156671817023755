import {Serializer} from '../../serializer';
import {PrestationSerializer} from '../../../../prestations/shared/model/prestation.serializer';
import {PrestationPage} from './prestation-page.model';

export class PrestationPageSerializer implements Serializer {

  private prestationSerializer = new PrestationSerializer();

  fromJson(json: any): PrestationPage {
    const prestationPage = new PrestationPage();

    json.content.forEach(
      content => {
        prestationPage.content.push(this.prestationSerializer.fromJson(content));
      }
    );
    prestationPage.totalElements = json.totalElements;
    prestationPage.totalPages = json.totalPages;
    return prestationPage;
  }

  toJson(resource: Object): any {
  }
}

