import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Document} from '../../model/document/document.model';

@Injectable()
export class WorkerPendingDocumentResolver implements Resolve<Observable<Document>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Document> {
    const workerId = route.queryParams['w'];
    return this.facadeService.getDocumentsByWorkerId(+workerId, true).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
