import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Dimona, DimonaErrorAction, DimonaPeriod, DimonaPeriodStatus} from '../../../../../shared/model/dimona/dimona.model';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../../../../../shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {DimonaCodeNotis} from '../../../../../shared/model/dimona-code-notis/dimona-code-notis.model';
import {Request} from '../../../model/request.model';
import {ErrorDetailDialogComponent} from '../error-detail-dialog/error-detail-dialog.component';

@Component({
  selector: 'app-request-error-detail-content',
  templateUrl: './request-error-detail-content.component.html',
  styleUrls: ['./request-error-detail-content.component.css']
})
export class RequestErrorDetailContentComponent implements OnInit {

  displayedColumns: string[] = ['statusColor', 'id', 'contractId', 'startDate', 'endDate', 'logoStatus', 'errorCode', 'errorLabel'];
  dataSource = new MatTableDataSource<DimonaPeriod>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  dimonaPeriods: DimonaPeriod[] = [];
  @Input() requestId: number;
  @Input() editButton: boolean;

  constructor(private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private translate: TranslateService) {
  }

  get DimonaPeriodStatus() {
    return DimonaPeriodStatus;
  }

  ngOnInit() {
    if (this.editButton === true) {
      this.displayedColumns.push('fixButton');
    }
    this.facadeService.getDimonaByRequestId(this.requestId)
      .subscribe(
        dimonas => {
          dimonas.forEach(
            (dimona: Dimona) => {
              dimona.dimonaPeriods.forEach(
                (dimonaPeriod: DimonaPeriod) => {
                  if (dimonaPeriod.errorCodes) {
                    dimonaPeriod.idContractDimona = dimona.contractId;
                    dimonaPeriod.idDimona = dimona.id;
                    this.facadeService.getDimonaCodeNotisByCode(dimonaPeriod.errorCodes).subscribe(
                      (data: DimonaCodeNotis) => {
                        switch (this.translate.currentLang.toLowerCase()) {
                          case 'nl': {
                            dimonaPeriod.label = data.labelNL;
                            break;
                          }
                          default : {
                            dimonaPeriod.label = data.labelFR;
                          }
                        }
                      },
                    );
                  }
                  this.dimonaPeriods.push(dimonaPeriod);
                }
              );
            }
          );
          this.dataSource = new MatTableDataSource<DimonaPeriod>(this.dimonaPeriods);
        },
      );
  }


  openDialog(dimonaPeriod: DimonaPeriod): void {
    switch (dimonaPeriod.errorCodes) {
      case '90017-363':
      case '00024-003': {
        this.facadeService.getRequestById(this.requestId).subscribe(
          (request: Request) => {
            const params = {
              w: request.workerId,
              d: dimonaPeriod.id,
              action: DimonaErrorAction.INVALID_NISS
            };
            this.router.navigate(['/worker-edit'], {queryParams: params}).then();
          });
        break;
      }
      default: {
        const dialogRef = this.dialog.open(ErrorDetailDialogComponent, {
          data: {
            dimonaPeriod: dimonaPeriod,
            request: this.route.snapshot.data['request']
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['requests']).then();
          }
        });
      }
    }
  }

}
