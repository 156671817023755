import { PrestationCodeSerializer } from '../../prestation-code/prestation-code.serializer';
import { Serializer } from '../../serializer';
import { PrestationCodePage } from './prestation-code-page.model';

export class PrestationCodePageSerializer implements Serializer {

  private prestationCodeSerializer = new PrestationCodeSerializer();

  fromJson(json: any): PrestationCodePage {
    const prestationCodePage = new PrestationCodePage();

    json.content.forEach(
      content => {
        prestationCodePage.content.push(this.prestationCodeSerializer.fromJson(content));
      }
    );

    prestationCodePage.totalElements = json.totalElements;
    prestationCodePage.totalPages = json.totalPages;
    return prestationCodePage;
  }

  toJson(resource: Object): any {
  }
}

