import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Customer} from '../../customers/shared/model/customer.model';
import {map} from 'rxjs/operators';
import {StatusSerializer} from '../model/status.serializer';
import {Status} from '../model/status.model';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  private statusServiceURL = environment.status_service_URL;
  private statusSerializer: StatusSerializer = new StatusSerializer();

  constructor(private http: HttpClient) {
  }

  list(): Observable<any> {
    return this.http.get<Status[]>(this.statusServiceURL + 'statuses').pipe(
      map((data: Status[]) => this.convertData(data).sort((a, b) => b.name.localeCompare(a.name)))
    );
  }

  manualCheck(): Observable<any> {
    return this.http.post<Status[]>(this.statusServiceURL + 'statuses/manual-check', {});
  }

  private convertData(data: any): Customer[] {
    return data.map(customer => this.statusSerializer.fromJson(customer));
  }
}
