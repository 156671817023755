import {Serializer} from 'src/app/shared/model/serializer';
import {WorkerPosition} from './worker-position.model';

export class WorkerPositionSerializer implements Serializer {

  fromJson(json: any): WorkerPosition {
    const workerPosition: WorkerPosition = new WorkerPosition();

    workerPosition.id = json.id;
    workerPosition.active = json.active;
    workerPosition.nameEN = json.nameEN;
    workerPosition.nameFR = json.nameFR;
    workerPosition.nameNL = json.nameNL;

    return workerPosition;
  }

  toJson(workerPosition: WorkerPosition): any {
    return {
      id: workerPosition.id,
      active: workerPosition.active,
      nameEN: workerPosition.nameEN,
      nameFR: workerPosition.nameFR,
      nameNL: workerPosition.nameNL
    };
  }
}
