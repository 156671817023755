import {Serializer} from '../serializer';
import {CompanyPrefix} from './company-prefix.model';

export class CompanyPrefixSerializer implements Serializer {
  fromJson(json: any): CompanyPrefix {
    const companyPrefix: CompanyPrefix = new CompanyPrefix();

    companyPrefix.id = json.id;
    companyPrefix.active = json.active;
    companyPrefix.code = json.code;
    companyPrefix.nameDE = json.nameDE;
    companyPrefix.nameEN = json.nameEN;
    companyPrefix.nameFR = json.nameFR;
    companyPrefix.nameNL = json.nameNL;

    return companyPrefix;
  }

  toJson(companyPrefix: CompanyPrefix): any {
    return {
      id: companyPrefix.id,
      active: companyPrefix.active,
      code: companyPrefix.code,
      nameDE: companyPrefix.nameDE,
      nameEN: companyPrefix.nameEN,
      nameFR: companyPrefix.nameFR,
      nameNL: companyPrefix.nameNL
    };
  }
}
