import {Request} from '../../../../requests/shared/model/request.model';
import {NonAvailability} from '../../../../workers/shared/model/nonAvailability.model';

export class WorkerPlanningDto {
  id: number;
  name: string;
  totalHours: number;
  totalMinutes: number;
  requests: Request[] = [];
}

export class CostCenterPlanningDto {
  costCenter: string;
  workers: WorkerPlanningDto[] = [];
}

export class CustomerPlanningDto {
  id: number;
  name: string;
  costCenters: CostCenterPlanningDto[] = [];
}

export class WorkerOccupancyDto {
  requestId: number;
  startDate: Date;
  endDate: Date;
}

export class PlanningDto {
  count: number;
  customers: CustomerPlanningDto[] = [];
  nonAvailabilities: Map<number, NonAvailability[]> = new Map<number, NonAvailability[]>();
  workerOccupancies: Map<number, WorkerOccupancyDto[]> = new Map<number, WorkerOccupancyDto[]>();
}
