import {Serializer} from 'src/app/shared/model/serializer';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';
import {Customer} from 'src/app/customers/shared/model/customer.model';
import {Request} from 'src/app/requests/shared/model/request.model';
import {Worker} from 'src/app/workers/shared/model/worker.model';
import {RequestSerializer} from 'src/app/requests/shared/model/request.serializer';
import {WorkerSerializer} from 'src/app/workers/shared/model/woker.serializer';
import {OperatingUnitSerializer} from 'src/app/operating-units/shared/model/operating-unit.serializer';
import {CustomerSerializer} from 'src/app/customers/shared/model/customer.serializer';
import {Contract, ContractPeriod} from './contract.model';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {AddressSerializer} from '../../../shared/model/address/address.serializer';
import {Address} from '../../../shared/model/address/address.model';

export class ContractSerializer implements Serializer {
  private requestSerializer: RequestSerializer = new RequestSerializer();
  private workerSerializer: WorkerSerializer = new WorkerSerializer();
  private operatingUnitSerializer: OperatingUnitSerializer = new OperatingUnitSerializer();
  private customerSerializer: CustomerSerializer = new CustomerSerializer();
  private dateSerializer: DateSerializer = new DateSerializer();
  private addressSerializer: AddressSerializer = new AddressSerializer();

  fromJson(json: any): Contract {
    const contract: Contract = new Contract();
    let request: Request = new Request();
    let operatingUnit: OperatingUnit = new OperatingUnit();
    let customer: Customer = new Customer();
    let worker: Worker = new Worker();
    let workPlaceAddress: Address = new Address();
    const contractPeriods: ContractPeriod[] = [];

    contract.id = json.id;
    contract.startDate = this.dateSerializer.fromJson(json.startDate);
    contract.endDate = this.dateSerializer.fromJson(json.endDate);
    contract.contractType = json.contractType;
    contract.workerType = json.workerType;
    contract.workFunctionId = json.workFunctionId;
    contract.jointCommissionCode = json.jointCommissionCode;
    contract.costCenter = json.costCenter;
    contract.billingCoefficient = json.billingCoefficient;
    contract.salaryScaleValue = json.salaryScaleValue;
    contract.referenceSalaryScaleValue = json.referenceSalaryScaleValue;
    contract.travelExpenses = json.travelExpenses;
    contract.travelCoefficient = json.travelCoefficient;
    contract.status = json.status;
    contract.dimonaStatus = json.dimonaStatus;
    contract.contractReason = json.active;
    contract.active = json.active;
    contract.fullTime = json.fullTime;
    contract.qS = json.qs;
    contract.contractNumber = json.contractNumber;
    contract.workPlaceName = json.workPlaceName;

    const jsonRequest = json.request;
    if (jsonRequest) {
      request = this.requestSerializer.fromJson(jsonRequest);
    }
    contract.request = request;

    const jsonOperatingUnit = json.operatingUnit;
    if (jsonOperatingUnit) {
      operatingUnit = this.operatingUnitSerializer.fromJson(jsonOperatingUnit);
    }
    contract.operatingUnit = operatingUnit;

    const jsonWorker = json.worker;
    if (jsonWorker) {
      worker = this.workerSerializer.fromJson(jsonWorker);
    }
    contract.worker = worker;

    const jsonCustomer = json.customer;
    if (jsonCustomer) {
      customer = this.customerSerializer.fromJson(jsonCustomer);
    }
    contract.customer = customer;

    const contractPeriodsJson = json.contractPeriods;
    if (contractPeriodsJson) {
      contractPeriodsJson.forEach(
        contractPeriodJson => {
          contractPeriods.push(this.contractPeriodFromJson(contractPeriodJson));
        }
      );
    }
    contract.contractPeriods = contractPeriods;

    const jsonWorkPlaceAddress = json.workPlaceAddress;
    if (jsonWorkPlaceAddress) {
      workPlaceAddress = this.addressSerializer.fromJson(jsonWorkPlaceAddress);
    }
    contract.workPlaceAddress = workPlaceAddress;

    return contract;
  }

  toJson(contract: Contract) {
    const contractPeriods: any[] = [];
    contract.contractPeriods.forEach(
      contractPeriod => {
        contractPeriods.push(this.contractPeriodToJson(contractPeriod));
      }
    );

    return {
      id: contract.id,
      startDate: this.dateSerializer.dateWithTimeToJson(contract.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(contract.endDate),
      contractType: contract.contractType,
      workerType: contract.workerType,
      workFunctionId: contract.workFunctionId,
      jointCommissionCode: contract.jointCommissionCode,
      costCenter: contract.costCenter,
      billingCoefficient: contract.billingCoefficient,
      salaryScaleValue: contract.salaryScaleValue,
      referenceSalaryScaleValue: contract.referenceSalaryScaleValue,
      travelExpenses: contract.travelExpenses,
      travelCoefficient: contract.travelCoefficient,
      request: this.requestSerializer.toJson(contract.request),
      operatingUnit: this.operatingUnitSerializer.toJson(contract.operatingUnit),
      worker: this.workerSerializer.toJson(contract.worker),
      customer: this.customerSerializer.toJson(contract.customer),
      status: contract.status,
      dimonaStatus: contract.dimonaStatus,
      contractReason: contract.active,
      contractPeriods: contractPeriods,
      active: contract.active,
      contractNumber: contract.contractNumber,
      workPlaceName: contract.workPlaceName,
      workPlaceAddress: this.addressSerializer.toJson(contract.workPlaceAddress)
    };
  }

  private contractPeriodFromJson(contractPeriodJson: any): ContractPeriod {
    const contractPeriod: ContractPeriod = new ContractPeriod();

    contractPeriod.id = contractPeriodJson.id;
    contractPeriod.startDate = this.dateSerializer.fromJson(contractPeriodJson.startDate);
    contractPeriod.endDate = this.dateSerializer.fromJson(contractPeriodJson.endDate);
    contractPeriod.description = contractPeriodJson.description;
    contractPeriod.active = contractPeriodJson.active;
    contractPeriod.breakDuration = this.dateSerializer.hoursFromJson(contractPeriodJson.pauseTime);

    return contractPeriod;
  }

  private contractPeriodToJson(contractPeriod: ContractPeriod) {
    return {
      id: contractPeriod.id,
      startDate: this.dateSerializer.dateWithTimeToJson(contractPeriod.startDate),
      endDate: this.dateSerializer.dateWithTimeToJson(contractPeriod.endDate),
      pauseTime: this.dateSerializer.hoursToJson(contractPeriod.breakDuration),
      active: contractPeriod.active,
      description: contractPeriod.description
    };
  }
}
