import {Contract, ContractPeriod} from '../contract.model';
import {ContractWeekPeriodDay} from './contract-week-period-day.dto';

export class ContractWeekPeriod {
  days: ContractWeekPeriodDay[] = [];
  totalHours: number;
  totalMinutes: number;
  private _total_time = 0;

  constructor(contract: Contract) {
    this.createDaysGroups(contract.startDate, contract.contractPeriods);
    this.computeTotal();
  }

  private static firstDayOfWeek(date: Date) {
    date = new Date(date);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  private createDaysGroups(startDate: Date, periods: ContractPeriod[]) {
    const firstDayOfWeek = ContractWeekPeriod.firstDayOfWeek(startDate);
    firstDayOfWeek.setHours(0);
    firstDayOfWeek.setMinutes(0);
    firstDayOfWeek.setSeconds(0);
    for (let i = 0; i < 7; i++) {
      const periodsByDate = periods.filter(
        p =>
          new Date(p.startDate.getFullYear(), p.startDate.getMonth(), p.startDate.getDate(), 0, 0, 0).getTime()
          === firstDayOfWeek.getTime()
      );
      const contractWeekPeriodDay = new ContractWeekPeriodDay(firstDayOfWeek, periodsByDate);
      this.days.push(contractWeekPeriodDay);
      firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 1);

      /*
      *   Compute totalMinutes and totalHours
      *   Convert hours to minutes
      * */
      this._total_time += contractWeekPeriodDay.totalMinutes;
      this._total_time += contractWeekPeriodDay.totalHours * 60;
    }
  }

  private computeTotal() {
    this.totalHours = Math.floor(this._total_time / 60);
    this.totalMinutes = Math.floor(this._total_time - this.totalHours * 60);
  }
}
