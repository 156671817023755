import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {InvoiceSerializer} from '../model/invoice.serializer';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Invoice} from '../model/invoice.model';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {TranslateService} from '@ngx-translate/core';
import {InvoicePageSerializer} from '../../../shared/model/pagination/billing/invoice-page.serializer';
import {InvoicePage} from '../../../shared/model/pagination/billing/invoice-page.model';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private serviceURLAccounting = environment.bill_service_URL + 'accounting/';
  private serviceURLInvoices = environment.bill_service_URL + 'invoices/';
  private invoiceSerializer: InvoiceSerializer = new InvoiceSerializer();
  private invoicePageSerializer: InvoicePageSerializer = new InvoicePageSerializer();

  private dateSerializer: DateSerializer = new DateSerializer();

  constructor(private http: HttpClient, private translate: TranslateService) {
  }

  get(id: number): Observable<any> {
    return this.http.get<Invoice>(this.serviceURLInvoices + id + '/printable').pipe(
      map((data) => this.invoiceSerializer.fromJson(data))
    );
  }

  getOriginalFile(id: number): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    let params = new HttpParams();
    params = params.append('language', this.translate.currentLang);
    return this.http.get(this.serviceURLInvoices + id + '/download-pdf', {headers: headers, params: params, responseType: 'blob'});
  }

  getPage(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<InvoicePage>(this.serviceURLInvoices + 'page', {params}).pipe(
      map((data: InvoicePage) => this.invoicePageSerializer.fromJson(data))
    );
  }

  export(
    prestationStartDateFrom: Date,
    prestationStartDateTo: Date,
    prestationEndDateFrom: Date,
    prestationEndDateTo: Date,
    invoiceCreationFrom: Date,
    invoiceCreationTo: Date,
    invoiceDueDateFrom: Date,
    invoiceDueDateTo: Date,
    operatingUnitIds: Array<number>,
    customerId: number
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        operatingUnitIds: String(operatingUnitIds),
        ...customerId ? {customerId: customerId} : {},
        ...prestationStartDateFrom ? {prestationStartDateFrom: this.dateSerializer.toJson(prestationStartDateFrom)} : {},
        ...prestationStartDateTo ? {prestationStartDateTo: this.dateSerializer.toJson(prestationStartDateTo)} : {},
        ...prestationEndDateFrom ? {prestationEndDateFrom: this.dateSerializer.toJson(prestationEndDateFrom)} : {},
        ...prestationEndDateTo ? {prestationEndDateTo: this.dateSerializer.toJson(prestationEndDateTo)} : {},
        ...invoiceCreationFrom ? {invoiceCreationFrom: this.dateSerializer.toJson(invoiceCreationFrom)} : {},
        ...invoiceCreationTo ? {invoiceCreationTo: this.dateSerializer.toJson(invoiceCreationTo)} : {},
        ...invoiceDueDateFrom ? {invoiceDueDateFrom: this.dateSerializer.toJson(invoiceDueDateFrom)} : {},
        ...invoiceDueDateTo ? {invoiceDueDateTo: this.dateSerializer.toJson(invoiceDueDateTo)} : {},
      }
    });

    return this.http.get(
      this.serviceURLInvoices + 'export',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  exportExactOnline(
    invoiceCreationFrom: Date,
    invoiceCreationTo: Date,
    operatingUnitIds: Array<number>,
    customerId: number
  ): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        operatingUnitIds: String(operatingUnitIds),
        ...customerId ? {customerId: customerId} : {},
        ...invoiceCreationFrom ? {invoiceCreationFrom: this.dateSerializer.toJson(invoiceCreationFrom)} : {},
        ...invoiceCreationTo ? {invoiceCreationTo: this.dateSerializer.toJson(invoiceCreationTo)} : {},
      }
    });

    return this.http.get(
      this.serviceURLAccounting + 'export-accounting',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  generateWeeklyInvoices(): Observable<any> {
    return this.http.post(this.serviceURLInvoices + 'generate/weekly/bills', null);
  }

  generateMonthlyInvoices(): Observable<any> {
    return this.http.post(this.serviceURLInvoices + 'generate/monthly/bills', null);
  }
  
}
