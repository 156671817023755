import {Component, Input, OnInit} from '@angular/core';
import {HttpStateService} from './service/http-state.service';
import {IHttpState} from './enum/i-http-state.enum';
import {HttpProgressState} from './enum/http-progress-state.enum';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  public loading = false;
  @Input() public filterBy: string | null = null;

  constructor(private httpStateService: HttpStateService) {
  }

  /**
   * receives all HTTP requests and filters them by the filterBy
   * values provided
   */
  ngOnInit() {
    this.httpStateService.state.subscribe((progress: IHttpState) => {
      if (progress && progress.url) {
        if (!this.filterBy) {
          this.loading = (progress.state === HttpProgressState.start);
        } else if (progress.url.indexOf(this.filterBy) !== -1) {
          this.loading = (progress.state === HttpProgressState.start);
        }
      }
    });
  }
}
