import {Component, OnInit} from '@angular/core';
import {User} from '../shared/model/user.model';
import {ActivatedRoute} from '@angular/router';
import {OperatingUnit} from '../../operating-units/shared/model/operating-unit.model';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.css']
})
export class UserNewComponent implements OnInit {
  user: User = new User();
  operatingUnits: OperatingUnit[] = [];

  constructor(private route: ActivatedRoute) {
    this.getAllOperatingUnits();
  }

  ngOnInit() {
  }

  private getAllOperatingUnits() {
    const operatingUnits = this.route.snapshot.data['operatingUnits'];
    Object.assign(this.operatingUnits, operatingUnits);
  }

}
