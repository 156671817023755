import {Component, OnInit, ViewChild} from '@angular/core';
import {Worker} from './shared/model/worker.model';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from '../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ExportDialogComponent, ExportDialogParam} from '../shared/component/export-dialog/export-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WorkerPage} from '../shared/model/pagination/worker/worker-page.model';
import {checkIfValidSearchTerm} from '../shared/model/pagination/pagination.model';

@Component({
  selector: 'app-workers',
  templateUrl: './workers.component.html',
  styleUrls: ['./workers.component.css']
})
export class WorkersComponent implements OnInit {
  displayedColumns: string[] = ['statusColor', 'workerNumber', 'lastName', 'firstName', 'document', 'status'];
  workersTable = new MatTableDataSource<Worker>();
  @ViewChild('workersPaginator', {static: true}) paginator: MatPaginator;
  pageEvent: PageEvent;
  pageIndex: number;
  previousIndex = 0;
  pageSize: number;
  query: string;
  length: number;
  wasInFilter = false;
  searchForm: UntypedFormGroup;

  constructor(private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder,
              private dialog: MatDialog,
              private facadeService: FacadeService,
              private router: Router,
              private translate: TranslateService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.pageIndex = this.route.snapshot.queryParams['pageIndex'] ?? 0;
    this.pageSize = this.route.snapshot.queryParams['pageSize'] ?? 20;

    const workersFromRoute: WorkerPage = this.route.snapshot.data['workers'];
    this.query = this.route.snapshot.queryParams['search'];

    this.length = workersFromRoute.totalElements;
    this.workersTable = new MatTableDataSource<Worker>(workersFromRoute.content);
    this.workersTable.paginator = this.paginator;
    this.wasInFilter = checkIfValidSearchTerm(this.query);

    this.searchForm = this.formBuilder.group({
      query: [this.query, []],
    });

    this.searchForm.get('query').valueChanges.subscribe(value => {
      this.searchWorker(value);
    });
  }

  handleChangePagination(event?: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getEventWorkerData();
    return event;
  }

  getEventWorkerData() {
    this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: {pageIndex: this.pageIndex, pageSize: this.pageSize, search: this.query}
      }
    ).then();
    this.facadeService.getPageWorkers(this.pageIndex, this.pageSize, {query: this.query}).subscribe(
      (res: WorkerPage) => {
        this.workersTable.data = res.content;
        this.length = res.totalElements;
      }
    );
  }

  searchWorker(value: string) {
    if (checkIfValidSearchTerm(value)) {
      this.query = value;
      if (!this.wasInFilter) {
        this.previousIndex = this.pageIndex;
        this.pageIndex = 0;
      }
      this.wasInFilter = true;
    } else {
      this.query = null;
      if (!this.wasInFilter) {
        return;
      }
      this.pageIndex = this.previousIndex;
      this.wasInFilter = false;
    }
    this.getEventWorkerData();
  }

  export() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.genericExport = true;
    param.workerExport = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getWorkerToExport(
        result.contractStartDate,
        result.contractEndDate,
        result.operatingUnitIds)));
  }
}

