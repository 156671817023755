import { BillingCoefficientSerializer } from './../../../../customers/shared/model/billing-coefficient.serializer';
import {Serializer} from '../../serializer';
import { BillingCoefficients } from '../../../../customers/shared/model/customer.model';
import { BillingCoefficientsPage } from './billing-coefficient-page.model';

export class BillingCoefficientSerializerPage implements Serializer {

  private billingCoefficientSerializer = new BillingCoefficientSerializer();

  fromJson(json: any): BillingCoefficientsPage {
    const billingCoefficientsPage = new BillingCoefficientsPage;

    json.content.forEach(
      content => {
        billingCoefficientsPage.content.push(this.billingCoefficientSerializer.fromJson(content));
      }
    );

    billingCoefficientsPage.totalElements = json.totalElements;
    billingCoefficientsPage.totalPages = json.totalPages;

    return billingCoefficientsPage;
  }

  toJson(resource: Object): any {
  }
}
