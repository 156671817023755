import { TravelExpenseCoefficient } from './../../../../admin/model/travel-expense-coefficient.model';
import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';

@Component({
  selector: 'app-travel-expense-coefficient-dialog',
  templateUrl: './travel-expense-coefficient-dialog.component.html',
  styleUrls: ['./travel-expense-coefficient-dialog.component.css']
})
export class TravelExpenseCoefficientDialogComponent implements OnInit {

  travelExpenseCoefficient: TravelExpenseCoefficient;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TravelExpenseCoefficientDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {
    this.travelExpenseCoefficient = this.data.element;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [(this.travelExpenseCoefficient.id !== null && this.travelExpenseCoefficient.id !== undefined) ? this.travelExpenseCoefficient.id : null, []],
      active: [(this.travelExpenseCoefficient.active !== null && this.travelExpenseCoefficient.active !== undefined) ? this.travelExpenseCoefficient.active : true, [Validators.required]],
      distanceKM: [(this.travelExpenseCoefficient.distanceKM !== null && this.travelExpenseCoefficient.distanceKM !== undefined) ? this.travelExpenseCoefficient.distanceKM : 0, []],
      weeklyA5: [(this.travelExpenseCoefficient.weeklyA5 !== null && this.travelExpenseCoefficient.weeklyA5 !== undefined) ? this.travelExpenseCoefficient.weeklyA5 : 0, [Validators.required]],
      weeklyA6: [(this.travelExpenseCoefficient.weeklyA6 !== null && this.travelExpenseCoefficient.weeklyA6 !== undefined) ? this.travelExpenseCoefficient.weeklyA6 : 0, [Validators.required]],
      weeklyA7: [(this.travelExpenseCoefficient.weeklyA7 !== null && this.travelExpenseCoefficient.weeklyA7 !== undefined) ? this.travelExpenseCoefficient.weeklyA7 : 0, [Validators.required]],
      weeklyIndemnities: [(this.travelExpenseCoefficient.weeklyIndemnities !== null && this.travelExpenseCoefficient.weeklyIndemnities !== undefined) ? this.travelExpenseCoefficient.weeklyIndemnities : 0, [Validators.required]],
    }
    );
  }

  onSubmit() {
    Object.assign(this.travelExpenseCoefficient, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.facade.saveTravelExpenseCoefficient(this.travelExpenseCoefficient)
      .subscribe({
        next: travelExpenseCoefficient => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: travelExpenseCoefficient.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

  clearDate(): void {
    this.form.controls.date.setValue('');
  }
}
