import {Component, OnInit} from '@angular/core';
import {ExportDialogComponent, ExportDialogParam} from '../shared/component/export-dialog/export-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FacadeService} from '../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {

  searchForm: UntypedFormGroup;
  query: string;
  activeChecked = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private facadeService: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    public route: ActivatedRoute) {
  }

  ngOnInit() {
    this.query = this.route.snapshot.queryParams['search'];
    this.searchForm = this.formBuilder.group({
      query: [this.query, []],
    });

    this.searchForm.get('query').valueChanges.subscribe(value => {
      this.query = value;
    });
  }

  export() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.contractsExport = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getContractToExport(
        result.contractStartDate,
        result.contractEndDate,
        result.operatingUnitIds,
        result.workerId,
        result.customerId)));
  }


  exportDimona() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.dimonasExport = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => param.saveFile(this.facadeService.getDimonaToExport(
        result.declarationStartDate,
        result.declarationEndDate,
        result.operatingUnitIds)));
  }
}
