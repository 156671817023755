import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FacadeService } from '../../../shared/service/facade/facade.service';
import { MatDialog } from '@angular/material/dialog';
import { SalaryScale } from '../../model/salary-scale.model';
import { SalaryScalePage } from '../../../shared/model/pagination/salary-scale/salary-scale-page.model';
import { SalaryScaleDialogComponent } from '../../../shared/component/admin-dialog/salary-scale-dialog/salary-scale-dialog.component';
import { JointCommission } from 'src/app/shared/model/joint-commission/joint-commission.model';

@Component({
  selector: 'app-salary-scale',
  templateUrl: './salary-scale.component.html',
  styleUrls: ['./salary-scale.component.css', '../../admin.component.css']
})
export class SalaryScaleComponent implements OnInit {
 @ViewChild('SalaryScalePaginator', { static: true }) paginator: MatPaginator;

  salaryScale: SalaryScale;
  jointCommissionCodeList: string[] = [];

  dataSource = new MatTableDataSource<SalaryScale>();
  pageEvent: PageEvent;
  pageIndex: number = 1;
  pageSize: number = 20;
  length: number;

  constructor(
    private facadeService: FacadeService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData();
    this.getAllJointCommissions();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getAllJointCommissions() {
    this.facadeService.getAllJointCommissions().subscribe((list: JointCommission[]) => {
        for (let i = 0; i < list.length; i++) {
          this.jointCommissionCodeList.push(list[i].code);
        }
    });
  }


  displayedColumns: string[] = [
    "id",
    "active",
    "category",
    "jointCommissionCode",
    "seniority",
    "value"
  ];

  public getData(event?: PageEvent) {
    this.pageIndex = event ? event.pageIndex : 0;
    this.pageSize = event ? event.pageSize : 20;
    this.facadeService.getPageSalaryScale(this.pageIndex, this.pageSize).subscribe(
      (data: SalaryScalePage) => {
        this.dataSource.data = data.content;
        this.dataSource.paginator = this.paginator;
        this.length = data.totalElements;
      }
    );
    return event;
  }

  openPopUp(row) {
    const dialogRef = this.dialog.open(SalaryScaleDialogComponent, {
      data: { element: row, jcCodeList: this.jointCommissionCodeList },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getData();
    });
  }

  addElement() {
    this.salaryScale = new SalaryScale;
    this.openPopUp(this.salaryScale);
  }

}
