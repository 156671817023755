import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {PrestationPeriodSerializer} from '../model/prestation-period.serializer';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PrestationPeriod} from '../model/prestation-period.model';

@Injectable({
  providedIn: 'root'
})
export class PrestationPeriodService {
  private serviceURL = environment.prestation_service_URL + 'periods';
  private prestationPeriodSerializer: PrestationPeriodSerializer = new PrestationPeriodSerializer();

  constructor(private http: HttpClient) {
  }

  modify(period: PrestationPeriod): Observable<any> {
    return this.http.post<PrestationPeriod>(this.serviceURL + '/modify', this.prestationPeriodSerializer.toJson(period)).pipe(
      map((data) => this.prestationPeriodSerializer.fromJson(data))
    );
  }

  validate(period: PrestationPeriod): Observable<any> {
    return this.http.post<PrestationPeriod>(this.serviceURL + '/validate', this.prestationPeriodSerializer.toJson(period)).pipe(
      map((data) => this.prestationPeriodSerializer.fromJson(data))
    );
  }

  reject(period: PrestationPeriod, justificiationCode: String): Observable<any> {
    return this.http.post<PrestationPeriod>(
      this.serviceURL + '/reject/with-justification/' + justificiationCode,
      this.prestationPeriodSerializer.toJson(period)
    ).pipe(
      map((data) => this.prestationPeriodSerializer.fromJson(data))
    );
  }

  cancel(period: PrestationPeriod, justificiationCode: String): Observable<any> {
    return this.http.post<PrestationPeriod>(
      this.serviceURL + '/cancel/with-justification/' + justificiationCode,
      this.prestationPeriodSerializer.toJson(period)
    ).pipe(
      map((data) => this.prestationPeriodSerializer.fromJson(data))
    );
  }
}
