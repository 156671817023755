import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { JointCommission } from 'src/app/shared/model/joint-commission/joint-commission.model';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';
import { Sector } from 'src/app/shared/model/sector/sector.model';

@Component({
  selector: 'app-joint-commission-dialog',
  templateUrl: './joint-commission-dialog.component.html',
  styleUrls: ['./joint-commission-dialog.component.css']
})
export class JointCommissionDialogComponent implements OnInit {

  jointCommission: JointCommission;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<JointCommissionDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar
  ) {
    this.jointCommission = this.data.element;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if (!this.jointCommission.sector) {
       this.setSector(this.jointCommission, 1);
    }

    this.form = this.formBuilder.group({
      id: [this.jointCommission.id? this.jointCommission.id : null, []],
      active: [(this.jointCommission.active !== null && this.jointCommission.active !== undefined) ? this.jointCommission.active : true, [Validators.required]],
      code: [this.jointCommission.code ? this.jointCommission.code : "", []],
      nameDE: [this.jointCommission.nameDE ? this.jointCommission.nameDE : "", []],
      nameEN: [this.jointCommission.nameEN ? this.jointCommission.nameEN : "", []],
      nameFR: [this.jointCommission.nameFR ? this.jointCommission.nameFR : "", []],
      nameNL: [this.jointCommission.nameNL ? this.jointCommission.nameNL : "", []],
      sector: [this.jointCommission.sector.nameEN, []],
      starred: [this.jointCommission.starred ? this.jointCommission.starred : true, []],
      validityStartDate: [this.jointCommission.validityStartDate ? this.jointCommission.validityStartDate : null, []],
      validityEndDate: [this.jointCommission.validityEndDate ? this.jointCommission.validityEndDate : null, []],
      }
    );
  }

  onSubmit() {
    let pastActive = this.jointCommission.active;
    Object.assign(this.jointCommission, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    if (this.form.value.sector === 'Horeca') {
      this.setSector(this.jointCommission, 1);
    } else {
      this.setSector(this.jointCommission, 2);
    }

    if (pastActive !== this.jointCommission.active) {
      this.facade.updateJointCommissions(this.jointCommission.code, this.jointCommission.active).subscribe({
        next: response => {},
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(error.message, 'X', 'customSnackError');
        }
      });
    }

    this.facade.saveJointCommission(this.jointCommission)
      .subscribe({
        next: jointCommission => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: jointCommission.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  private setSector(jointCommission: JointCommission, sectorId: number) {
     if (sectorId === 1) {
       jointCommission.sector = new Sector();
       jointCommission.sector.id = 1;
       jointCommission.sector.active = true;
       jointCommission.sector.code = "HORECA";
       jointCommission.sector.nameDE = "Horeca";
       jointCommission.sector.nameEN = "Horeca";
       jointCommission.sector.nameFR = "Horeca";
       jointCommission.sector.nameNL = "Horeca";
     } else {
           jointCommission.sector = new Sector();
           jointCommission.sector.id = 2;
           jointCommission.sector.active = true;
           jointCommission.sector.code = "MEDICAL";
           jointCommission.sector.nameDE = "Medical";
           jointCommission.sector.nameEN = "Medical";
           jointCommission.sector.nameFR = "Medical";
           jointCommission.sector.nameNL = "Medical";
       }
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

}
