import {Component, OnInit} from '@angular/core';
import {FacadeService} from '../shared/service/facade/facade.service';
import {Role} from '../users/shared/model/user.model';
import {AuthorizationService} from '../login/shared/service/authorization.service';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {

  searchForm: UntypedFormGroup;
  query: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private facadeService: FacadeService,
              private authorizationService: AuthorizationService,
              public route: ActivatedRoute) {
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  ngOnInit() {
    this.query = this.route.snapshot.queryParams['search'];
    this.searchForm = this.formBuilder.group({
      query: [this.query, []],
    });

    this.searchForm.get('query').valueChanges.subscribe(value => {
      this.query = value;
    });
  }
}
