import {Serializer} from '../../../shared/model/serializer';
import {PaymentTerms} from './customer.model';

export class PaymentTermsSerializer implements Serializer {
  fromJson(jsonPaymentTerms: any): PaymentTerms {
    const paymentTerms: PaymentTerms = new PaymentTerms();

    paymentTerms.mealFacial = jsonPaymentTerms.mealFacial;
    paymentTerms.mealEmployer = jsonPaymentTerms.mealEmployer;
    paymentTerms.dimonaFee = jsonPaymentTerms.dimonaFee;
    paymentTerms.travelExpenses = jsonPaymentTerms.travelExpenses;
    paymentTerms.coefficientClothes = jsonPaymentTerms.coefficientClothes;
    paymentTerms.coefficientPension = jsonPaymentTerms.coefficientPension;
    paymentTerms.coefficientPurchasing = jsonPaymentTerms.coefficientPurchasing;
    paymentTerms.coefficientEcocheques = jsonPaymentTerms.coefficientEcocheques;
    paymentTerms.mealVoucher = jsonPaymentTerms.mealVoucher;
    paymentTerms.travelExpenseBillingCoefficient = jsonPaymentTerms.travelExpenseBillingCoefficient;
    paymentTerms.dimonaBillingCoefficient = jsonPaymentTerms.dimonaBillingCoefficient;
    paymentTerms.clothesBillingCoefficient = jsonPaymentTerms.clothesBillingCoefficient;
    paymentTerms.purchasingBillingCoefficient = jsonPaymentTerms.purchasingBillingCoefficient;
    paymentTerms.ecochequesBillingCoefficient = jsonPaymentTerms.ecochequesBillingCoefficient;
    paymentTerms.pensionBillingCoefficient = jsonPaymentTerms.pensionBillingCoefficient;
    paymentTerms.flexyRate = jsonPaymentTerms.flexyRate;

    return paymentTerms;
  }

  toJson(paymentTerms: PaymentTerms): any {
    return {
      mealFacial: paymentTerms.mealFacial,
      mealEmployer: paymentTerms.mealEmployer,
      dimonaFee: paymentTerms.dimonaFee,
      travelExpenses: paymentTerms.travelExpenses,
      coefficientClothes: paymentTerms.coefficientClothes,
      coefficientPension: paymentTerms.coefficientPension,
      coefficientPurchasing: paymentTerms.coefficientPurchasing,
      coefficientEcocheques: paymentTerms.coefficientEcocheques,
      mealVoucher: paymentTerms.mealVoucher,
      travelExpenseBillingCoefficient: paymentTerms.travelExpenseBillingCoefficient,
      dimonaBillingCoefficient: paymentTerms.dimonaBillingCoefficient,
      clothesBillingCoefficient: paymentTerms.clothesBillingCoefficient,
      purchasingBillingCoefficient: paymentTerms.purchasingBillingCoefficient,
      ecochequesBillingCoefficient: paymentTerms.ecochequesBillingCoefficient,
      pensionBillingCoefficient: paymentTerms.pensionBillingCoefficient,
      flexyRate: paymentTerms.flexyRate,
    };
  }
}
