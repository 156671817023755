import {Serializer} from '../../serializer';
import {UserPage} from './user-page.model';
import {UserSerializer} from '../../../../users/shared/model/user.serializer';

export class UserPageSerializer implements Serializer {

  private userSerializer = new UserSerializer();

  fromJson(json: any): UserPage {
    const userPage = new UserPage();

    json.content.forEach(
      content => {
        userPage.content.push(this.userSerializer.fromJson(content));
      }
    );

    userPage.totalElements = json.totalElements;
    userPage.totalPages = json.totalPages;
    return userPage;
  }

  toJson(resource: Object): any {
  }
}

