import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PublicHoliday } from 'src/app/admin/model/public-holiday.model';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';

@Component({
  selector: 'app-public-holiday-dialog',
  templateUrl: './public-holiday-dialog.component.html',
  styleUrls: ['./public-holiday-dialog.component.css']
})
export class PublicHolidayDialogComponent implements OnInit {

  publicHoliday: PublicHoliday;
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PublicHolidayDialogComponent>,
    private facade: FacadeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
  ) {
    this.publicHoliday = this.data.element;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      id: [(this.publicHoliday.id !== null && this.publicHoliday.id !== undefined) ? this.publicHoliday.id : null, []],
      active: [(this.publicHoliday.active !== null && this.publicHoliday.active !== undefined) ? this.publicHoliday.active : true, [Validators.required]],
      description: [(this.publicHoliday.description !== null && this.publicHoliday.description !== undefined) ? this.publicHoliday.description : "", []],
      date: [(this.publicHoliday.date !== null && this.publicHoliday.date !== undefined) ? this.publicHoliday.date : new Date(), [Validators.required]],
    }
    );
  }

  onSubmit() {
    Object.assign(this.publicHoliday, this.form.value);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.getFormValidationErrors(this.form);
      return this.openSnackBar(this.translate.instant('popup.form-invalid'), 'X', 'customSnackError');
    }

    this.facade.savePublicHoliday(this.publicHoliday)
      .subscribe({
        next: publicHoliday => {
          this.openSnackBar(this.translate.instant('popup.save-success', { id: publicHoliday.id }), 'X', 'customSnackValid');
        },
        error: (error: HttpErrorResponse) => {
          this.openSnackBar(
            this.translate.instant('popup.save-error', { error: error.message }),
            'X', 'customSnackError');
        }
      });
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, pC: string) {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      if (form.get(key) instanceof FormGroup) {
        this.getFormValidationErrors(form.get(key) as FormGroup);
      }
    });
  }

  cancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

  clearDate(): void {
    this.form.controls.date.setValue('');
  }

}
