import {Serializer} from '../../../shared/model/serializer';
import {SubsidiaryAddress} from './customer.model';

export class SubsidiaryAddressSerializer implements Serializer {

  fromJson(json: any): SubsidiaryAddress {
    const subsidiaryAddress = new SubsidiaryAddress();
    subsidiaryAddress.id = json.id;
    subsidiaryAddress.name = json.name;
    subsidiaryAddress.street = json.street;
    subsidiaryAddress.number = json.number;
    subsidiaryAddress.box = json.box;
    subsidiaryAddress.zip = json.zip;
    subsidiaryAddress.city = json.city;
    subsidiaryAddress.country = json.country;

    return subsidiaryAddress;
  }

  toJson(subsidiaryAddress: SubsidiaryAddress): any {
    return {
      id: subsidiaryAddress.id,
      name: subsidiaryAddress.name,
      street: subsidiaryAddress.street,
      number: subsidiaryAddress.number,
      box: subsidiaryAddress.box,
      zip: subsidiaryAddress.zip,
      city: subsidiaryAddress.city,
      country: subsidiaryAddress.country
    };
  }
}
