import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-export-prestations-new',
  templateUrl: './export-prestations-new.component.html',
  styleUrls: ['./export-prestations-new.component.css']
})
export class ExportPrestationsNewComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.form.addControl('prestationStartDate', new UntypedFormControl());
    this.form.addControl('prestationEndDate', new UntypedFormControl());
    this.form.addControl('workerId', new UntypedFormControl());
    this.form.addControl('customerId', new UntypedFormControl());
  }

  clearStartDatePrestation() {
    this.form.controls.prestationStartDate.setValue('');
  }

  clearEndDatePrestation() {
    this.form.controls.prestationEndDate.setValue('');
  }

}
