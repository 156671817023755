import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {ContractPage} from '../../model/pagination/contract/contract-page.model';

@Injectable()
export class ContractsFromRequestResolver implements Resolve<Observable<ContractPage>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<ContractPage> {
    const pageIndex = route.queryParams['pageIndex'] ?? 0;
    const pageSize = route.queryParams['pageSize'] ?? 5;
    const requestId = route.queryParams['r'];

    return this.facadeService.getPageContract(pageIndex, pageSize, {requestId: requestId, active: true}).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
