import {Serializer} from '../../serializer';
import { TravelExpenseCoefficientSerializer } from './../../../../admin/model/travel-expense-coefficient.serializer';
import { TravelExpenseCoefficientPage } from './travel-expense-coefficient-page.model';

export class TravelExpenseCoefficientPageSerializer implements Serializer {

  private travelExpenseCoefficientSerializer = new TravelExpenseCoefficientSerializer();

  fromJson(json: any): TravelExpenseCoefficientPage {
    const travelExpenseCoefficientPage = new TravelExpenseCoefficientPage();

    json.content.forEach(
      content => {
        travelExpenseCoefficientPage.content.push(this.travelExpenseCoefficientSerializer.fromJson(content));
      }
    );

    travelExpenseCoefficientPage.totalElements = json.totalElements;
    travelExpenseCoefficientPage.totalPages = json.totalPages;
    return travelExpenseCoefficientPage;
  }

  toJson(resource: Object): any {
  }
}

