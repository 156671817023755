import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FacadeService} from '../../shared/service/facade/facade.service';
import {User} from '../../users/shared/model/user.model';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.css']
})
export class EditPasswordComponent implements OnInit {

  user: User;
  uuid: string;

  constructor(private route: ActivatedRoute,
              private facadeService: FacadeService,
              private translate: TranslateService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.uuid = this.route.snapshot.queryParamMap.get('uuid');
    this.user = this.route.snapshot.data['user'];
    console.log(this.user);
  }

  changePassword(form: NgForm) {
    if (!form.valid) {
      return this.openSnackBar(this.translate.instant('users.form.message.error.passwordForm.form'), 'customSnackError');
    }
    const newPassword = form.value.newPassword.trim();
    const confirmPassword = form.value.confirmNewPassword.trim();

    if (newPassword !== confirmPassword) {
      return this.openSnackBar(this.translate.instant('users.form.message.error.passwordForm.different'), 'customSnackError');
    }

    this.facadeService.updateUserPassword('', newPassword, this.user.username, this.uuid)
      .subscribe({
        next:
          ignoreProperty => {
            this.openSnackBar(this.translate.instant('users.form.message.success.passwordForm.save'));
            window.location.href = '/login';
          },
        error: ignoreProperty => {
          this.openSnackBar(this.translate.instant('users.form.message.error.passwordForm.save'), 'customSnackError');
        }
      });
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }
}
