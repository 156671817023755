import {Serializer} from 'src/app/shared/model/serializer';
import {BankData, ContractData, MedicalData, StudentQuotaHour, Worker, WorkerStatus} from 'src/app/workers/shared/model/worker.model';
import {AddressSerializer} from '../../../shared/model/address/address.serializer';
import {Address} from '../../../shared/model/address/address.model';
import {DateSerializer} from '../../../shared/model/date.serializer';

export class WorkerSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();
  private addressSerializer: AddressSerializer = new AddressSerializer();

  private static jsonToBankDate(jsonBankData: any): BankData {
    const bankData: BankData = new BankData();

    bankData.bic = jsonBankData.bic;
    bankData.iban = jsonBankData.iban;
    bankData.deposit = jsonBankData.deposit;
    bankData.diplomaNumber = jsonBankData.diplomaNumber;
    bankData.visaNumber = jsonBankData.visaNumber;
    bankData.inamiNumber = jsonBankData.inamiNumber;
    bankData.positions = jsonBankData.positions;
    bankData.licenseNumber = jsonBankData.licenseNumber;
    bankData.expertize = jsonBankData.expertize;
    bankData.withHoldingRate = jsonBankData.withHoldingRate;
    bankData.deductionsSeizures = jsonBankData.deductionsSeizures;
    bankData.invalid = jsonBankData.invalid;
    bankData.familySituation = jsonBankData.familySituation;
    bankData.dependentsNumber = jsonBankData.dependentsNumber;
    bankData.federalTargetGroupReduction = jsonBankData.federalTargetGroupReduction;
    bankData.walloniaTargetGroupReduction = jsonBankData.walloniaTargetGroupReduction;
    bankData.socialExclusionReport = jsonBankData.socialExclusionReport;

    return bankData;
  }

  private static jsonToMedicalDate(jsonMedicalData: any): MedicalData {
    const medicalData: MedicalData = new MedicalData();

    medicalData.hepBDosage = jsonMedicalData.hepBDosage;
    medicalData.medicalVisit = jsonMedicalData.medicalVisit;
    medicalData.intradermo = jsonMedicalData.intradermo;
    medicalData.socialRiskDeclaration1 = jsonMedicalData.socialRiskDeclaration1;
    medicalData.socialRiskDeclaration2 = jsonMedicalData.socialRiskDeclaration2;
    medicalData.socialRiskDeclaration3 = jsonMedicalData.socialRiskDeclaration3;
    medicalData.socialRiskDeclaration4 = jsonMedicalData.socialRiskDeclaration4;
    medicalData.socialRiskDeclaration5 = jsonMedicalData.socialRiskDeclaration5;
    medicalData.socialRiskDeclaration6 = jsonMedicalData.socialRiskDeclaration6;

    return medicalData;
  }

  private static bankDataToJson(bankData: BankData) {
    return {
      bic: bankData.bic,
      iban: bankData.iban,
      deposit: bankData.deposit,
      diplomaNumber: bankData.diplomaNumber,
      visaNumber: bankData.visaNumber,
      inamiNumber: bankData.inamiNumber,
      positions: bankData.positions,
      licenseNumber: bankData.licenseNumber,
      expertize: bankData.expertize,
      withHoldingRate: bankData.withHoldingRate,
      deductionsSeizures: bankData.deductionsSeizures,
      invalid: bankData.invalid,
      familySituation: bankData.familySituation,
      dependentsNumber: bankData.dependentsNumber,
      federalTargetGroupReduction: bankData.federalTargetGroupReduction,
      walloniaTargetGroupReduction: bankData.walloniaTargetGroupReduction,
      socialExclusionReport: bankData.socialExclusionReport
    };
  }

  private static medicalDataToJson(medicalData: MedicalData) {
    return {
      hepBDosage: medicalData.hepBDosage,
      medicalVisit: medicalData.medicalVisit,
      intradermo: medicalData.intradermo,
      socialRiskDeclaration1: medicalData.socialRiskDeclaration1,
      socialRiskDeclaration2: medicalData.socialRiskDeclaration2,
      socialRiskDeclaration3: medicalData.socialRiskDeclaration3,
      socialRiskDeclaration4: medicalData.socialRiskDeclaration4,
      socialRiskDeclaration5: medicalData.socialRiskDeclaration5,
      socialRiskDeclaration6: medicalData.socialRiskDeclaration6
    };
  }


  private static jsonToStudentQuotaHours(jsonQuotaHour: any): StudentQuotaHour {
    const studentQuotaHours: StudentQuotaHour = new StudentQuotaHour();

    studentQuotaHours.id = jsonQuotaHour.id;
    studentQuotaHours.year = jsonQuotaHour.year;
    studentQuotaHours.hoursDone = jsonQuotaHour.hoursDone;

    return studentQuotaHours;
  }

  private static studentQuotaHoursToJson(studentQuotaHour: StudentQuotaHour) {
    return {
      worker: {
        id: studentQuotaHour.worker.id
      },
      id: studentQuotaHour.id,
      year: studentQuotaHour.year,
      hoursDone: studentQuotaHour.hoursDone,
    };
  }

  fromJson(json: any): any {
    const worker: Worker = new Worker();
    let address = new Address();
    let workerStatus = new WorkerStatus();
    let bankData = new BankData();
    let medicalData = new MedicalData();
    let contractData = new ContractData();
    const studentQuotaHourData: StudentQuotaHour[] = [];


    worker.id = json.id;
    worker.workerNumber = json.workerNumber;
    worker.firstName = json.firstName;
    worker.lastName = json.lastName;
    worker.operatingUnitId = json.operatingUnitId;
    worker.niss = json.niss;
    worker.gender = json.gender;
    worker.mobile = json.mobile;
    worker.email = json.email;
    worker.dateOfBirth = this.dateSerializer.fromJson(json.dateOfBirth);
    worker.placeOfBirth = json.placeOfBirth;
    worker.languageCode = json.languageCode;
    worker.active = json.active;
    worker.groupsId = json.groupsId;
    worker.docPending = json.docPending;
    worker.workerPositions = json.workerPositions;
    worker.age = this.getAge(worker.dateOfBirth);


    const jsonAddress = json.address;
    if (jsonAddress) {
      address = this.addressSerializer.fromJson(jsonAddress);
    }
    worker.address = address;

    const jsonWorkerStatus = json.workerStatus;
    if (jsonWorkerStatus) {
      workerStatus = this.jsonToWorkerStatus(jsonWorkerStatus);
    }
    worker.workerStatus = workerStatus;

    const jsonBankData = json.bankData;
    if (jsonBankData) {
      bankData = WorkerSerializer.jsonToBankDate(jsonBankData);
    }
    worker.bankData = bankData;

    const jsonMedicalData = json.medicalData;
    if (jsonMedicalData) {
      medicalData = WorkerSerializer.jsonToMedicalDate(jsonMedicalData);
    }
    worker.medicalData = medicalData;

    const jsonContractData = json.contractData;
    if (jsonContractData) {
      contractData = this.jsonToContractDate(jsonContractData);
    }
    worker.contractData = contractData;

    const jsonStudentQuotaHours = json.studentQuotaHours;
    if (jsonStudentQuotaHours) {
      jsonStudentQuotaHours.forEach(jsonStudentQuotaHour => {
        studentQuotaHourData.push(WorkerSerializer.jsonToStudentQuotaHours(jsonStudentQuotaHour));
      });
    }
    studentQuotaHourData.sort((a, b) => a.year - b.year);
    worker.studentQuotaHours = studentQuotaHourData;

    return worker;
  }

  toJson(worker: Worker): any {

    const studentQuotaHours: any [] = [];
    worker.studentQuotaHours.forEach(
      qh => {
        qh.worker = worker;
        studentQuotaHours.push(WorkerSerializer.studentQuotaHoursToJson(qh));
      }
    );

    return {
      id: worker.id,
      workerNumber: worker.workerNumber,
      firstName: worker.firstName,
      lastName: worker.lastName,
      operatingUnitId: worker.operatingUnitId,
      niss: worker.niss,
      gender: worker.gender,
      mobile: worker.mobile,
      email: worker.email,
      dateOfBirth: this.dateSerializer.toJson(worker.dateOfBirth),
      placeOfBirth: worker.placeOfBirth,
      languageCode: worker.languageCode,
      active: worker.active,
      groupsId: worker.groupsId,
      docPending: worker.docPending,
      workerPositions: worker.workerPositions,
      address: this.addressSerializer.toJson(worker.address),
      workerStatus: this.workerStatusToJson(worker.workerStatus),
      bankData: WorkerSerializer.bankDataToJson(worker.bankData),
      medicalData: WorkerSerializer.medicalDataToJson(worker.medicalData),
      contractData: this.contractDataToJson(worker.contractData),
      studentQuotaHours: studentQuotaHours
    };
  }

  private getAge(dateOfBirth: Date): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  private jsonToWorkerStatus(jsonWorkerStatus: any): WorkerStatus {
    const workerStatus: WorkerStatus = new WorkerStatus();

    workerStatus.workerType = jsonWorkerStatus.workerType;
    workerStatus.seniority = jsonWorkerStatus.seniority;
    workerStatus.studentJobQuotaStartDate = this.dateSerializer.fromJson(jsonWorkerStatus.studentJobQuotaStartDate);
    workerStatus.studentJobQuotaHours = jsonWorkerStatus.studentJobQuotaHours;
    workerStatus.studentAtWorkNumber = jsonWorkerStatus.studentAtWorkNumber;
    workerStatus.horecaAtWorkQuotaStartDate = this.dateSerializer.fromJson(jsonWorkerStatus.horecaAtWorkQuotaStartDate);
    workerStatus.horecaAtWorkQuotadays = jsonWorkerStatus.horecaAtWorkQuotadays;
    workerStatus.idCardNumber = jsonWorkerStatus.idCardNumber;
    workerStatus.idCardEndOfValidity = this.dateSerializer.fromJson(jsonWorkerStatus.idCardEndOfValidity);
    workerStatus.nationality = jsonWorkerStatus.nationality;
    workerStatus.residentPermitType = jsonWorkerStatus.residentPermitType;
    workerStatus.residentPermitNumber = jsonWorkerStatus.residentPermitNumber;
    workerStatus.residentPermitEndOfValidity = this.dateSerializer.fromJson(jsonWorkerStatus.residentPermitEndOfValidity);

    return workerStatus;
  }

  private jsonToContractDate(jsonContractData: any): ContractData {
    const contractData: ContractData = new ContractData();

    contractData.studyLevel = jsonContractData.studyLevel;
    contractData.firstContractDate = this.dateSerializer.fromJson(jsonContractData.firstContractDate);
    contractData.lastContractDate = this.dateSerializer.fromJson(jsonContractData.lastContractDate);
    contractData.interimOnlinePlanning = jsonContractData.interimOnlinePlanning;
    contractData.communicationTool = jsonContractData.communicationTool;
    contractData.customerRemarks = jsonContractData.customerRemarks;
    contractData.possibleEvaluation = jsonContractData.possibleEvaluation;
    contractData.operatingUnitNotes = jsonContractData.operatingUnitNotes;

    return contractData;
  }

  private contractDataToJson(contractData: ContractData) {

    return {
      studyLevel: contractData.studyLevel,
      firstContractDate: this.dateSerializer.toJson(contractData.firstContractDate),
      lastContractDate: this.dateSerializer.toJson(contractData.lastContractDate),
      interimOnlinePlanning: contractData.interimOnlinePlanning,
      communicationTool: contractData.communicationTool,
      customerRemarks: contractData.customerRemarks,
      possibleEvaluation: contractData.possibleEvaluation,
      operatingUnitNotes: contractData.operatingUnitNotes
    };
  }

  private workerStatusToJson(workerStatus: WorkerStatus) {
    return {
      workerType: workerStatus.workerType,
      seniority: workerStatus.seniority,
      studentJobQuotaStartDate: this.dateSerializer.toJson(workerStatus.studentJobQuotaStartDate),
      studentJobQuotaHours: workerStatus.studentJobQuotaHours,
      studentAtWorkNumber: workerStatus.studentAtWorkNumber,
      horecaAtWorkQuotaStartDate: this.dateSerializer.toJson(workerStatus.horecaAtWorkQuotaStartDate),
      horecaAtWorkQuotadays: workerStatus.horecaAtWorkQuotadays,
      idCardNumber: workerStatus.idCardNumber,
      idCardEndOfValidity: this.dateSerializer.toJson(workerStatus.idCardEndOfValidity),
      nationality: workerStatus.nationality,
      residentPermitType: workerStatus.residentPermitType,
      residentPermitNumber: workerStatus.residentPermitNumber,
      residentPermitEndOfValidity: this.dateSerializer.toJson(workerStatus.residentPermitEndOfValidity),
    };
  }
}
