import { JointCommissionSerializer } from '../../joint-commission/joint-commission.serializer';
import { Serializer } from '../../serializer';
import { JointCommissionPage } from './joint-commission-page.model';

export class JointCommissionPageSerializer implements Serializer {

  private jointCommissionSerializer = new JointCommissionSerializer();

  fromJson(json: any): JointCommissionPage {
    const jointCommissionPage = new JointCommissionPage();

    json.content.forEach(
      content => {
        jointCommissionPage.content.push(this.jointCommissionSerializer.fromJson(content));
      }
    );

    jointCommissionPage.totalElements = json.totalElements;
    jointCommissionPage.totalPages = json.totalPages;
    return jointCommissionPage;
  }

  toJson(resource: Object): any {
  }
}

