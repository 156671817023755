import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';
import {Worker} from '../shared/model/worker.model';
import {FacadeService} from 'src/app/shared/service/facade/facade.service';
import {GoogleMapService} from 'src/app/shared/service/GoogleMap/google-map.service';
import {DatePipe} from '@angular/common';
import {NonAvailability} from '../shared/model/nonAvailability.model';
import {NonAvailabilityDialogComponent} from './non-availability-dialog/non-availability-dialog.component';
import {Document} from '../../shared/model/document/document.model';
import {ProcessedDocumentDialogComponent} from './processed-document-dialog/processed-document-dialog.component';
import {UploadDocumentComponent} from './upload-document/upload-document.component';
import {ConfirmationDialogComponent} from '../../shared/component/confirmation-dialog/confirmation-dialog.component';
import {AccountStatus, Role} from '../../users/shared/model/user.model';
import {AuthorizationService} from '../../login/shared/service/authorization.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {PrestationPeriod} from '../../prestations/shared/model/prestation-period.model';
import {Prestation} from '../../prestations/shared/model/prestation.model';
import {forkJoin} from 'rxjs';
import {CalendarOptions, DateSelectArg, EventClickArg} from '@fullcalendar/core';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import list from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import {FullCalendarComponent} from '@fullcalendar/angular';

@Component({
  selector: 'app-worker-view',
  templateUrl: './worker-view.component.html',
  styleUrls: ['./worker-view.component.css']
})
export class WorkerViewComponent implements OnInit {

  @ViewChild('calendar') calendarRef: FullCalendarComponent;
  options: CalendarOptions = {
    plugins: [rrulePlugin,
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
      resourceTimelinePlugin,
      list],
    firstDay: 1,
    height: 'auto',
    locales: [enLocale, frLocale, nlLocale],
    timeZone: 'local',
    initialView: 'timeGridWeek',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    slotDuration: '00:30:00',
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
      // meridiem: 'short',
      hour12: false,
    },
    allDaySlot: false,
    handleWindowResize: false,
    locale: this.translate.currentLang,
    eventColor: 'red',
    eventTextColor: '#ffffff',
    datesSet: this.datesSet.bind(this),
    eventClick: this.eventClick.bind(this),
    select: this.handleSelect.bind(this),
    editable: true,
    selectable: true,
  };
  worker: Worker = new Worker();
  operatingUnit: OperatingUnit = new OperatingUnit();
  displayDay: number;
  nonAvailabilities: NonAvailability[] = [];
  prestations: Prestation[] = [];
  documentDisplayedColumns: string[] = ['name', 'type', 'comment', 'processedBy', 'processedDate', 'view', 'delete', 'process'];
  documents = new MatTableDataSource<Document>();
  @ViewChild('paginatorDocuments', {static: true}) paginatorDocuments: MatPaginator;
  pendingDocumentDisplayedColumns: string[] = ['name', 'type', 'comment', 'uploadDate', 'view', 'delete', 'process'];
  pendingDocuments = new MatTableDataSource<Document>();
  @ViewChild('paginatorPendingDocuments', {static: true}) paginatorPendingDocuments: MatPaginator;
  accountStatus: AccountStatus;
  protected readonly AccountStatus = AccountStatus;

  constructor(private dialog: MatDialog,
              private datePipe: DatePipe,
              private googleMapService: GoogleMapService,
              private route: ActivatedRoute,
              private router: Router,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private translate: TranslateService) {
    this.displayDay = new Date().getDay();
    this.worker = this.route.snapshot.data['worker'];
  }

  private static buildRequestsEvent(prestationPeriod: PrestationPeriod, prestation: Prestation) {
    const start = prestationPeriod.plannedStartDate;
    const end = prestationPeriod.plannedEndDate;
    return {
      title: prestation.customerName,
      start: start,
      end: end,
      editable: true,
      overlap: false,
      color: 'blue',
      requestId: prestation.requestId,
      prestationPeriod: prestationPeriod
    };
  }

  ngOnInit() {
    this.getOperatingUnitById(this.worker.operatingUnitId);
    this.setDocuments(this.route.snapshot.data['documents']);
    this.setPendingDocuments(this.route.snapshot.data['pendingDocuments']);
    this.translate.onLangChange.subscribe(next => {
      this.calendarRef.getApi().setOption('locale', next.lang);
    });
    this.canCreateWorkerAccount();
  }

  hasRole() {
    return this.authorizationService.hasRole(Role.SUPERVISOR);
  }

  getLinkWithAddress(worker: Worker): string {
    return this.googleMapService.getLinkWithAddress(worker.address);
  }

  openSnackBar(message: string, pC: string, action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }

  onActivate() {
    this.facadeService.activeWorker(this.worker)
      .subscribe({
        next:
          ignoreProperty => {
            this.router.navigate(['workers']).then();
            return this.openSnackBar(this.translate.instant('workers.view.message.success.activate'), 'customSnackValid');
          },
        error: () => {
          return this.openSnackBar(this.translate.instant('workers.view.message.error.activate'), 'customSnackError');
        }

      });
  }

  onDeactivate() {
    this.facadeService.getPendingRequestByWorker(this.worker.id).subscribe(
      data => {
        if (data.length === 0) {
          this.facadeService.deactivateWorker(this.worker)
            .subscribe({
              next: ignoreProperty => {
                this.router.navigate(['workers']).then();
                return this.openSnackBar(this.translate.instant('workers.view.message.success.deactivate'), 'customSnackValid');
              },
              error: () => {
                return this.openSnackBar(this.translate.instant('workers.view.message.error.deactivate'), 'customSnackError');
              }
            });
        } else {
          return this.openSnackBar(this.translate.instant('workers.view.message.error.pendingRequest'), 'customSnackError');
        }
      }
    );
  }

  getRegistrationDocument(workerId: number) {
    this.facadeService.getRegistrationFormByWorkerId(workerId).subscribe({
      next: data => {
        const file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      error: () => {
        return alert(this.translate.instant('workers.view.message.error.downloadDocument'));
      }
    });
  }

  /////////////////////////////////////////
  eventClick(arg: EventClickArg) {
    if (arg.event.extendedProps.requestId) {
      const requestId = arg.event.extendedProps.requestId;
      return this.router.navigate(['request-edit'], {queryParams: {'r': requestId}});
    }
    this.openNonAvailabilityDialog(arg.event.extendedProps.nonAvailability);
  }

  handleSelect(selectInfo: DateSelectArg) {
    this.openNonAvailabilityDialog(null, selectInfo.start, selectInfo.end);
  }

  openNonAvailabilityDialog(nonAvailability?: NonAvailability, startDate?: Date, endDate?: Date) {
    const dialogRef = this.dialog.open(NonAvailabilityDialogComponent, {
      data: {
        worker: this.worker,
        nonAvailability: nonAvailability,
        startDate: startDate,
        endDate: endDate
      }
    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const activeDate = this.calendarRef.getApi().view.activeStart;
        const activeEnd = this.calendarRef.getApi().view.activeEnd;
        this.facadeService.getNonAvailabilitiesFilter(this.worker.id, activeDate, activeEnd)
          .subscribe({

            next: (nonAvailabilities: NonAvailability[]) => {
              this.nonAvailabilities = nonAvailabilities;
              this.buildCalendarEvents();
            }, error: () => {
              // return alert(this.translate.instant('workers.view.message.error.nonAvailabilities'));
            }
          });
      }
    });
  }

  datesSet(event: any) {
    const start = event.view.activeStart;
    const end = event.view.activeEnd;
    forkJoin([
      this.facadeService.filterPrestation(start, end, this.worker.id),
      this.facadeService.getNonAvailabilitiesFilter(this.worker.id, start, end),
    ]).subscribe({
      next: ([prestationFiltered, nonAvailabilitiesFiltered]) => {
        this.prestations = prestationFiltered;
        this.nonAvailabilities = nonAvailabilitiesFiltered;
        this.buildCalendarEvents();
      },
      error: () => {
        return this.openSnackBar(this.translate.instant('requests.form.message.error.nonAvailabilities'), 'customSnackError');
      }

    });
  }

  /////////////////////////////////////////
  //////      No Availability        //////

  //////////////////////////////////
  downloadDocument(document: Document) {
    this.facadeService.downloadWorkerDocument(this.worker.id, document).subscribe({
      next: data => {
        const file = new Blob([data], {type: document.contentType});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      },
      error: () => {
        return alert(this.translate.instant('workers.view.message.error.downloadDocument'));
      }
    });
  }

  deleteDocument(document: Document, isPending: boolean = true) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.facadeService.deleteWorkerDocument(this.worker.id, document).subscribe({
          next:
            ignoreProperty => {
              if (isPending) {
                return this.reloadPendingDocuments();
              }
              this.reloadDocuments();
            },
          error: () => {
            return alert(this.translate.instant('workers.view.message.error.deleteDocument'));
          }
        });
      }
    });
  }

  uploadDocument() {
    const dialogRef = this.dialog.open(UploadDocumentComponent, {
      data: {
        worker: this.worker
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadDocuments();
      }
    });
  }

  processDocument(document: Document, isPending: boolean = true) {
    const dialogRef = this.dialog.open(ProcessedDocumentDialogComponent, {
      data: {
        document: document,
        worker: this.worker,
        isPending: isPending
      },
      minWidth: '50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reloadPendingDocuments();
        this.reloadDocuments();
      }
    });
  }

  //////////////////////////////////
  //////      Document        //////

  getFormattedContractDate(date: Date): string {
    if (date != null) {
      return this.datePipe.transform(date, 'dd/MM/yyyy');
    }
    return '';
  }

  canCreateWorkerAccount() {
    if (this.worker.id && this.accountStatus == null) {
      this.facadeService.checkWorkerEmailTaken(this.worker.email).subscribe({
        next: (status: AccountStatus) => {
          this.accountStatus = status;
        }
      });
    }
  }

  createWorkerAccount() {
    this.facadeService.createWorkerAccount(this.worker.id)
      .subscribe({
        next: ignoreProperty => {
          return alert(this.translate.instant('workers.view.message.success.mailSend'));
        },
        error: () => {
          return alert(this.translate.instant('workers.view.message.error.mailSend'));
        }
      });
  }

  private setPendingDocuments(documents: Document[]) {
    this.pendingDocuments = new MatTableDataSource<Document>(documents);
    this.pendingDocuments.paginator = this.paginatorPendingDocuments;
  }

  private setDocuments(documents: Document[]) {
    this.documents = new MatTableDataSource<Document>(documents);
    this.documents.paginator = this.paginatorDocuments;
  }

  private buildCalendarEvents() {
    const events = [];
    this.calendarRef.getApi().removeAllEvents();
    this.nonAvailabilities.forEach(
      nonAvailability => {
        const event = NonAvailability.buildEvent(nonAvailability);
        event['editable'] = true;
        events.push(event);
      }
    );
    this.prestations.forEach(
      prestation => {
        prestation.periods.forEach(
          prestationPeriod => events.push(WorkerViewComponent.buildRequestsEvent(prestationPeriod, prestation))
        );
      }
    );

    events.forEach(
      event => this.calendarRef.getApi().addEvent(event)
    );
  }

  private getOperatingUnitById(operatingUnitId: number) {
    this.facadeService.getOperatingUnitById(operatingUnitId).subscribe({
      next: operatingUnit => {
        Object.assign(this.operatingUnit, operatingUnit);
      },
      error: () => {
        // return alert(this.translate.instant('workers.view.message.error.operatingUnit'));
      }

    });
  }

  private reloadPendingDocuments() {
    this.facadeService.getDocumentsByWorkerId(this.worker.id, true).subscribe({
      next: data => {
        this.setPendingDocuments(data);
      },
      error: () => {
        return alert(this.translate.instant('workers.view.message.error.pendingDocuments'));
      }
    });
  }

  private reloadDocuments() {
    this.facadeService.getDocumentsByWorkerId(this.worker.id).subscribe({
      next: data => {
        this.setDocuments(data);
      },
      error: () => {
        return alert(this.translate.instant('workers.view.message.error.reloadDocuments'));
      }
    });
  }
}
