import {Component, Inject, OnInit} from '@angular/core';
import {PrestationPeriodCode} from '../../shared/model/prestation-period-code.model';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PrestationPeriod} from '../../shared/model/prestation-period.model';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {PrestationCode, SalaryType} from '../../../shared/model/prestation-code/prestation-code.model';
import * as clone from 'clone';
import {Prestation} from '../../shared/model/prestation.model';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {fourDecimalsValidator} from '../../../shared/validator/four-decimals.validator';

@Component({
  selector: 'app-edit-correction-dialog',
  templateUrl: './edit-correction-dialog.component.html',
  styleUrls: ['./edit-correction-dialog.component.css']
})
export class EditCorrectionDialogComponent implements OnInit {
  columns: string[] = ['code', 'description', 'number', 'days', 'totalBasedSalary', 'rate', 'percentage', 'total', 'coefficient', 'billingPercentage', 'billingTotal'];

  dataSource = new MatTableDataSource<PrestationPeriodCode>();
  tableForm: UntypedFormGroup;
  prestationPeriod: PrestationPeriod;
  prestation: Prestation;
  prestationCodes: PrestationCode[] = [];

  constructor(public dialogRef: MatDialogRef<EditCorrectionDialogComponent>,
              private formBuilder: UntypedFormBuilder,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService) {
    this.prestationPeriod = clone<PrestationPeriod>(data.prestationPeriod);
    this.prestationCodes = data.prestationCodes;
    this.prestationCodes = this.prestationCodes.sort((a, b) => a.code.localeCompare(b.code));
    this.prestation = data.prestation;
    this.setDataSource(this.prestationPeriod.prestationPeriodCodes);
  }

  ngOnInit() {
    this.buildForm();
    this.setPrestationPeriodCodeForm(this.data.prestationPeriod.prestationPeriodCodes);
  }

  setPrestationPeriodCodeForm(prestationPeriodCode: PrestationPeriodCode[]) {
    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];
    while (periodForm.length > 0) {
      periodForm.removeAt(0);
    }

    for (let index = 0; index < prestationPeriodCode.length; index++) {
      periodForm.push(this.createPeriodCodeFormGroup(prestationPeriodCode[index]));
      this.disableFields(periodForm.at(index), prestationPeriodCode[index].code);
    }
  }

  addLine() {
    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];
    const p = new PrestationPeriodCode();
    periodForm.push(this.createPeriodCodeFormGroup(p));
    this.prestationPeriod.prestationPeriodCodes.push(p);
    this.setDataSource(this.prestationPeriod.prestationPeriodCodes);
  }

  selectionChangeOnCode(index: number, code: string) {
    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];
    const period = periodForm.at(index);
    const prestationCode = this.getPrestationCodeByCode(code);
    period.get('labelEN').setValue(prestationCode.labelEN);
    period.get('number').setValue(0);
    period.get('days').setValue(1);
    period.get('totalBasedSalary').setValue(0);
    period.get('rate').setValue(0);
    period.get('percentage').setValue(0);
    period.get('billingPercentage').setValue(0);
    period.get('coefficient').setValue(0);
    period.get('total').setValue(0);
    period.get('billingTotal').setValue(0);
    this.disableFields(period, prestationCode.code);
  }

  submit() {
    if (!this.tableForm.valid) {
      return alert(this.translate.instant('prestations.correction.edit.message.error.submit'));
    }

    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];

    const prestationPeriodCodes: PrestationPeriodCode[] = [];
    for (let i = 0; i < periodForm.length; i++) {

      const periodCode = periodForm.at(i);
      const code = periodCode.get('code').value;
      const originalPrestationPeriodCode =
        this.prestationPeriod.prestationPeriodCodes.find(c => c.code === code);
      if (!periodCode.touched) {
        originalPrestationPeriodCode.prestationPeriod = this.prestationPeriod;
        prestationPeriodCodes.push(originalPrestationPeriodCode);
      } else {
        let prestationPeriodCode: PrestationPeriodCode = new PrestationPeriodCode();
        if (originalPrestationPeriodCode) {
          prestationPeriodCode = originalPrestationPeriodCode;
        }
        prestationPeriodCode.billingTotal = null;
        const pC = this.prestationCodes.find(p => p.code === code);
        prestationPeriodCode.labelEN = pC.labelEN;
        prestationPeriodCode.labelFR = pC.labelFR;
        prestationPeriodCode.labelNL = pC.labelNL;
        prestationPeriodCode.prestationCoefficient = periodCode.get('rate').value;
        prestationPeriodCode.prestationPercentage = periodCode.get('percentage').value;
        prestationPeriodCode.billingCoefficient = periodCode.get('coefficient').value;
        prestationPeriodCode.billingPercentage = periodCode.get('billingPercentage').value;
        prestationPeriodCode.code = periodCode.get('code').value;
        prestationPeriodCode.days = periodCode.get('days').value;
        prestationPeriodCode.hoursDecimal = periodCode.get('number').value;
        prestationPeriodCode.totalBasedSalary = periodCode.get('totalBasedSalary').value;
        prestationPeriodCode.prestationPeriod = this.prestationPeriod;
        prestationPeriodCodes.push(prestationPeriodCode);
      }
    }

    this.prestationPeriod.prestationPeriodCodes = prestationPeriodCodes;
    this.prestationPeriod.prestation = this.prestation;


    this.facadeService.modifyPrestationPeriod(this.prestationPeriod)
      .subscribe({
        next: (p: PrestationPeriod) => {
          this.dialogRef.close(p);
          this.openSnackBar(this.translate.instant('prestations.correction.edit.message.success.save'));
        },
        error: () => {
          this.openSnackBar(this.translate.instant('prestations.correction.edit.message.error.save'));
        }
      });
  }

  getTotal(index: number) {
    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];
    const period = periodForm.at(index);

    const total = this.getBase(period) *
      period.get('rate').value *
      (period.get('percentage').value / 100);

    period.get('total').setValue(total);

    return period.get('total').value;
  }

  getTotalFacturation(index: number) {
    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];
    const period = periodForm.at(index);

    const billingTotal =
      this.getBase(period) *
      period.get('rate').value *
      period.get('coefficient').value *
      (period.get('billingPercentage').value / 100);

    period.get('billingTotal').setValue(billingTotal);

    return period.get('billingTotal').value;
  }

  getDescription(index: number) {
    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];
    const period = periodForm.at(index);

    switch (this.translate.currentLang) {
      case 'fr':
        return period.get('labelFR').value;
      case 'en':
      default:
        return period.get('labelEN').value;

    }

  }

  getCode(index: number) {
    const periodForm = <UntypedFormArray>this.tableForm.controls['periods'];
    const period = periodForm.at(index);

    return period.get('code').value;
  }

  private setDataSource(prestationPeriodCode: PrestationPeriodCode[]) {
    this.dataSource = new MatTableDataSource<PrestationPeriodCode>(prestationPeriodCode);
  }

  private buildForm() {
    this.tableForm = this.formBuilder.group({
      periods: this.formBuilder.array([], [Validators.required])
    });
  }

  private createPeriodCodeFormGroup(period: PrestationPeriodCode): UntypedFormGroup {

    return this.formBuilder.group({
      code: [period.code, [Validators.required]],
      labelEN: [period.labelEN, []],
      labelFR: [period.labelFR, []],
      number: [period.hoursDecimal, [Validators.required]],
      days: [period.days, [Validators.required, Validators.min(0)]],
      totalBasedSalary: [period.totalBasedSalary, [Validators.required, Validators.min(0)]],
      rate: [
        period.prestationCoefficient
          ? +period.prestationCoefficient.toFixed(4)
          : period.prestationCoefficient,
        [Validators.required, Validators.min(0), fourDecimalsValidator]
      ],
      percentage: [period.prestationPercentage, [Validators.required, Validators.min(0), Validators.max(100)]],
      billingPercentage: [period.billingPercentage, [Validators.required, Validators.min(0), Validators.max(100)]],
      coefficient: [
        period.billingCoefficient
          ? +period.billingCoefficient.toFixed(4)
          : period.billingCoefficient,
        [Validators.required]
      ],
      total: [period.prestationSalary, []],
      billingTotal: [period.billingTotal, []]
    });
  }

  private getPrestationCodeByCode(code: string): PrestationCode {
    return this.prestationCodes.find(c => c.code === code);
  }

  private disableFields(control: AbstractControl, code: string) {
    control.get('code').disable();

    // Codes 2317 & 2318 must be negative //
    if (code === '2317' || code === '2318'){
      control.get('coefficient').setValidators([Validators.required, Validators.max(0)]);
    }
    else {
        control.get('coefficient').setValidators([Validators.required, Validators.min(0)]);
    }

    switch (this.getPrestationCodeByCode(code).getSalaryType()) {
      case SalaryType.TOTAL_BASED_SALARY: {
        control.get('number').disable();
        control.get('days').disable();
        break;
      }
      case SalaryType.NUMBER: {
        control.get('totalBasedSalary').disable();
        control.get('days').disable();
        break;
      }
      case SalaryType.DAYS: {
        control.get('number').disable();
        control.get('totalBasedSalary').disable();
        break;
      }
    }

    return;
  }

  private getBase(period: AbstractControl): number {

    if (!period.get('code').value) {
      return;
    }

    switch (this.getPrestationCodeByCode(period.get('code').value).getSalaryType()) {

      case SalaryType.DAYS: {
        return period.get('days').value;
      }
      case SalaryType.NUMBER: {
        return period.get('number').value;
      }
      case SalaryType.TOTAL_BASED_SALARY: {
        return period.get('totalBasedSalary').value;
      }
    }
  }

  private openSnackBar(message: string) {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      panelClass: ['customSnackValid']
    });
  }
}
