import {Serializer} from '../../serializer';
import { SalaryScaleSerializer } from './../../../../admin/model/salary-scale.serializer';
import { SalaryScalePage } from './salary-scale-page.model';

export class SalaryScalePageSerializer implements Serializer {

  private salaryScaleSerializer = new SalaryScaleSerializer();

  fromJson(json: any): SalaryScalePage {
    const salaryScalePage = new SalaryScalePage;

    json.content.forEach(
      content => {
        salaryScalePage.content.push(this.salaryScaleSerializer.fromJson(content));
      }
    );

    salaryScalePage.totalElements = json.totalElements;
    salaryScalePage.totalPages = json.totalPages;

    return salaryScalePage;
  }

  toJson(resource: Object): any {
  }
}
