import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Request, RequestStatus} from '../../model/request.model';
import {FacadeService} from '../../../../shared/service/facade/facade.service';
import {TranslateService} from '@ngx-translate/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Prestation} from '../../../../prestations/shared/model/prestation.model';

@Component({
  selector: 'app-paid-request',
  templateUrl: './paid-request.component.html',
  styleUrls: ['./paid-request.component.css']
})
export class PaidRequestComponent implements OnInit {
  displayedColumns: string[] = ['requestId', 'customerId', 'workerId', 'startDate', 'endDate', 'paid'];
  dataSource = new MatTableDataSource<Prestation>();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private facadeService: FacadeService,
              private translate: TranslateService) {
  }

  @Input() set prestations(p: Prestation[]) {
    this.dataSource = new MatTableDataSource<Prestation>(p);
    this.dataSource.paginator = this.paginator;
  }

  get RequestStatus() {
    return RequestStatus;
  }

  ngOnInit() {
  }

  setRequestPaid(request: Request, e: any) {
    if (e.checked) {
      this.facadeService.paidRequest(request).subscribe({
        next:
          r => {
            request.status = r.status;
          },
        error: ignoreProperty => {
          alert(this.translate.instant('requests.paid.message.error.requests'));
        }
      });
    } else {
      this.facadeService.unpaidRequest(request).subscribe({
        next:
          r => {
            request.status = r.status;
          },
        error: ignoreProperty => {
          alert(this.translate.instant('requests.paid.message.error.unpaidRequests'));
        }
      });
    }
  }

}
