import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Request} from 'src/app/requests/shared/model/request.model';

@Injectable()
export class RequestResolver implements Resolve<Observable<Request>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Request> {
    const requestId = route.queryParams['r'];
    if (requestId) {
      return this.facadeService.getRequestById(+requestId).pipe(
        map((data) => data),
        catchError(error => {
          return of({error: error});
        })
      );
    }
  }
}
