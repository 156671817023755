import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AccountStatus, User} from '../model/user.model';
import {Observable} from 'rxjs';
import {UserSerializer} from '../model/user.serializer';
import {map} from 'rxjs/operators';
import {UserPage} from '../../../shared/model/pagination/user/user-page.model';
import {UserPageSerializer} from '../../../shared/model/pagination/user/user-page.serializer';
import {Login} from '../../../login/shared/model/login.model';

@Injectable()
export class UserService {
  private userSerializer: UserSerializer = new UserSerializer();
  private userPageSerializer: UserPageSerializer = new UserPageSerializer();

  private userServiceURL = environment.user_service_URL + 'users';
  private authServiceURL = environment.user_service_URL + 'auth';

  constructor(private http: HttpClient) {
  }

  authorize(login: Login): Observable<any> {

    const loginJson = {
      'username': login.username,
      'password': login.password
    };

    return this.http.post<any>(this.authServiceURL + '/login', loginJson, {observe: 'response'});
  }

  refreshToken(refreshToken: string): Observable<any> {
    return this.http.get<any>(this.authServiceURL + '/refresh-token?token=' + refreshToken, {observe: 'response'});
  }


  get(id: number): Observable<any> {
    return this.http.get<User>(this.userServiceURL + '/' + id).pipe(
      map((data) => this.userSerializer.fromJson(data))
    );
  }

  getByUuid(uuid: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('uuid', uuid);
    return this.http.get<User>(this.authServiceURL + '/load-by-uuid', {params}).pipe(
      map((data) => this.userSerializer.fromJson(data))
    );
  }

  create(user: User): Observable<any> {

    const userJson = this.userSerializer.toJson(user);

    const body = {
      ...userJson,
      password: user.password
    };

    return this.http.post<User>(this.userServiceURL, body).pipe(
      map((data) => this.userSerializer.fromJson(data))
    );
  }

  save(user: User): Observable<any> {
    return this.http.post<User>(this.userServiceURL, this.userSerializer.toJson(user)).pipe(
      map((data) => this.userSerializer.fromJson(data))
    );
  }

  getByToken(token: string): Observable<any> {

    const authorizationHeader = {
      'Authorization': token,
    };

    const requestOptions = {
      headers: new HttpHeaders(authorizationHeader),
    };

    return this.http.get<User>(this.authServiceURL + '/load-by-token', requestOptions).pipe(
      map((data) => this.userSerializer.fromJson(data))
    );
  }

  updatePassword(password: string, newPassword: string, username: string, uuid?: string): Observable<any> {
    const body = {
      'username': username,
      'password': password,
      'newPassword': newPassword
    };
    let params = new HttpParams();
    if (uuid) {
      params = params.append('uuid', uuid);
    }
    return this.http.post(this.authServiceURL + '/update-password', body, {params: params});
  }

  changePasswordAdmin(username: string, newPassword: string): Observable<any> {
    const body = {
      'username': username,
      'newPassword': newPassword
    };
    return this.http.post(this.userServiceURL + '/change-password', body);
  }

  resetPassword(username: string, lang: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('username', username);
    if (lang) {
      params = params.append('language', lang);
    }
    return this.http.get(this.authServiceURL + '/reset-password', {params});
  }

  getPageUsersConsultants(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<UserPage>(this.userServiceURL + '/consultants/page', {params}).pipe(
      map((data: UserPage) => this.userPageSerializer.fromJson(data))
    );
  }

  getPageUsersWorkers(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<UserPage>(this.userServiceURL + '/workers/page', {params}).pipe(
      map((data: UserPage) => this.userPageSerializer.fromJson(data))
    );
  }

  getPageUsersCustomers(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<UserPage>(this.userServiceURL + '/customers/page', {params}).pipe(
      map((data: UserPage) => this.userPageSerializer.fromJson(data))
    );
  }

  createWorkerAccount(workerId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('workerId', String(workerId));
    return this.http.post<any>(this.userServiceURL + '/worker-account/create', null, {params});
  }

  checkWorkerEmailTaken(email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get<AccountStatus>(this.userServiceURL + '/worker-account/status', {params});
  }

  createCustomerAccount(id: number, email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('customerId', String(id));
    params = params.append('email', email);
    return this.http.post<any>(this.userServiceURL + '/customer-account/create', null, {params});
  }

  checkCustomerEmailTaken(id: number, email: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('customerId', String(id));
    params = params.append('email', email);
    return this.http.get<AccountStatus>(this.userServiceURL + '/customer-account/status', {params});
  }
}
