import {Serializer} from '../serializer';
import {NaceCode} from './nace-code.model';
import {DateSerializer} from '../date.serializer';

export class NaceCodeSerializer implements Serializer {
  private dateSerializer: DateSerializer = new DateSerializer();

  fromJson(json: any): NaceCode {
    const naceCode: NaceCode = new NaceCode();

    naceCode.id = json.id;
    naceCode.active = json.active;
    naceCode.isMaribelSocial = json.isMaribelSocial;
    naceCode.isOnssApl = json.isOnssApl;
    naceCode.code = json.code;
    naceCode.nameDE = json.nameDE;
    naceCode.nameEN = json.nameEN;
    naceCode.nameFR = json.nameFR;
    naceCode.nameNL = json.nameNL;
    naceCode.starred = json.starred;
    naceCode.validityEndDate = this.dateSerializer.fromJson(json.validityEndDate);
    naceCode.validityStartDate = this.dateSerializer.fromJson(json.validityStartDate);

    return naceCode;
  }

  toJson(naceCode: NaceCode): any {
    return {
      id: naceCode.id,
      active: naceCode.active,
      isMaribelSocial: naceCode.isMaribelSocial,
      isOnssApl: naceCode.isOnssApl,
      code: naceCode.code,
      nameDE: naceCode.nameDE,
      nameEN: naceCode.nameEN,
      nameFR: naceCode.nameFR,
      nameNL: naceCode.nameNL,
      starred: naceCode.starred,
      validityEndDate: this.dateSerializer.toJson(naceCode.validityEndDate),
      validityStartDate: this.dateSerializer.toJson(naceCode.validityStartDate)
    };
  }
}
