import {AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Period, Request, RequestStatus} from '../../model/request.model';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe, Location} from '@angular/common';
import {Customer, SubsidiaryAddress} from 'src/app/customers/shared/model/customer.model';
import {Worker} from 'src/app/workers/shared/model/worker.model';
import {FacadeService} from 'src/app/shared/service/facade/facade.service';
import {convertWorkFunction, WorkFunction} from '../../../../shared/model/work-function/work-function.model';
import interactionPlugin, {EventResizeDoneArg} from '@fullcalendar/interaction';
import {ValidateRequestDialogComponent} from './validate-request-dialog/validate-request-dialog.component';
import {AddEventPeriodDialogComponent} from './add-event-period-dialog/add-event-period-dialog.component';
import {Constants} from '../../../../shared/constants';
import {AuthorizationService} from '../../../../login/shared/service/authorization.service';
import {Role} from '../../../../users/shared/model/user.model';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CalendarOptions, DateSelectArg, EventClickArg, EventDropArg, EventHoveringArg} from '@fullcalendar/core';
import {NonAvailability} from '../../../../workers/shared/model/nonAvailability.model';
import {PrestationPeriod} from '../../../../prestations/shared/model/prestation-period.model';
import {Prestation} from '../../../../prestations/shared/model/prestation.model';
import {Dimona, DimonaPeriod} from '../../../../shared/model/dimona/dimona.model';
import {ReferenceDataService} from '../../../../shared/service/reference-data/reference-data.service';
import {forkJoin, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {integerValidator} from '../../../../shared/validator/integer.validator';
import {Address} from '../../../../shared/model/address/address.model';
import {ConfirmationDialogComponent} from '../../../../shared/component/confirmation-dialog/confirmation-dialog.component';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import list from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import enLocale from '@fullcalendar/core/locales/en-gb';
import frLocale from '@fullcalendar/core/locales/fr';
import nlLocale from '@fullcalendar/core/locales/nl';
import {FullCalendarComponent} from '@fullcalendar/angular';
import {PrestationPage} from '../../../../shared/model/pagination/prestation/prestation-page.model';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.css']
})
export class RequestFormComponent implements OnInit, AfterContentInit, AfterViewInit {

  @ViewChild('calendar') calendarRef: FullCalendarComponent;
  options: CalendarOptions = {
    plugins: [rrulePlugin,
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      listPlugin,
      resourceTimelinePlugin,
      list],
    firstDay: 1,
    height: 'auto',
    locales: [enLocale, frLocale, nlLocale],
    timeZone: 'local',
    initialView: 'timeGridWeek',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'timeGridWeek,timeGridDay,listWeek'
    },
    allDaySlot: false,
    dayMaxEventRows: false,
    handleWindowResize: false,
    eventColor: '#8BD1DC',
    eventTextColor: '#ffffff',
    editable: true,
    selectable: true,
    locale: this.translate.currentLang,
    nowIndicator: true,
    eventOverlap: false,
    slotEventOverlap: false,
    selectOverlap: false,
    validRange: {
      start: this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    },
    eventClick: this.handleEventClick.bind(this),
    select: this.handleSelect.bind(this),
    eventResize: this.handleEventResize.bind(this),
    eventDrop: this.handleEventDrop.bind(this),
    datesSet: this.datesSet.bind(this),
    eventMouseEnter: this.handleEventMouseEnter.bind(this)
  };
  requestForm: UntypedFormGroup;
  @Input() request: Request;
  @Input() customers: Customer[];
  @Input() workers: Worker[];
  @Input() prestationCorrections: Prestation[];
  @Input() prestationsToValidate: PrestationPage;
  workFunctions: WorkFunction[] = [];
  costCenters: [] = [];
  addresses: SubsidiaryAddress[] = [];
  eventId = 0;
  nonAvailabilities: any[] = [];
  requests: any[] = [];
  initialized = false;
  periodDimona: DimonaPeriod[] = [];
  workerId: number;
  workFunctionId: number;
  customerId: number;
  customerName: string;
  customerAddress: Address;
  costCenter: string;
  costCenterInitialized = false;
  workerOptions: Observable<Worker[]>;
  customerOptions: Observable<Customer[]>;
  workFunctionOptions: Observable<WorkFunction[]>;
  activeChecked = true;
  requestId: number;
  private readonly NON_AVAILABILITIES_SOURCE_ID = 'non-availabilities';
  private readonly REQUEST_SOURCE_ID = 'requests';

  constructor(private datePipe: DatePipe,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private location: Location,
              private facadeService: FacadeService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private authorizationService: AuthorizationService,
              private referenceDataService: ReferenceDataService,
              private translate: TranslateService) {
    this.workerId = +this.route.snapshot.queryParamMap.get('workerId');
    this.customerId = +this.route.snapshot.queryParamMap.get('customerId');
    this.costCenter = this.route.snapshot.queryParamMap.get('costCenter');
    this.customerName = this.route.snapshot.queryParamMap.get('customerName');
    this.workFunctionId = Number(this.route.snapshot.queryParamMap.get('workFunctionId'));
    if (this.costCenter) {
      this.costCenterInitialized = true;
    }
  }


  private static checkOverlap(start_a: Date, end_a: Date, start_b: Date, end_b: Date): boolean {
    return (start_a.getTime() < end_b.getTime() && start_b.getTime() < end_a.getTime());
  }

  private static buildRequestsEvent(prestationPeriod: PrestationPeriod, prestation: Prestation) {
    const start = prestationPeriod.plannedStartDate;
    const end = prestationPeriod.plannedEndDate;
    return {
      title: prestation.customerName,
      start: start,
      end: end,
      editable: false,
      overlap: false,
      color: 'blue',
      prestationPeriod: prestationPeriod
    };
  }

  ngOnInit() {
    this.getRequest();
    this.formBuilderRequest();
    // initializing customerName bc in setCustomers() the function is based on this value
    if (!this.customerName) {
      this.customerName = this.request.customerName;
    }
    if (this.request.customerId || this.customerId) {
      this.setCustomer();
    }
    this.getRequestDimonas();
    this.workerOptions = this.requestForm.controls['workerId'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterWorker(value))
      );
    this.customerOptions = this.requestForm.controls['customerId'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterCustomer(value))
      );
    this.workFunctionOptions = this.requestForm.controls['workFunctionId'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterWorkFunction(value))
      );
  }

  public buildWorkerName(worker: Worker): string {
    return worker.id + ' ' + worker.lastName.trim().toLowerCase() + ' ' + worker.firstName.trim().toLowerCase();
  }

  getWorkerName(value) {
    const results = this.workers.filter(w => w.id === +value);
    if (results.length) {
      return '#' + results[0].id + ' - ' + results[0].lastName.trim() + ' ' + results[0].firstName.trim();
    }
    return value;
  }

  getCustomerName(value) {
    const results = this.customers.filter(shop => shop.id === +value);
    if (results.length) {
      return '#' + results[0].id + ' - ' + results[0].name.trim();
    }
    return value;
  }

  public buildWorkFunctionName(workFunction: WorkFunction): string {
    return '[' + workFunction.category + ' | ' + workFunction.code + '] '+ workFunction.nameTranslated;
  }

  getWorkFunctionName(value) {
    if (this.workFunctions.length > 0) {
      const results = this.workFunctions.filter(shop => shop.id === +value);
      if (results.length) {
        return this.buildWorkFunctionName(results[0]).trim();
      }
    }
  }

  ngAfterContentInit() {
    if (this.request.id) {
      //  ----- Editing mode, impossible to modify customer and worker -----
      this.requestForm.controls.customerId.disable();
      if (this.request.workerId) {
        this.requestForm.controls.workerId.disable();
      }
      //  ---------------------------------------------------------------------
      this.requestForm.patchValue(this.request);
    }
  }

  ngAfterViewInit() {
    if (this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
      this.calendarRef.getApi().setOption('validRange', {});
    }
    if (this.request.id) {
      this.calendarRef.getApi().setOption('validRange', {});
      this.calendarRef.getApi().gotoDate(this.request.startDate);
      this.request.periods.forEach(
        period => {
          this.addEvent(this.eventBuild(
            period.startDate,
            period.endDate,
            period.description,
            false,
            period.breakDuration,
            period.id
          ));
        }
      );
    }
    if (this.request.workerId || this.workerId) {
      this.setWorker();
    }
    this.initialized = true;

    this.translate.onLangChange.subscribe(next => {
      if (this.calendarRef.getApi()) {
        this.calendarRef.getApi().setOption('locale', next.lang);
      }
      if (this.workFunctions) {
        Object.assign(this.workFunctions, convertWorkFunction(this.workFunctions, this.translate));
      }
    });
  }

  setCustomer() {
    this.resetForm();
    this.facadeService.getCustomerById(this.requestForm.controls.customerId.value).subscribe({
      next: (customer: Customer) => {
        this.addresses = [];
        const principalAddress = new SubsidiaryAddress().fromPrincipal(customer);
        this.addresses.push(principalAddress);
        if (customer.subsidiaryAddresses.length > 0) {
          customer.subsidiaryAddresses.forEach(address => this.addresses.push(address));
        }
        if (this.customerName && this.addresses.length > 1) {
          this.requestForm.controls.customerAddress.setValue(this.addresses.find(s => s.name === this.customerName));
        } else {
          this.requestForm.controls.customerAddress.setValue(principalAddress);
        }
        this.costCenters = customer.legalData.costCenter;
        if (this.costCenter && this.costCenterInitialized) {
          this.requestForm.controls['costCenter'].setValue(this.costCenter);
        }
        this.costCenterInitialized = false;
        this.facadeService.listWorkFunctionsByJointCommissionCode(customer.billingData.jointCommissionCode).subscribe({
          next: (workFunctions: WorkFunction[]) => {
            Object.assign(this.workFunctions, workFunctions);
            this.workFunctions = this.workFunctions.sort((a, b) => a.nameTranslated.localeCompare(b.nameTranslated));

            if (!this.workFunctionId) {
              if (this.request.workFunctionId) {
                this.requestForm.controls.workFunctionId.setValue(this.request.workFunctionId);
              } else {
                this.requestForm.controls.workFunctionId.setValue('');
              }
            } else {
              this.requestForm.controls.workFunctionId.setValue(this.workFunctionId);
            }
          },
          error: () => {
            return this.openSnackBar(this.translate.instant('requests.form.message.error.workFunctions'), 'customSnackError');
          }
        });
      },
      error: () => {
        return this.openSnackBar(this.translate.instant('requests.form.message.error.customer'), 'customSnackError');
      }
    });
  }

  checkDeleteRequest(request: Request) {

    // Check if request's first period's start date is < new Date() so that it can still be deleted //
    const sortedPeriods = request.periods.sort((a,b) => a.startDate.getTime() - b.startDate.getTime());
    const firstStartDate = sortedPeriods[0].startDate;

    if (firstStartDate < new Date()) {
      if (this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {message: this.translate.instant('requests.form.forceDelete')}});

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.deleteRequest(request);
          }
        });
      } else {
        return this.openSnackBar('Impossible to delete a request in the past', 'customSnackError');
      }
    } else {
      this.deleteRequest(request);
    }
  }

  public getWarningMessage(): string {
    const workerId = this.requestForm.controls['workerId'].value;
    const customerId = this.requestForm.controls['customerId'].value;
    if (workerId === null || customerId === null) {
      return '';
    }
    if (typeof workerId !== 'string' && typeof customerId !== 'string') {
      const worker = this.workers.find(w => w.id === workerId);
      const customer = this.customers.find(c => c.id === customerId);
      if (worker.operatingUnitId !== customer.operatingUnitId) {
        return 'Warning, the worker and the customer does not belong to the same operating unit';
      }
    }
    return '';
  }

  onSubmit() {
    if (!this.checkRequestValidity()) {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.overlap'), 'customSnackError');
    }

    //  contractType not required to create a request, only to create contract
    this.requestForm.controls.contractType.clearValidators();
    this.requestForm.controls.contractType.updateValueAndValidity();
    //  -----------------------------------------------------------------------
    //  workerType not required to create a request, only to create contract
    this.requestForm.controls.workerType.clearValidators();
    this.requestForm.controls.workerType.updateValueAndValidity();
    //  -----------------------------------------------------------------------
    //  workerId not required to create a request, only to create contract
    this.requestForm.controls.workerId.clearValidators();
    this.requestForm.controls.workerId.updateValueAndValidity();
    //  -----------------------------------------------------------------------
    //  costCenter not required to create a request, only to create contract
    this.requestForm.controls.costCenter.clearValidators();
    this.requestForm.controls.costCenter.updateValueAndValidity();
    //  -----------------------------------------------------------------------
    //  workFunctionId not required to create a request, only to create contract
    this.requestForm.controls.workFunctionId.clearValidators();
    if (this.requestForm.controls.workFunctionId.value) {
      this.requestForm.controls.workFunctionId.setValidators(integerValidator);
    }
    this.requestForm.controls.workFunctionId.updateValueAndValidity();
    //  -----------------------------------------------------------------------

    this.createPeriodsOfRequestFromFullCalendar();

    if (this.requestForm.valid) {
      Object.assign(this.request, this.requestForm.value);
      if (this.request.workerId) {
        const worker = this.workers.find(w => w.id === this.request.workerId);
        if (worker) {
          this.request.workerLastName = worker.lastName;
          this.request.workerFirstName = worker.firstName;
        }
      }
      if (this.request.customerId) {
        this.request.customerName = this.requestForm.controls.customerAddress.value.name;
      }
      if (!this.request.operatingUnitId) {
        this.request.operatingUnitId = this.authorizationService.getCurrentUser().operatingUnitId;
      }
      this.facadeService.saveRequest(this.request).subscribe({
        next: (request: Request) => {
          this.request = request;
          this.openSnackBarWithAction(this.translate.instant('requests.form.message.success.save', {requestId: request.id}));
          return this.refresh();
        },
        error: () => {
          return this.openSnackBar(this.translate.instant('requests.form.message.error.save'), 'customSnackError');
        }
      });
    } else {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.form'), 'customSnackError');
    }
  }

  onModify() {
    this.createPeriodsOfRequestFromFullCalendar();

    if (this.requestForm.valid) {
      Object.assign(this.request, this.requestForm.value);
      this.facadeService.modifyRequest(this.request)
        .subscribe({
          next: (request: Request) => {
            Object.assign(this.request, request);
            this.requestForm.updateValueAndValidity();
            return this.openSnackBarWithAction(this.translate.instant('requests.form.message.success.modify'));
          },
          error: () => {
            return this.openSnackBar(this.translate.instant('requests.form.message.error.modify'), 'customSnackError');
          }
        });
    }
  }

  handleSelect(selectInfo: DateSelectArg) {
    const startDate_ms = new Date(selectInfo.startStr).getTime();
    const today = new Date().getTime();

    if (today > startDate_ms && !this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
      this.openSnackBar(this.translate.instant('requests.form.message.error.event.eventPast'), 'customSnackError');
      return selectInfo.view.calendar.unselect();
    }

    this.openDialogForPeriod(selectInfo.start, selectInfo.end);
  }

  handleEventClick(arg: EventClickArg) {
    const source = arg.event.source;
    if (!source || (source.id !== this.NON_AVAILABILITIES_SOURCE_ID && source.id !== this.REQUEST_SOURCE_ID)) {
      this.openDialogForPeriod(arg.event.start,
        arg.event.end,
        arg.event.extendedProps.breakDuration,
        arg.event.id,
        arg.event.extendedProps.description
      );
    }
  }

  /**
   *
   * @param newEvent : [{
   *    start: string (format : 'yyyy-MM-ddTHH:mm'),
   *    end: string ('yyyy-MM-ddTHH:mm'),
   *    title : string,
   *    workerId: number,
   *    description: string
   * }]
   * @param eventId represent identifier for each event
   */
  addEvent(newEvent: any, eventId = null): boolean {
    let overlap = false;
    this.calendarRef.getApi().getEvents().forEach(
      (calendarEvent) => {
        if (RequestFormComponent.checkOverlap(calendarEvent.start, calendarEvent.end, newEvent.start, newEvent.end)
          && eventId !== calendarEvent.id) {
          return overlap = true;
        }
      }
    );
    if (overlap) {
      return false;
    }
    if (eventId) {
      this.removeEvent(eventId);
    }
    newEvent.id = this.eventId++;
    this.calendarRef.getApi().addEvent(newEvent);
    return true;
  }

  removeEvent(eventId: any) {
    const event = this.calendarRef.getApi().getEventById(eventId);
    if (!event) {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.removeEvent'), 'customSnackError');
    }
    event.remove();
    this.requestForm.markAsDirty();
  }

  handleEventResize(arg: EventResizeDoneArg) {
    const startDate_ms = new Date(arg.event.start).getTime();
    const today = new Date().getTime();
    const endDate_ms = new Date(arg.event.end).getTime();
    const diff_ms = endDate_ms - startDate_ms;

    if (today > startDate_ms && !this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
      this.openSnackBar(this.translate.instant('requests.form.message.error.event.eventPast'), 'customSnackError');
      return arg.revert();
    }

    if (diff_ms < Constants.EVENT_MINIMUM_DURATION) {
      this.openSnackBar(this.translate.instant('requests.form.message.error.event.minimum'), 'customSnackError');
      return arg.revert();
    }

    if (diff_ms > Constants.EVENT_MAXIMUM_DURATION) {
      this.openSnackBar(this.translate.instant('requests.form.message.error.event.maximum'), 'customSnackError');
      return arg.revert();
    }

    this.requestForm.markAsDirty();
  }

  handleEventDrop(arg: EventDropArg) {
    const startDate_ms = new Date(arg.event.start).getTime();
    const today = new Date().getTime();

    if (today > startDate_ms && !this.authorizationService.hasRole(Role.SUPER_ADMIN)) {
      this.openSnackBar(this.translate.instant('requests.form.message.error.event.eventPast'), 'customSnackError');
      return arg.revert();
    }

    this.requestForm.markAsDirty();
  }

  datesSet() {
    if (this.initialized) {
      this.getNonAvailabilitiesForWorker();
      this.getRequestForWorker();
    }
  }

  setWorker() {
    this.getNonAvailabilitiesForWorker();
    this.getRequestForWorker();
  }

  // tslint:disable-next-line:max-line-length
  openDialogForPeriod(start: Date, end: Date, breakDuration: Date = null, eventId: any = null, description: string = null, error: boolean = false) {
    if (!this.requestForm.get('workerId').value || typeof this.requestForm.controls['workerId'].value === 'string') {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.event.chooseWorker'), 'customSnackError');
    }

    if (end.getTime() - start.getTime() < Constants.EVENT_MINIMUM_DURATION) {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.event.minimum'), 'customSnackError');
    }

    const dialogRef = this.dialog.open(AddEventPeriodDialogComponent, {
      height: '50%',
      width: '30%',
      data: {
        start: start,
        end: end,
        eventId: eventId,
        description: description,
        breakDuration: breakDuration,
        error: error
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      //  Result[0]: Event
      //  Result[1]: Is an edition ?
      //  Result[2]: Is a simple delete ?
      if (result && result[2]) {
        return this.removeEvent(eventId);
      }

      if (result && result[0]) {
        const period = result[0];
        const eFC = this.eventBuild(
          period.startDate,
          period.endDate,
          period.description,
          true,
          period.breakDuration
        );
        if (eFC) {
          if (!this.addEvent(eFC, (result[1]) ? eventId : null)) {
            this.openDialogForPeriod(start, end, breakDuration, eventId, description, true);
          }
        }
      }
    });
  }

  openDialogToValidate() {
    if (this.requestForm.dirty) {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.validate.dirty'), 'customSnackError');
    }

    this.createPeriodsOfRequestFromFullCalendar();

    if (this.requestForm.invalid) {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.validate.invalid'), 'customSnackError');
    }

    if (!this.checkRequestValidity()) {
      return this.openSnackBar(this.translate.instant('requests.form.message.error.overlap'), 'customSnackError');
    }

    forkJoin([
      this.facadeService.getCustomerById(this.request.customerId),
      this.facadeService.getMinimumSalaryScale(
        this.request.workerType,
        this.request.workFunctionId,
        this.request.workerId,
        this.request.customerId),
      this.facadeService.getMaxStudentQuotaHoursByYear(this.request.startDate.getFullYear())
    ]).subscribe({
      next: ([customer, salaryScale, maxQuotaHours]) => {
        const dialogRef = this.dialog.open(ValidateRequestDialogComponent, {
          data: {
            customer: customer,
            workFunctions: this.workFunctions,
            costCenters: this.costCenters,
            request: this.request,
            worker: this.workers.find(w => w.id === this.request.workerId),
            minimum: salaryScale,
            studentQuotaHoursMax: maxQuotaHours
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.goToRequestsList();
            // return this.openSnackBarWithAction(this.translate.instant('requests.form.message.success.validate.update'));
          }
        });
      },
      error: () => {
        return this.openSnackBar(this.translate.instant('requests.form.message.error.salaryScales'), 'customSnackError');
      }
    });
  }

  addNonAvailabilities() {
    this.calendarRef.getApi().addEventSource({
      id: this.NON_AVAILABILITIES_SOURCE_ID,
      events: this.nonAvailabilities
    });
  }

  addWorkerRequest() {
    this.calendarRef.getApi().addEventSource({
      id: this.REQUEST_SOURCE_ID,
      events: this.requests
    });
  }

  onBack(): void {
    this.location.back();
  }

  refresh(): void {
    this.router.navigate(['request-edit'], {queryParams: {'r': this.request.id}});
  }

  goToRequestsList(): void {
    this.router.navigate(['requests']);
    window.scrollTo(0, 0); //Move to the top of the page
  }

  private getRequestDimonas() {
    if (this.request.id) {
      this.facadeService.getDimonaByRequestId(this.request.id)
        .subscribe({
          next: (requestDimonas: Dimona[]) => {
            requestDimonas.forEach(
              rD => {
                this.periodDimona = this.periodDimona.concat(rD.dimonaPeriods);
              }
            );
          },
          error: () => {
          }
        });
    }
  }

  private _filterWorker(value: any): Worker[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.workers.filter(option => this.buildWorkerName(option).includes(filterValue));
  }

  private _filterCustomer(value: any): Customer[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.customers.filter(customer => customer.name.toLowerCase().includes(filterValue.toLowerCase()));
  }

  private _filterWorkFunction(value: any): WorkFunction[] {
    if (typeof value !== 'string') {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this.workFunctions.filter(wf => this.buildWorkFunctionName(wf).toLowerCase().includes(filterValue.toLowerCase()));
  }

  private getRequest() {
    if (!this.request) {
      this.request = new Request();
    }
    this.requestId = this.request.id;
  }

  private deleteRequest(request: Request) {
    this.facadeService.deleteRequest(request).subscribe({
      next: () => {
        this.openSnackBar(this.translate.instant('requests.form.message.success.delete'), 'customSnackValid');
        return this.onBack();
      },
      error: () => {
        return this.openSnackBar(this.translate.instant('requests.form.message.error.delete'), 'customSnackError');
      }
    });
  }

  private formBuilderRequest() {

    this.requestForm = this.formBuilder.group({
      id: [this.request.id, []],
      customerId: [
        this.request.customerId ? this.request.customerId : this.customerId ? this.customerId : null,
        [Validators.required, integerValidator]
      ],
      workerId: [
        this.request.workerId ? this.request.workerId : this.workerId ? this.workerId : null,
        [Validators.required, integerValidator]
      ],
      costCenter: [{
        value: this.request.costCenter ? this.request.costCenter : this.costCenter ? this.costCenter : null,
        disabled: !this.request.customerId
      }, []],
      workFunctionId: [{
        value: this.request.workFunctionId,
        disabled: !this.workFunctions || this.workFunctions.length === 0
      }, [Validators.required, integerValidator]],
      customerAddress: [{
        value: this.request.customerAddress,
        disabled: !this.request.customerId
      }, [Validators.required]
      ],
      startDate: [this.request.startDate, [Validators.required]],
      endDate: [this.request.endDate, [Validators.required]],
      periods: this.formBuilder.array([], [Validators.required]),
      active: [(this.request.active) ? this.request.active : true, []],
      contractType: [this.request.contractType, []],
      workerType: [this.request.workerType, []],
      flexyRate: [this.request.flexyRate, []]
    });

    this.requestForm.controls.workerId.valueChanges.subscribe(
      value => {
        if (typeof value === 'string') {
          if (this.request.status !== RequestStatus.CUSTOMER_CREATED) {
            this.calendarRef.getApi().removeAllEvents();
          }
          return;
        }
        this.request.workerType = this.workers.filter(w => w.id === value)[0].workerStatus.workerType;
        this.requestForm.controls['workerType'].setValue(this.request.workerType);
        this.setWorker();
      });
    this.requestForm.controls.customerId.valueChanges.subscribe(
      value => {
        if (typeof value === 'string') {
          this.workFunctions = [];
          this.addresses = [];
          this.resetForm();
          return;
        }
        this.setCustomer();
      });
  }

  private resetForm() {
    this.costCenters = [];
    this.requestForm.controls.costCenter.reset();
    this.requestForm.controls.costCenter.enable();
    this.requestForm.controls.workFunctionId.reset();
    this.requestForm.controls.workFunctionId.enable();
    this.requestForm.controls.customerAddress.reset();
    this.requestForm.controls.customerAddress.enable();
  }

  private formBuilderPeriodForFormBuilderRequest(period: Period): UntypedFormGroup {
    return this.formBuilder.group({
      id: [period.id, []],
      description: [period.description, []],
      startDate: [period.startDate, [Validators.required]],
      endDate: [period.endDate, [Validators.required]],
      breakDuration: [period.breakDuration, [Validators.required]],
      active: [(period.active) ? period.active : true, []]
    });
  }

  private createPeriodsOfRequestFromFullCalendar() {
    const events = this.calendarRef.getApi().getEvents();
    if (events.length > 0) {
      let startDate, endDate;
      const p = <UntypedFormArray>this.requestForm.controls['periods'];
      while (p.length > 0) {
        p.removeAt(0);
      }

      events.forEach(
        event => {
          if ([this.NON_AVAILABILITIES_SOURCE_ID, this.REQUEST_SOURCE_ID].includes(event?.source?.id)) {
            return;
          }
          const period = new Period();
          period.description = event.extendedProps.description;
          period.startDate = new Date(event.start);
          period.endDate = new Date(event.end);
          period.breakDuration = new Date(event.extendedProps.breakDuration);

          if (event.extendedProps.periodId) {
            period.id = event.extendedProps.periodId;
          }

          p.push(this.formBuilderPeriodForFormBuilderRequest(period));

          if (!startDate || (startDate > new Date(event.start))) {
            startDate = new Date(event.start);
          }
          if (!endDate || (endDate < new Date(event.end))) {
            endDate = new Date(event.end);
          }
        });

      this.requestForm.controls.endDate.setValue(endDate);
      this.requestForm.controls.startDate.setValue(startDate);
    }
  }

  private eventBuild(
    startDate: Date, endDate: Date, description: any, newEvent: boolean = true, breakDuration: Date, periodId: number = null) {
    if (newEvent) {
      const breakDuration_base = new Date(0, 0, 0, breakDuration.getHours(), breakDuration.getMinutes(), 0);
      const base = new Date(0, 0, 0, 0, 0, 0);
      const breakDuration_ms = breakDuration_base.getTime() - base.getTime();
      const startDate_ms = startDate.getTime();
      const endDate_ms = endDate.getTime();
      const diff_ms = endDate_ms - startDate_ms - breakDuration_ms;

      if (diff_ms < Constants.EVENT_MINIMUM_DURATION) {
        return this.openSnackBar(this.translate.instant('requests.form.message.error.event.minimum'), 'customSnackError');
      }

      if (diff_ms > Constants.EVENT_MAXIMUM_DURATION) {
        return this.openSnackBar(this.translate.instant('requests.form.message.error.event.maximum'), 'customSnackError');
      }

      this.requestForm.markAsDirty();
    }

    let title = '';

    if (description && description.trim().length > 0) {
      title += ((title.length === 0) ? '' : '\n-\n') + description;
    }

    return {
      start: startDate,
      end: endDate,
      title: title,
      description: description,
      periodId: periodId,
      breakDuration: breakDuration
    };
  }

  private getNonAvailabilitiesForWorker() {
    if (this.calendarRef) {
      const workerId = this.requestForm.controls['workerId'].value;
      if (workerId) {
        const startDate = this.calendarRef.getApi().view.activeStart;
        const endDate = this.calendarRef.getApi().view.activeEnd;
        this.facadeService.getNonAvailabilitiesFilter(workerId, startDate, endDate)
          .subscribe({
            next: (nonAvailabilities: NonAvailability[]) => {
              this.nonAvailabilities = [];
              const sources = this.calendarRef.getApi().getEventSourceById(this.NON_AVAILABILITIES_SOURCE_ID);
              if (sources) {
                sources.remove();
              }
              nonAvailabilities.forEach(
                nonAvailability => {
                  const event = NonAvailability.buildEvent(nonAvailability);
                  event['editable'] = false;
                  event['overlap'] = false;
                  this.nonAvailabilities.push(event);
                }
              );
              this.addNonAvailabilities();
            },
            error: () => {
              return this.openSnackBar(this.translate.instant('requests.form.message.error.nonAvailabilities'), 'customSnackError');
            }
          });
      }
    }
  }

  private getRequestForWorker() {
    if (this.calendarRef) {
      const workerId = this.requestForm.controls['workerId'].value;
      if (workerId) {
        const startDate = this.calendarRef.getApi().view.activeStart;
        const endDate = this.calendarRef.getApi().view.activeEnd;
        this.facadeService.filterPrestation(startDate, endDate, workerId).subscribe({
          next: (prestations: Prestation[]) => {
            this.requests = [];
            if (!this.calendarRef.getApi()) {
              return;
            }
            const sources = this.calendarRef.getApi().getEventSourceById(this.REQUEST_SOURCE_ID);
            if (sources) {
              sources.remove();
            }
            prestations.forEach(
              prestation => {
                prestation.periods.forEach(
                  prestationPeriod => {
                    if (prestation.requestId !== this.request.id) {
                      this.requests.push(RequestFormComponent.buildRequestsEvent(prestationPeriod, prestation));
                    }
                  }
                );
              }
            );
            this.addWorkerRequest();
          },
          error: () => {
            return this.openSnackBar(this.translate.instant('requests.form.message.error.nonAvailabilities'), 'customSnackError');
          }
        });
      }
    }
  }

  private checkRequestValidity() {
    let isValid = true;
    const events = this.calendarRef.getApi().getEvents();
    events.sort((a, b) => a.start.getTime() - b.start.getTime());
    for (let i = 0; i < events.length - 1; i++) {
      const eventSourceI = events[i].source;
      const eventSourceIAfter = events[i + 1].source;
      if (!([this.NON_AVAILABILITIES_SOURCE_ID, this.REQUEST_SOURCE_ID].includes(eventSourceI?.id)
        && ([this.NON_AVAILABILITIES_SOURCE_ID, this.REQUEST_SOURCE_ID].includes(eventSourceIAfter?.id)))) {
        if (RequestFormComponent.checkOverlap(events[i].start, events[i].end, events[i + 1].start, events[i + 1].end)) {
          return isValid = false;
        }
      }
    }
    return isValid;
  }

  private openSnackBarWithAction(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, this.translate.instant('requests.form.message.success.goToRequest'), {
      duration: 10000,
      panelClass: [pC]
    }).onAction().subscribe(() => {
      return this.router.navigate(['request-edit'], {queryParams: {'r': this.request.id}});
    });
  }

  private openSnackBar(message: string, pC: string = 'customSnackValid') {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      panelClass: [pC]
    });
  }

  private handleEventMouseEnter(arg: EventHoveringArg) {
    const el = arg.el;
    const periodId = arg.event.extendedProps.periodId;
    if (periodId) {
      const ref = this.periodDimona.find(pD => pD.requestPeriodId === periodId);
      if (ref && ref.reference) {
        el.setAttribute('title', ref.reference);
      }
    }
  }
}
