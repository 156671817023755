import { JointCommissionCoefficientSerializerAdmin } from './../../../../admin/model/joint-commission-coefficient.serializer';
import {Serializer} from '../../serializer';
import { JointCommissionCoefficientPage } from './joint-commision-coefficient-page.model';

export class JointCommissionCoefficientPageSerializer implements Serializer {

  private jointCommissionCoefficientSerializer = new JointCommissionCoefficientSerializerAdmin();

  fromJson(json: any): JointCommissionCoefficientPage {
    const jointCommissionCoefficientPage = new JointCommissionCoefficientPage();

    json.content.forEach(
      content => {
        jointCommissionCoefficientPage.content.push(this.jointCommissionCoefficientSerializer.fromJson(content));
      }
    );

    jointCommissionCoefficientPage.totalElements = json.totalElements;
    jointCommissionCoefficientPage.totalPages = json.totalPages;
    return jointCommissionCoefficientPage;
  }

  toJson(resource: Object): any {
  }
}

