import { Serializer } from "../../shared/model/serializer";
import { JointCommissionCoefficientAdmin } from "./joint-commission-coefficient.model";

export class JointCommissionCoefficientSerializerAdmin implements Serializer {

  fromJson(json: any): JointCommissionCoefficientAdmin {
    const jointCommissionCoefficient: JointCommissionCoefficientAdmin = new JointCommissionCoefficientAdmin();

    jointCommissionCoefficient.id = json.id;
    jointCommissionCoefficient.active = json.active ? json.active : false;
    jointCommissionCoefficient.jointCommissionCode = json.joinCommissionCode ? json.joinCommissionCode : "-";
    jointCommissionCoefficient.nightShiftWorkType = json.nightShiftWorkType ? json.nightShiftWorkType : "-";
    jointCommissionCoefficient.clothesBillingCoefficient = json.paymentTerms?.clothesBillingCoefficient ? json.paymentTerms.clothesBillingCoefficient : 0;
    jointCommissionCoefficient.coefficientClothes = json.paymentTerms?.coefficientClothes ? json.paymentTerms.coefficientClothes : 0;
    jointCommissionCoefficient.coefficientEcoCheques = json.paymentTerms?.coefficientEcocheques ? json.paymentTerms.coefficientEcocheques : 0;
    jointCommissionCoefficient.coefficientPension = json.paymentTerms?.coefficientPension ? json.paymentTerms.coefficientPension : 0;
    jointCommissionCoefficient.coefficientPurchasing = json.paymentTerms?.coefficientPurchasing ? json.paymentTerms.coefficientPurchasing : 0;
    jointCommissionCoefficient.dimonaBillingCoefficient = json.paymentTerms?.dimonaBillingCoefficient ? json.paymentTerms.dimonaBillingCoefficient : 0;
    jointCommissionCoefficient.dimonaFee = json.paymentTerms?.dimonaFee ? json.paymentTerms.dimonaFee : 0;
    jointCommissionCoefficient.ecoChequeBillingCoefficient = json.paymentTerms?.ecochequesBillingCoefficient ? json.paymentTerms.ecochequesBillingCoefficient : 0;
    jointCommissionCoefficient.flexyRate = json.paymentTerms?.flexyRate ? json.paymentTerms.flexyRate : 0;
    jointCommissionCoefficient.mealEmployer = json.paymentTerms?.mealEmployer ? json.paymentTerms.mealEmployer : 0;
    jointCommissionCoefficient.mealFacial = json.paymentTerms?.mealFacial ? json.paymentTerms.mealFacial : 0;
    jointCommissionCoefficient.mealVoucher = json.paymentTerms?.mealVoucher ? json.paymentTerms.mealVoucher : 0;
    jointCommissionCoefficient.pensionBillingCoefficient = json.paymentTerms?.pensionBillingCoefficient ? json.paymentTerms.pensionBillingCoefficient : 0;
    jointCommissionCoefficient.purchasingBillingCoefficient = json.paymentTerms?.purchasingBillingCoefficient ? json.paymentTerms.purchasingBillingCoefficient : 0;
    jointCommissionCoefficient.travelExpenseBillingCoefficient = json.paymentTerms?.travelExpenseBillingCoefficient ? json.paymentTerms.travelExpenseBillingCoefficient : 0;
    jointCommissionCoefficient.travelExpenses = json.paymentTerms?.travelExpenses ? json.paymentTerms.travelExpenses : 0;
    jointCommissionCoefficient.riskedActivity = json.riskedActivity ? json.riskedActivity : false;
    jointCommissionCoefficient.rtt = json.rtt ? json.rtt : false;
    jointCommissionCoefficient.whCutSchedule = json.uncomfortableWorkingHours?.cutSchedule ? json.uncomfortableWorkingHours.cutSchedule : 0;
    jointCommissionCoefficient.whLate = json.uncomfortableWorkingHours?.late ? json.uncomfortableWorkingHours.late : 0;
    jointCommissionCoefficient.whNight = json.uncomfortableWorkingHours?.night ? json.uncomfortableWorkingHours.night : 0;
    jointCommissionCoefficient.whPublicHoliday = json.uncomfortableWorkingHours?.publicHoliday ? json.uncomfortableWorkingHours.publicHoliday : 0;
    jointCommissionCoefficient.whSaturday = json.uncomfortableWorkingHours?.saturday ? json.uncomfortableWorkingHours.saturday : 0;
    jointCommissionCoefficient.whSunday = json.uncomfortableWorkingHours?.sunday ? json.uncomfortableWorkingHours.sunday : 0;

    return jointCommissionCoefficient;
  }

  toJson(jointCommissionCoefficient: JointCommissionCoefficientAdmin): any {
    return {
      id: jointCommissionCoefficient.id,
      active: jointCommissionCoefficient.active,
      joinCommissionCode: jointCommissionCoefficient.jointCommissionCode,
      nightShiftWorkType: jointCommissionCoefficient.nightShiftWorkType,
      paymentTerms: {
        clothesBillingCoefficient: jointCommissionCoefficient.clothesBillingCoefficient,
        coefficientClothes: jointCommissionCoefficient.coefficientClothes,
        coefficientEcocheques: jointCommissionCoefficient.coefficientEcoCheques,
        coefficientPension: jointCommissionCoefficient.coefficientPension,
        coefficientPurchasing: jointCommissionCoefficient.coefficientPurchasing,
        dimonaBillingCoefficient: jointCommissionCoefficient.dimonaBillingCoefficient,
        dimonaFee: jointCommissionCoefficient.dimonaFee,
        ecochequesBillingCoefficient: jointCommissionCoefficient.ecoChequeBillingCoefficient,
        flexyRate: jointCommissionCoefficient.flexyRate,
        mealEmployer: jointCommissionCoefficient.mealEmployer,
        mealFacial: jointCommissionCoefficient.mealFacial,
        mealVoucher: jointCommissionCoefficient.mealVoucher,
        pensionBillingCoefficient: jointCommissionCoefficient.pensionBillingCoefficient,
        purchasingBillingCoefficient: jointCommissionCoefficient.purchasingBillingCoefficient,
        travelExpenseBillingCoefficient: jointCommissionCoefficient.travelExpenseBillingCoefficient,
        travelExpenses: jointCommissionCoefficient.travelExpenses
      },
      riskedActivity: jointCommissionCoefficient.riskedActivity,
      rtt: jointCommissionCoefficient.rtt,
      uncomfortableWorkingHours: {
        cutSchedule: jointCommissionCoefficient.whCutSchedule,
        late: jointCommissionCoefficient.whLate,
        night: jointCommissionCoefficient.whNight,
        publicHoliday: jointCommissionCoefficient.whPublicHoliday,
        saturday: jointCommissionCoefficient.whSaturday,
        sunday: jointCommissionCoefficient.whSunday
      },
    };
  }

}
