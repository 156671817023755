import { SalaryScale } from './salary-scale.model';
import {Serializer} from '../../shared/model/serializer';

export class SalaryScaleSerializer implements Serializer {

  fromJson(json: any): SalaryScale {
    const salaryScale: SalaryScale = new SalaryScale();

    salaryScale.id = json.id;
    salaryScale.active = json.active;
    salaryScale.jointCommissionCode = json.jointCommissionCode;
    salaryScale.category = json.category;
    salaryScale.seniority = json.seniority;
    salaryScale.value = json.value;

    return salaryScale;
  }

  toJson(salaryScale: SalaryScale): any {
    return {
      id: salaryScale.id,
      active: salaryScale.active,
      jointCommissionCode: salaryScale.jointCommissionCode,
      category: salaryScale.category,
      seniority: salaryScale.seniority,
      value: salaryScale.value,
    };
  }

}
