import {Component, Inject, OnInit} from '@angular/core';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Contract} from '../../shared/model/contract.model';
import {Request} from '../../../requests/shared/model/request.model';
import {Role} from '../../../users/shared/model/user.model';
import {AuthorizationService} from '../../../login/shared/service/authorization.service';

@Component({
  selector: 'app-copy-request-dialog',
  templateUrl: './copy-request-dialog.component.html',
  styleUrls: ['./copy-request-dialog.component.css']
})
export class CopyRequestDialogComponent implements OnInit {
  contract: Contract;

  constructor(private facadeService: FacadeService,
              private authorizationService: AuthorizationService,
              private dialogRef: MatDialogRef<CopyRequestDialogComponent>,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contract = this.data.contract;
  }

  ngOnInit(): void {
  }

  submit(eventForm: NgForm) {
    if (eventForm.invalid) {
      return this.openSnackBar(this.translate.instant('contracts.view.copyDialog.message.error.invalid'));
    }

    const startDate = new Date(new Date(eventForm.value.startDate).setHours(0, 0, 0));
    const actualDate = new Date(new Date().setHours(0, 0, 0));

    if ((actualDate.getTime() > startDate.getTime()) && !this.hasAdminRole()) {
      return this.openSnackBar(this.translate.instant('contracts.view.copyDialog.message.error.past'));
    }

    const request = new Request;
    request.startDate = startDate;
    request.id = this.contract.request.id;

    this.facadeService.copyRequestWithNewDate(request)
      .subscribe({
        next: (newRequest: Request) => {
          this.closeDialog(newRequest);
        },
        error: () => {
          this.closeDialog();
          return this.openSnackBar(this.translate.instant('contracts.view.copyDialog.message.error.copy'));
        }
      });
  }


  hasAdminRole() {
    return this.authorizationService.hasRole(Role.COMEXIS_SUPER_ADMIN);
  }

  closeDialog(request = null) {
    this.dialogRef.close(request);
  }

  openSnackBar(message: string, pC: string = 'customSnackError', action: string = 'X') {
    this.snackBar.open(message, action, {
      duration: 10000,
      panelClass: [pC]
    });
  }
}
