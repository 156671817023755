import {LanguageCode} from '../../../shared/enum/language-code.enum';
import {OperatingUnit} from '../../../operating-units/shared/model/operating-unit.model';

export enum Role {
  CONSULTANT = 'CONSULTANT',
  SUPERVISOR = 'SUPERVISOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
  COMEXIS_SUPER_ADMIN = 'COMEXIS_SUPER_ADMIN',
  CUSTOMER_PLANNER = 'CUSTOMER_PLANNER',
  CUSTOMER_VALIDATOR = 'CUSTOMER_VALIDATOR',
  WORKER = 'WORKER'
}

export enum AccountType {
  CONSULTANT = 'CONSULTANT',
  CUSTOMER = 'CUSTOMER',
  WORKER = 'WORKER'
}

export enum AccountStatus {
  NOT_CREATED = 'NOT_CREATED',
  CREATED = 'CREATED',
  SECURED = 'SECURED'
}

export class User {
  id: number;
  username: string;
  languageCode: LanguageCode;
  firstName: string;
  lastName: string;
  password: string;
  operatingUnitId: number;
  role: Role;
  accountType: AccountType;
  operatingUnit?: OperatingUnit;
  workerId: number;
  customerId: number;
}
