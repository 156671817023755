import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {PlanningDtoSerializer} from '../model/dto/planning-dto.serializer';
import {PlanningDto} from '../model/dto/planning-dto.model';

@Injectable({
  providedIn: 'root'
})
export class PlanningService {
  private serviceURL = environment.request_service_URL + 'requests';
  private dateSerializer: DateSerializer = new DateSerializer();
  private planningSerializer: PlanningDtoSerializer = new PlanningDtoSerializer();

  constructor(private http: HttpClient) {
  }

  getByStartDateAndEndDate(customerIds: number[], operatingUnitIds: number[], startDate: Date, endDate: Date): Observable<any> {
    let params = new HttpParams();
    params = params.append('startDate', this.dateSerializer.toJson(startDate));
    params = params.append('endDate', this.dateSerializer.toJson(endDate));
    if (customerIds && customerIds.length > 0) {
      params = params.append('customerIds', String(customerIds));
    }
    if (operatingUnitIds && operatingUnitIds.length > 0) {
      params = params.append('operatingUnitIds', String(operatingUnitIds));
    }
    return this.http.get<PlanningDto[]>(this.serviceURL + '/planning', {params}).pipe(
      map((data) => this.planningSerializer.fromJson(data))
    );
  }
}
