import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-export-contracts',
  templateUrl: './export-contracts.component.html',
  styleUrls: ['./export-contracts.component.css']
})
export class ExportContractsComponent implements OnInit {

  @Input() form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit(): void {
    this.form.addControl('contractStartDate', new UntypedFormControl());
    this.form.addControl('contractEndDate', new UntypedFormControl());
    this.form.addControl('workerId', new UntypedFormControl());
    this.form.addControl('customerId', new UntypedFormControl());
  }

  clearStartDateContract() {
    this.form.controls.contractStartDate.setValue('');
  }

  clearEndDateContract() {
    this.form.controls.contractEndDate.setValue('');
  }


}
