import {Component, OnInit} from '@angular/core';
import {AuthorizationService} from './login/shared/service/authorization.service';
import {Role, User} from './users/shared/model/user.model';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {ExportDialogComponent, ExportDialogParam} from './shared/component/export-dialog/export-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FacadeService} from './shared/service/facade/facade.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  isLoggedIn: boolean;
  currentUser: User;
  appVersion = environment.version;

  constructor(private translate: TranslateService,
              private authService: AuthorizationService,
              public router: Router,
              private dialog: MatDialog,
              private facadeService: FacadeService,
              private snackBar: MatSnackBar) {
    const nav = window.navigator;
    translate.setDefaultLang(nav.language.split('-')[0].toLowerCase());
  }

  get Role() {
    return Role;
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.loggedIn();
    if (this.isLoggedIn) {
      this.currentUser = this.authService.getCurrentUser();
      this.translate.setDefaultLang(this.currentUser.languageCode.toLowerCase());
      this.useLanguage(this.currentUser.languageCode.toLowerCase());
    }
  }

  hasRole(role: Role) {
    return this.authService.hasRole(role);
  }

  onLogout() {
    this.authService.logout();
  }

  getLanguage() {
    return this.translate.currentLang.toUpperCase();
  }

  useLanguage(language: string) {
    this.translate.use(language.toLowerCase());
  }

  exportSuccessiveContracts() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.genericExport = true;
    param.successiveContractsExport = true;
    param.showOperatingUnit = false;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          param.saveFile(
            this.facadeService.getSuccessiveContractsToExport(
              result.contractStartDate,
              result.contractEndDate)
          );
        }
      }
    );
  }

  exportActivityReport() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.genericExport = true;
    param.activityReportExport = true;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          param.saveFile(
            this.facadeService.getActivityReportToExport(
              result.contractStartDate,
              result.contractEndDate,
              result.operatingUnitIds)
          );
        }
      }
    );
  }

  exportDutyReport() {
    const param = new ExportDialogParam(this.snackBar, this.translate);
    param.genericExport = true;
    param.dutyReport = true;
    param.showOperatingUnit = false;
    const dialogRef = this.dialog.open(ExportDialogComponent, {data: param});
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          param.saveFile(
            this.facadeService.getInfoDutyReportToExport(
              result.contractStartDate,
              result.contractEndDate)
          );
        }
      }
    );
  }

}
