import {Component, Inject} from '@angular/core';
import {FacadeService} from '../../../shared/service/facade/facade.service';
import {NgForm} from '@angular/forms';
import {Document, TypeFileCustomer, TypeFileWorker} from '../../../shared/model/document/document.model';
import {AuthorizationService} from '../../../login/shared/service/authorization.service';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-processed-document-dialog',
  templateUrl: './processed-document-dialog.component.html',
  styleUrls: ['./processed-document-dialog.component.css']
})
export class ProcessedDocumentDialogComponent {
  typesFile = [];
  isPending = false;
  isMedicalAttestation = false;
  oldFileType: TypeFileWorker | TypeFileCustomer;
  oldStartDate: Date;
  oldEndDate: Date;
  isSaveButtonUnlocked = true;
  isStartDateSame = true;
  isEndDateSame = true;

  constructor(private dialogRef: MatDialogRef<ProcessedDocumentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private authorizationService: AuthorizationService,
              private datePipe: DatePipe,
              private facadeService: FacadeService,
              private translate: TranslateService) {
    if (data.isPending) {
      this.isPending = true;
    } else {
      this.oldFileType = data.document.typeFile;
      this.isSaveButtonUnlocked = false;
    }
    if (data.worker) {
      this.isMedicalAttestation = (this.data.document.typeFile === TypeFileWorker.MEDICAL_ATTESTATION);
      if (this.isMedicalAttestation) {
        this.oldStartDate = this.data.document.startDate;
        this.oldEndDate = this.data.document.endDate;
      }
      Object.keys(TypeFileWorker).forEach(e => {
        if (e !== TypeFileWorker.PENDING) {
          this.typesFile.push(e);
        }
      });
    }
    if (data.customer) {
      Object.keys(TypeFileCustomer).forEach(e => {
        this.typesFile.push(e);
      });
    }
  }

  selectionChangeTypeFile(event: any) {
    this.isMedicalAttestation = (event.value === TypeFileWorker.MEDICAL_ATTESTATION);
    this.isSaveButtonUnlocked = !(event.value === this.oldFileType && this.oldFileType !== TypeFileWorker.PENDING);
  }

  processDocument(form: NgForm) {
    if (form.valid) {
      const originalDoc = <Document>this.data.document;
      const doc = new Document();
      const username = this.authorizationService.getCurrentUser().username;
      doc.fileName = originalDoc.fileName;
      doc.consultant = username;
      doc.typeFile = form.value.typeFile;
      doc.comment = originalDoc.comment;
      const startDate = typeof form.value.startDate === 'string' ? this.dateFromString(form.value.startDate) : form.value.startDate;
      const endDate = typeof form.value.endDate === 'string' ? this.dateFromString(form.value.endDate) : form.value.endDate;
      if (startDate && endDate) {
        if (startDate.getTime() > endDate.getTime()) {
          return alert(this.translate.instant('workers.view.documents.processed.dialog.message.error.process.date'));
        }
        doc.endDate = endDate;
        doc.startDate = startDate;
      }
      if (doc.typeFile !== TypeFileWorker.MEDICAL_ATTESTATION) {
        doc.startDate = null;
        doc.endDate = null;
      }
      if (this.isPending) {
        doc.comment += `\n[${username} | ${this.datePipe.transform(new Date(), 'dd/MM/yyyy - HH:mm')}] : ${form.value.comment}`;
        this.facadeService.processDocumentByWorkerId(this.data.worker.id, doc)
          .subscribe({
            next:
              ignoreProperty => {
                this.closeDialog(true);
              },
            error: ignoreProperty => {
              return alert(this.translate.instant('workers.view.documents.processed.dialog.message.error.process.save'));
            }
          });
      } else {
        if (this.data.worker) {
          this.editWorkerDocument(originalDoc, doc);
        } else {
          this.editCustomerDocument(originalDoc, doc);
        }
      }
    }
  }

  checkStartDate(event: string) {

    this.isStartDateSame = this.isSameDate(event, this.data.document.startDate);
    this.isSaveButtonUnlocked = !(this.isStartDateSame
      && !this.isPending
      && this.isEndDateSame);
  }

  checkEndDate(event: string) {
    this.isEndDateSame = this.isSameDate(event, this.data.document.endDate);
    this.isSaveButtonUnlocked = !(this.isEndDateSame
      && !this.isPending
      && this.isStartDateSame);
  }

  closeDialog(update = false): void {
    this.dialogRef.close(update);
  }

  getFormattedComment(comment: string) {
    return comment.replace(/\n/g, '<br>');
  }

  isSameDate(dateString: String, date2: Date): boolean {
    if (!date2) {
      return false;
    }
    const date = this.dateFromString(dateString);
    date.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);
    return date.getTime() === date2.getTime();
  }

  dateFromString(dateString: String): Date {
    const dates = dateString.split('/');
    const date = new Date();
    date.setFullYear(+dates[2], +dates[1] - 1, +dates[0]);
    return date;
  }

  private editWorkerDocument(originalDoc: Document, doc: Document) {
    this.facadeService.editWorkerDocumentFileType(this.data.worker.id, originalDoc.typeFile, doc)
      .subscribe({
        next:
          ignoreProperty => {
            this.closeDialog(true);
          },
        error: ignoreProperty => {
          return alert(this.translate.instant('workers.view.documents.processed.dialog.message.error.process.save'));
        }

      });
  }

  private editCustomerDocument(originalDoc: Document, doc: Document) {
    this.facadeService.editCustomerDocumentFileType(this.data.customer.id, originalDoc.typeFile, doc)
      .subscribe({
        next:
          ignoreProperty => {
            this.closeDialog(true);
          },
        error: ignoreProperty => {
          return alert(this.translate.instant('workers.view.documents.processed.dialog.message.error.process.save'));
        }

      });
  }
}
