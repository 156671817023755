import { JointCommissionCoefficientAdmin } from './../../../admin/model/joint-commission-coefficient.model';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JointCommission} from '../../model/joint-commission/joint-commission.model';
import {JointCommissionSerializer} from '../../model/joint-commission/joint-commission.serializer';
import {map} from 'rxjs/operators';
import {convertSingleWorkFunction, convertWorkFunction, WorkFunction} from '../../model/work-function/work-function.model';
import {NaceCodeSerializer} from '../../model/nace-code/nace-code.serializer';
import {convertNaceCode, NaceCode} from '../../model/nace-code/nace-code.model';
import {BillingCoefficients, CustomerSize} from '../../../customers/shared/model/customer.model';
import {BillingCoefficientsPage} from '../../model/pagination/billing-coefficient/billing-coefficient-page.model';
import {BillingCoefficientSerializer} from '../../../customers/shared/model/billing-coefficient.serializer';
import {BillingCoefficientSerializerPage} from '../../model/pagination/billing-coefficient/billing-coefficient-page.serializer';
import {JointCommissionCoefficientSerializer} from '../../model/joint-commission/joint-commission-coefficient.serializer';
import {JointCommissionCoefficient} from '../../model/joint-commission/joint-commission-coefficient.model';
import {CountrySerializer} from '../../model/country/country.serializer';
import {convertCountries, Country} from '../../model/country/country.model';
import {LanguageSerializer} from '../../model/language/language.serializer';
import {Language} from '../../model/language/language.model';
import {CorrectionTypeSerializer} from '../../model/correction-type/correction-type.serializer';
import {CorrectionType} from '../../model/correction-type/correction-type.model';
import {PrestationCodeSerializer} from '../../model/prestation-code/prestation-code.serializer';
import {PrestationCode} from '../../model/prestation-code/prestation-code.model';
import {SectorSerializer} from '../../model/sector/sector.serializer';
import {Sector} from '../../model/sector/sector.model';
import {CompanyPrefixSerializer} from '../../model/company-prefix/company-prefix.serializer';
import {CompanyPrefix, convertCompanyPrefix} from '../../model/company-prefix/company-prefix.model';
import {Bic} from '../../model/bic/bic.model';
import {BicSerializer} from '../../model/bic/bic.serializer';
import {TranslateService} from '@ngx-translate/core';
import {DimonaCodeNotisSerializer} from '../../model/dimona-code-notis/dimona-code-notis.serializer';
import {DimonaCodeNotis} from '../../model/dimona-code-notis/dimona-code-notis.model';
import {WorkerPositionSerializer} from '../../worker-position/worker-position.serializer';
import {convertWorkerPosition, WorkerPosition} from '../../worker-position/worker-position.model';
import { PublicHolidayPageSerializer } from '../../model/pagination/public-holiday/public-holiday-page.serializer';
import { PublicHolidayPage } from '../../model/pagination/public-holiday/public-holiday-page.model';
import { TravelExpenseCoefficientPageSerializer } from '../../model/pagination/travel-expense-coefficient/travel-expense-coefficient-page.serializer';
import { TravelExpenseCoefficientPage } from '../../model/pagination/travel-expense-coefficient/travel-expense-coefficient-page.model';
import { PublicHoliday } from 'src/app/admin/model/public-holiday.model';
import { PublicHolidaySerializer } from 'src/app/admin/model/public-holiday.serializer';
import { StudentQuotaHours } from 'src/app/admin/model/student-quota-hours.model';
import { SalaryScale } from 'src/app/admin/model/salary-scale.model';
import { StudentQuotaHoursPageSerializer } from '../../model/pagination/student-quota-hour/student-quota-hours-page.serializer';
import { StudentQuotaHoursSerializer } from 'src/app/admin/model/student-quota-hours.serializer';
import { SalaryScaleSerializer } from 'src/app/admin/model/salary-scale.serializer';
import { SalaryScalePageSerializer } from '../../model/pagination/salary-scale/salary-scale-page.serializer';
import { StudentQuotaHoursPage } from '../../model/pagination/student-quota-hour/student-quota-hours-page.model';
import { SalaryScalePage } from '../../model/pagination/salary-scale/salary-scale-page.model';
import { TravelExpenseCoefficient } from 'src/app/admin/model/travel-expense-coefficient.model';
import { TravelExpenseCoefficientSerializer } from 'src/app/admin/model/travel-expense-coefficient.serializer';
import { JointCommissionCoefficientSerializerAdmin } from './../../../admin/model/joint-commission-coefficient.serializer';
import { JointCommissionCoefficientPageSerializer } from '../../model/pagination/joint-commision-coefficient/joint-commission-coefficient-page.serializer';
import { WorkFunctionPageSerializer } from '../../model/pagination/work-function/work-function-page.serializer';
import { JointCommissionCoefficientPage } from '../../model/pagination/joint-commision-coefficient/joint-commision-coefficient-page.model';
import { WorkFunctionPage } from '../../model/pagination/work-function/work-function-page.model';
import { WorkFunctionSerializer } from '../../model/work-function/work-function.serializer';
import { JointCommissionPageSerializer } from '../../model/pagination/joint-commission/joint-commission-page.serializer';
import { JointCommissionPage } from '../../model/pagination/joint-commission/joint-commission-page.model';
import { PrestationCodePage } from '../../model/pagination/prestation-code/prestation-code-page.model';
import { PrestationCodePageSerializer } from '../../model/pagination/prestation-code/prestation-code-page.serializer';


@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  private serviceURL = environment.reference_data_service_URL;
  private jointCommissionSerializer: JointCommissionSerializer = new JointCommissionSerializer();
  private workFunctionSerializer: WorkFunctionSerializer = new WorkFunctionSerializer();
  private workFunctionPageSerializer: WorkFunctionPageSerializer = new WorkFunctionPageSerializer();
  private workerPositionSerializer: WorkerPositionSerializer = new WorkerPositionSerializer();
  private naceCodeSerializer: NaceCodeSerializer = new NaceCodeSerializer();
  private billingCoefficientSerializer: BillingCoefficientSerializer = new BillingCoefficientSerializer();
  private billingCoefficientSerializerPage: BillingCoefficientSerializerPage = new BillingCoefficientSerializerPage();
  private jointCommissionCoefficientSerializer: JointCommissionCoefficientSerializer = new JointCommissionCoefficientSerializer();
  private countrySerializer: CountrySerializer = new CountrySerializer();
  private languageSerializer: LanguageSerializer = new LanguageSerializer();
  private correctionTypeSerializer: CorrectionTypeSerializer = new CorrectionTypeSerializer();
  private prestationCodeSerializer: PrestationCodeSerializer = new PrestationCodeSerializer();
  private sectorSerializer: SectorSerializer = new SectorSerializer();
  private companyPrefixSerializer: CompanyPrefixSerializer = new CompanyPrefixSerializer();
  private bicSerializer: BicSerializer = new BicSerializer();
  private dimonaCodeNotisSerializer: DimonaCodeNotisSerializer = new DimonaCodeNotisSerializer();
  private publicHolidaySerializer: PublicHolidaySerializer = new PublicHolidaySerializer();
  private publicHolidayPageSerializer: PublicHolidayPageSerializer = new PublicHolidayPageSerializer();
  private studentQuotaHoursSerializer: StudentQuotaHoursSerializer = new StudentQuotaHoursSerializer();
  private studentQuotaHoursPageSerializer: StudentQuotaHoursPageSerializer = new StudentQuotaHoursPageSerializer();
  private salaryScaleSerializer: SalaryScaleSerializer = new SalaryScaleSerializer();
  private salaryScalePageSerializer: SalaryScalePageSerializer = new SalaryScalePageSerializer();
  private travelExpenseCoefficientSerializer: TravelExpenseCoefficientSerializer = new TravelExpenseCoefficientSerializer();
  private travelExpenseCoefficientPageSerializer: TravelExpenseCoefficientPageSerializer = new TravelExpenseCoefficientPageSerializer();
  private jointCommissionCoefficientSerializerAdmin: JointCommissionCoefficientSerializerAdmin = new JointCommissionCoefficientSerializerAdmin();
  private jointCommissionCoefficientPageSerializer: JointCommissionCoefficientPageSerializer = new JointCommissionCoefficientPageSerializer();
  private jointCommissionPageSerializer: JointCommissionPageSerializer = new JointCommissionPageSerializer();
  private prestationCodePageSerializer: PrestationCodePageSerializer = new PrestationCodePageSerializer();


  constructor(private http: HttpClient,
              private translate: TranslateService) {
  }

  // JointCommission
  getAllJointCommissions(): Observable<any> {
    return this.http.get<JointCommission[]>(this.serviceURL + 'joint-commissions').pipe(
      map((data) => this.convertDataToJointCommissions(data))
    );
  }

  getJointCommissionsBySectorId(sectorId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('sectorId', String(sectorId));
    return this.http.get<JointCommission[]>(this.serviceURL + 'joint-commissions/filter', {params}).pipe(
      map((data) => this.convertDataToJointCommissions(data))
    );
  }

  getJointCommissionsById(id: number): Observable<any> {
    let params = new HttpParams();
    return this.http.get<JointCommission>(this.serviceURL + 'joint-commissions/' + id).pipe(
      map((data) => this.jointCommissionSerializer.fromJson(data))
    );
  }

  getPageJointCommission(index: number, size: number): Observable<any> {
      let params = new HttpParams();
      params = params.append('index', String(index));
      params = params.append('size', String(size));
      return this.http.get<JointCommissionPage>(this.serviceURL + 'joint-commissions/page', {params}).pipe(
        map((data: JointCommissionPage) => this.jointCommissionPageSerializer.fromJson(data))
      );
    }

  saveJointCommission(jointCommission: JointCommission): Observable<any> {
    return this.http.post<JointCommission>(this.serviceURL + 'joint-commissions', this.jointCommissionSerializer.toJson(jointCommission)).pipe(
      map((data) => this.jointCommissionSerializer.fromJson(data))
    );
  }

  // jointCommissionCoefficient
  getJointCommissionCoefficientsByJointCommissionCode(jointCommissionCode: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('code', jointCommissionCode);
    const url = this.serviceURL + 'joint-commission-coefficients/joint-commission';
    return this.http.get<JointCommissionCoefficient[]>(url, {params}).pipe(
      map((data) => this.jointCommissionCoefficientSerializer.fromJson(data))
    );
  }

  getPageJointCommissionCoefficients(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<JointCommissionCoefficientPage>(this.serviceURL + 'joint-commission-coefficients/page', {params}).pipe(
      map((data: JointCommissionCoefficientPage) => this.jointCommissionCoefficientPageSerializer.fromJson(data))
    );
  }

  saveJointCommissionCoefficients(jointCommissionCoefficient: JointCommissionCoefficientAdmin): Observable<any> {
    return this.http.post<JointCommissionCoefficientAdmin>(this.serviceURL + 'joint-commission-coefficients', this.jointCommissionCoefficientSerializerAdmin.toJson(jointCommissionCoefficient)).pipe(
      map((data) => this.jointCommissionCoefficientSerializerAdmin.fromJson(data))
    );
  }

  // workFunction
  getWorkFunctionById(workFunctionId: number): Observable<any> {
    return this.http.get<WorkFunction>(this.serviceURL + 'work-functions/' + workFunctionId).pipe(
      map((data) => convertSingleWorkFunction(this.workFunctionSerializer.fromJson(data), this.translate)
      ));
  }

  listWorkFunctionsByJointCommissionCode(jointCommissionCode: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('jointCommissionCode', jointCommissionCode);
    return this.http.get<WorkFunction[]>(this.serviceURL + 'work-functions/filter', {params}).pipe(
      map((data) => this.convertDataToWorkFunctions(data))
    );
  }

  getPageWorkFunction(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<WorkFunctionPage>(this.serviceURL + 'work-functions/page', {params}).pipe(
      map((data: WorkFunctionPage) => this.workFunctionPageSerializer.fromJson(data))
    );
  }

  saveWorkFunction(workFunction: WorkFunction): Observable<any> {
    return this.http.post<WorkFunction>(this.serviceURL + 'work-functions', this.workFunctionSerializer.toJson(workFunction)).pipe(
      map((data) => this.workFunctionSerializer.fromJson(data))
    );
  }

  // workPosition
  listWorkerPositions(): Observable<any> {
    return this.http.get<WorkerPosition[]>(this.serviceURL + 'worker-positions').pipe(
      map((data) => this.convertDataToWorkerPositions(data))
    );
  }

  // nac
  listNaceCodes(): Observable<any> {
    return this.http.get<WorkFunction[]>(this.serviceURL + 'nace-codes').pipe(
      map((data) => {
        const naceCodes: NaceCode[] = data.map(naceCode => this.naceCodeSerializer.fromJson(naceCode));
        return convertNaceCode(naceCodes, this.translate);
      })
    );
  }

  // billingCoefficient
  getBillingCoefficientsByCustomerSize(customerSize: CustomerSize) {
    return this.http.get<BillingCoefficients>(this.serviceURL + 'billing-coefficients/customer-size/' + customerSize).pipe(
      map((data) => this.billingCoefficientSerializer.fromJson(data))
    );
  }

  getPageBillingCoefficient(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<BillingCoefficientsPage>(this.serviceURL + 'billing-coefficients/page', {params}).pipe(
      map((data: BillingCoefficientsPage) => this.billingCoefficientSerializerPage.fromJson(data))
    );
  }

  saveBillingCoefficients(billingCoefficients: BillingCoefficients): Observable<any> {
    return this.http.post<BillingCoefficients>(this.serviceURL + 'billing-coefficients', this.billingCoefficientSerializer.toJson(billingCoefficients)).pipe(
      map((data) => this.billingCoefficientSerializer.fromJson(data))
    );
  }

  // Countries
  listCountries(): Observable<any> {
    return this.http.get<Country[]>(this.serviceURL + 'countries').pipe(
      map((data) => {
        const countries: Country[] = data.map(country => this.countrySerializer.fromJson(country));
        return convertCountries(countries, this.translate);
      })
    );
  }

  // language
  listLanguages(): Observable<any> {
    return this.http.get<Language[]>(this.serviceURL + 'languages').pipe(
      map((data) => this.convertDataToLanguages(data))
    );
  }

  // correctionType
  listCorrectionTypes(): Observable<any> {
    return this.http.get<CorrectionType[]>(this.serviceURL + 'correction-types').pipe(
      map((data) => this.convertDataToCorrectionType(data))
    );
  }

  // PrestationCode
  listPrestationCodeJustifications(): Observable<any> {
    return this.http.get<PrestationCode[]>(this.serviceURL + 'prestation-codes/justification').pipe(
      map((data) => this.convertDataToPrestationCodes(data))
    );
  }

  listPrestationCodesCorrections(): Observable<any> {
    return this.http.get<PrestationCode[]>(this.serviceURL + 'prestation-codes/correction').pipe(
      map((data) => this.convertDataToPrestationCodes(data))
    );
  }

  listPrestationCodes(): Observable<any> {
    return this.http.get<PrestationCode[]>(this.serviceURL + 'prestation-codes').pipe(
      map((data) => this.convertDataToPrestationCodes(data))
    );
  }

  getPagePrestationCode(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<PrestationCodePage>(this.serviceURL + 'prestation-codes/page', {params}).pipe(
      map((data: PrestationCodePage) => this.prestationCodePageSerializer.fromJson(data))
    );
  }

  savePrestationCode(prestationCode: PrestationCode): Observable<any> {
    return this.http.post<PrestationCode>(this.serviceURL + 'prestation-codes', this.prestationCodeSerializer.toJson(prestationCode)).pipe(
      map((data) => this.prestationCodeSerializer.fromJson(data))
    );
  }

  updateJointCommissions(jointCommissionCode: string, active: boolean): Observable<any> {
    let params = new HttpParams()
                     .set('jointCommissionCode', jointCommissionCode)
                     .set('active', active);
    return this.http.post<PrestationCode>(this.serviceURL + 'prestation-codes/update-joint-commissions', null, {params});
  }

  // Sector
  listSectors(): Observable<any> {
    return this.http.get<Sector[]>(this.serviceURL + 'sectors').pipe(
      map((data) => this.convertDataToSectors(data))
    );
  }

  // CompanyPrefix
  listCompanyPrefixes(): Observable<any> {
    return this.http.get<CompanyPrefix[]>(this.serviceURL + 'company-prefixes').pipe(
      map((data) => {
        const cp = data.map(companyPrefix => this.companyPrefixSerializer.fromJson(companyPrefix));
        return convertCompanyPrefix(cp, this.translate);
      })
    );
  }

  getCompanyPrefixByCode(code: string): Observable<any> {
    return this.http.get<CompanyPrefix>(this.serviceURL + 'company-prefixes/' + code).pipe(
      map((data) => this.companyPrefixSerializer.fromJson(data))
    );
  }

  // StudentQuotaHours
  getMaxStudentQuotaHoursByYear(year: number): Observable<any> {
    return this.http.get<number>(this.serviceURL + 'student-quota-hours/max-by-year?year=' + year);
  }

  getPageStudentQuotaHours(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<StudentQuotaHoursPage>(this.serviceURL + 'student-quota-hours/page', {params}).pipe(
      map((data: StudentQuotaHoursPage) => this.studentQuotaHoursPageSerializer.fromJson(data))
    );
  }

  saveStudentQuotaHours(studentQuotaHours: StudentQuotaHours): Observable<any> {
    return this.http.post<StudentQuotaHours>(this.serviceURL + 'student-quota-hours', this.studentQuotaHoursSerializer.toJson(studentQuotaHours)).pipe(
      map((data) => this.studentQuotaHoursSerializer.fromJson(data))
    );
  }

  // Bic
  getBicByIdentificationNumber(identificationNumber: string): Observable<any> {
    return this.http.get<Bic>(this.serviceURL + 'bics/bic/' + identificationNumber).pipe(
      map((data) => this.bicSerializer.fromJson(data))
    );
  }

  // dimona
  getDimonaCodeNotisByCode(codeNotis: string): Observable<any> {
    return this.http.get<DimonaCodeNotis>(this.serviceURL + 'dimona-code-notis/code/' + codeNotis).pipe(
      map((data) => this.dimonaCodeNotisSerializer.fromJson(data))
    );
  }

  // TravelExpenseCoefficient
  getPageTravelExpenseCoefficient(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<TravelExpenseCoefficientPage>(this.serviceURL + 'travel_expense_coefficients/page', {params}).pipe(
      map((data: TravelExpenseCoefficientPage) => this.travelExpenseCoefficientPageSerializer.fromJson(data))
    );
  }

  saveTravelExpenseCoefficient(travelExpenseCoefficient: TravelExpenseCoefficient): Observable<any> {
    return this.http.post<TravelExpenseCoefficient>(this.serviceURL + 'travel_expense_coefficients', this.travelExpenseCoefficientSerializer.toJson(travelExpenseCoefficient)).pipe(
      map((data) => this.travelExpenseCoefficientSerializer.fromJson(data))
    );
  }

  // PublicHoliday
  getPagePublicHoliday(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<PublicHolidayPage>(this.serviceURL + 'public-holidays/page', {params}).pipe(
      map((data: PublicHolidayPage) => this.publicHolidayPageSerializer.fromJson(data))
    );
  }

  savePublicHoliday(publicHoliday: PublicHoliday): Observable<any> {
    return this.http.post<PublicHoliday>(this.serviceURL + 'public-holidays', this.publicHolidaySerializer.toJson(publicHoliday)).pipe(
      map((data) => this.publicHolidaySerializer.fromJson(data))
    );
  }

  // salaryScale
  getPageSalaryScale(index: number, size: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', String(index));
    params = params.append('size', String(size));
    return this.http.get<SalaryScalePage>(this.serviceURL + 'salary-scales/page', {params}).pipe(
      map((data: SalaryScalePage) => this.salaryScalePageSerializer.fromJson(data))
    );
  }

  saveSalaryScale(salaryScale: SalaryScale): Observable<any> {
    return this.http.post<SalaryScale>(this.serviceURL + 'salary-scales', this.salaryScaleSerializer.toJson(salaryScale)).pipe(
      map((data) => this.salaryScaleSerializer.fromJson(data))
    );
  }

  // private methodes
  private convertDataToPrestationCodes(data: any): PrestationCode[] {
    return data.map(prestationCodes => this.prestationCodeSerializer.fromJson(prestationCodes));
  }

  private convertDataToJointCommissions(data: any): JointCommission[] {
    return data.map(jointCommission => this.jointCommissionSerializer.fromJson(jointCommission));
  }

  private convertDataToWorkFunctions(data: any): WorkFunction[] {
    const workFunctions: WorkFunction[] = data.map(workFunction => this.workFunctionSerializer.fromJson(workFunction));
    return convertWorkFunction(workFunctions, this.translate);
  }

  private convertDataToWorkerPositions(data: any): WorkerPosition[] {
    const workerPositions: WorkerPosition[] = data.map(workerPosition => this.workerPositionSerializer.fromJson(workerPosition));
    return convertWorkerPosition(workerPositions, this.translate);
  }

  private convertDataToLanguages(data: any): Language[] {
    return data.map(language => this.languageSerializer.fromJson(language));
  }

  private convertDataToCorrectionType(data: any): CorrectionType[] {
    return data.map(correctionType => this.correctionTypeSerializer.fromJson(correctionType));
  }

  private convertDataToSectors(data: any): Sector[] {
    return data.map(sector => this.sectorSerializer.fromJson(sector));
  }
}
