import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {CompanyPrefix} from '../../model/company-prefix/company-prefix.model';

@Injectable()
export class CompanyPrefixesResolver implements Resolve<Observable<CompanyPrefix[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<CompanyPrefix[]> {
    return this.facadeService.listCompanyPrefixes().pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
