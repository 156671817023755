import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable, of} from 'rxjs';
import {FacadeService} from '../../service/facade/facade.service';
import {catchError, map} from 'rxjs/operators';
import {Prestation} from '../../../prestations/shared/model/prestation.model';

@Injectable()
export class PrestationsToValidateResolver implements Resolve<Observable<Prestation[]>> {
  constructor(private facadeService: FacadeService) {
  }

  resolve(): Observable<Prestation[]> {
    return this.facadeService.getPagePrestationToValidate(0, 5, {}).pipe(
      map((data) => data),
      catchError(error => {
        return of({error: error});
      })
    );
  }
}
