import {Component} from '@angular/core';
import {Worker} from '../shared/model/worker.model';
import {ActivatedRoute} from '@angular/router';
import {OperatingUnit} from 'src/app/operating-units/shared/model/operating-unit.model';
import {Country} from '../../shared/model/country/country.model';
import {Language} from '../../shared/model/language/language.model';
import {WorkerPosition} from '../../shared/worker-position/worker-position.model';

@Component({
  selector: 'app-worker-edit',
  templateUrl: './worker-edit.component.html',
  styleUrls: ['./worker-edit.component.css']
})
export class WorkerEditComponent {
  worker: Worker = new Worker();
  operatingUnits: OperatingUnit[] = [];
  countries: Country[] = [];
  languages: Language[] = [];
  workerPositions: WorkerPosition[] = [];


  constructor(private route: ActivatedRoute) {
    this.getWorker();
    this.getAllOperatingUnits();
    this.getAllCountries();
    this.getAllLanguages();
    this.getAllWorkerPositions();
  }

  private getWorker() {
    const worker = this.route.snapshot.data['worker'];
    Object.assign(this.worker, worker);
  }

  private getAllOperatingUnits() {
    const operatingUnits = this.route.snapshot.data['operatingUnits'];
    Object.assign(this.operatingUnits, operatingUnits);
  }

  private getAllCountries() {
    const countries = this.route.snapshot.data['countries'];
    Object.assign(this.countries, countries);
  }

  private getAllLanguages() {
    const languages = this.route.snapshot.data['languages'];
    Object.assign(this.languages, languages);
  }

  private getAllWorkerPositions() {
    const workerPositions = <WorkerPosition[]>this.route.snapshot.data['workerPositions'];
    Object.assign(this.workerPositions, workerPositions);
  }
}
