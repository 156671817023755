import {Component, OnInit} from '@angular/core';
import {interval} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import { FacadeService } from 'src/app/shared/service/facade/facade.service';
import { Status } from '../../model/status.model';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css', '../../admin.component.css']
})
export class ServicesComponent implements OnInit {

  serviceStatus: Status[] = [];
  private readonly TIMER = 30000; //  ms

  constructor(private facadeService: FacadeService) {
  }

  ngOnInit(): void {
    this.facadeService.loadStatus().subscribe(
      (value: Status[]) => {
        this.sortStatus(value);
      }
    );
    interval(this.TIMER)
      .pipe(
        mergeMap(() => this.facadeService.loadStatus())
      )
      .subscribe(data => this.sortStatus(data));
  }

  manualCheck(): void {
    this.facadeService.statusManualCheck().subscribe(
      res => this.sortStatus(res)
    );
  }

  private sortStatus(statuses: Status[]) {
    this.serviceStatus = statuses;
    this.serviceStatus.sort((a, b) => (b.name > a.name ? -1 : 1));
  }

}
