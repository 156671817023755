import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Contract} from '../model/contract.model';
import {ContractSerializer} from '../model/contract.serializer';
import {map} from 'rxjs/operators';
import {DateSerializer} from '../../../shared/model/date.serializer';
import {TranslateService} from '@ngx-translate/core';
import {ContractPageSerializer} from '../../../shared/model/pagination/contract/contract-page.serializer';
import {ContractPage} from '../../../shared/model/pagination/contract/contract-page.model';
import {WorkerType} from '../../../workers/shared/model/worker.model';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private serviceURL = environment.contract_service_URL + 'contracts';
  private contractSerializer: ContractSerializer = new ContractSerializer();
  private contractPageSerializer: ContractPageSerializer = new ContractPageSerializer();

  constructor(private http: HttpClient, private translate: TranslateService) {
  }

  get(id: number): Observable<any> {
    return this.http.get<Contract>(this.serviceURL + '/' + id).pipe(
      map((data) => this.contractSerializer.fromJson(data))
    );
  }

  getOriginalfile(id: number): Observable<any> {

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    let params = new HttpParams();
    params = params.append('language', this.translate.currentLang);
    return this.http.get(this.serviceURL + '/' + id + '/download-pdf', {headers: headers, params: params, responseType: 'blob'});
  }

  list(requestId: number = null, customerId: number = null): Observable<any> {
    let params = new HttpParams();
    if (requestId) {
      params = params.append('requestId', String(requestId));
    }
    if (customerId) {
      params = params.append('customerId', String(customerId));
    }

    return this.http.get<Contract[]>(this.serviceURL + '/filter', {params}).pipe(
      map((data) => this.convertData(data))
    );
  }

  generatePdf(contractId: number): Observable<any> {
    return this.http.post(this.serviceURL + '/' + contractId + '/generate-pdf/' + this.translate.currentLang, null);
  }

  getPage(index: number, size: number, requestId: number, active: boolean): Observable<any> {
    let params = new HttpParams();
    params = params.append('index', index);
    params = params.append('size', size);
    if (requestId) {
      params = params.append('requestId', requestId);
    }
    if (active) {
      params = params.append('active', active);
    }
    return this.http.get<ContractPage>(this.serviceURL + '/page', {params}).pipe(
      map((data) => this.contractPageSerializer.fromJson(data))
    );
  }

  getMinimumSalaryScale(workerType: WorkerType, workFunctionId: number, workerId: number, customerId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('workerType', workerType);
    params = params.append('workFunctionId', workFunctionId);
    params = params.append('workerId', workerId);
    params = params.append('customerId', customerId);
    return this.http.get<number>(this.serviceURL + '/minimum-salary-scale', {params});
  }

  getStudentRate(age: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('age', age);
    return this.http.get<number>(this.serviceURL + '/student-rate', {params});
  }

  save(contract: Contract): Observable<any> {
    return this.http.post<Contract>(this.serviceURL, this.contractSerializer.toJson(contract)).pipe(
      map((data) => this.contractSerializer.fromJson(data))
    );
  }

  delete(contract: Contract): Observable<any> {
    return this.http.delete<Contract>(this.serviceURL + '/' + contract.id).pipe(
      map((data) => this.contractSerializer.fromJson(data))
    );
  }

  export(startDate: Date, endDate: Date, operationUnitIds: Array<number>, workerId: number, customerId: number): Observable<any> {

    const dateSerializer = new DateSerializer();

    const params = new HttpParams({
      fromObject: {
        operationUnitIds: String(operationUnitIds),
        ...workerId ? {workerId: workerId} : {},
        ...customerId ? {customerId: customerId} : {},
        ...startDate ? {startDate: dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: dateSerializer.toJson(endDate)} : {}
      }
    });

    return this.http.get(
      this.serviceURL + '/export',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  exportSuccessiveContracts(startDate: Date, endDate: Date): Observable<any> {

    const dateSerializer = new DateSerializer();

    const params = new HttpParams({
      fromObject: {
        ...startDate ? {startDate: dateSerializer.toJson(startDate)} : {},
        ...endDate ? {endDate: dateSerializer.toJson(endDate)} : {}
      }
    });
    return this.http.get(
      this.serviceURL + '/export-successive-contracts',
      {
        responseType: 'blob',
        params: params,
        observe: 'response'
      });
  }

  private convertData(data: any): Contract[] {
    return data.map(contract =>
      this.contractSerializer.fromJson(contract));
  }
}
